import React from 'react'
import styled from 'styled-components'
import Colors from '../../domain/Colors'
import { Oval } from 'react-loader-spinner'

interface Props {
  text?: string
}

const Background = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${Colors.black}66;
  z-index: 9999;
`

const IconAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: white;
  font-size: 1.25em;
  font-family: Open Sans, sans-serif;

  & p {
    padding-top: 1.5vh;
    font-weight: 800;
  }
`

const LoadingCircleBackground = ({ text }: Props) => {
  const displayText = text || 'Carregando...'
  return (
    <Background>
      <IconAndTextContainer>
        <Oval />
        <p>{displayText}</p>
      </IconAndTextContainer>
    </Background>
  )
}

export default LoadingCircleBackground
