import React from 'react'
import ChoicesModal from '../ChoicesModal'

interface Props {
  open: boolean
  onChoice: (days: number) => void
  onClose: () => void
}

export default ({ open, onChoice, onClose }: Props) => {
  return (
    <ChoicesModal
      open={open}
      title="Conectar Contas"
      text="Após conectar uma conta recuperaremos as transações associadas. Quanto gostaria de recuperar?"
      options={[
        {
          value: 30,
          text: '30 dias',
          tooltip: 'Pode acabar carregando lançamentos já cadastrados.'
        },
        { value: 3, text: '3 dias', tooltip: 'Carregará as transações dos últimos 3 dias.' },
        {
          value: 0,
          text: 'nenhuma transação',
          tooltip: 'Carregará transações a partir de agora. Útil para não duplicar lançamentos.'
        }
      ]}
      onChoice={({ value }) => onChoice(value)}
      onClose={onClose}
    />
  )
}
