import React, { useState } from 'react'
import { useColumns } from './columns'
import { ErrorContainer } from './TransactionsByInvestmentIdTable.styles'
import Button from '../../Buttons'
import { getLoadingInvestmentTransaction } from '../../../domain/investments'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { noticeError } from '../../../services/Monitoring'
import Table from '../Table'
import { ContainerTable } from '../Table.styles'
import { useFamilyLancamentos } from '../../../queries/lancamento/queries'

interface Props {
  end: Date
  familyId: number
  pluggyInvestmentId: string
  start: Date
  toggledOffByDefaultColumns?: string[]
  widthBeforeHorizontalScroll?: number
}

const loadingData = getLoadingInvestmentTransaction()

export const TransactionsByInvestmentIdTable = ({
  familyId,
  pluggyInvestmentId,
  start,
  end,
  widthBeforeHorizontalScroll,
  toggledOffByDefaultColumns = []
}: Props) => {
  const token = useRequiredAuth()
  const columns = useColumns()
  const [page, setPage] = useState(0)

  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage(
    'investment-transactions-by-investiment-id-page-size',
    10
  )

  const {
    lancamentos,
    totalPages,
    isLancamentosError,
    isLoadingLancamentos,
    lancamentosError,
    refetchLancamentos
  } = useFamilyLancamentos({ end, start, familyId, page, size: pageSize, pluggyInvestmentId })

  if (isLancamentosError) {
    noticeError(lancamentosError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchLancamentos()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable>
      <Table
        title=" "
        data={lancamentos?.results || []}
        columns={columns}
        isLoading={isLoadingLancamentos}
        loadingData={loadingData}
        filterEnabled={false}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        toggledOffByDefaultColumns={toggledOffByDefaultColumns}
        downloadFileName={`Lançamento-Investimentos-${new Date().toISOString().split('T')[0]}`}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
      />
    </ContainerTable>
  )
}
