import { Dono } from '../../../components/Navbars/AdminNavbar/components/SaveDataTransaction/types'
import { noticeError } from '../../../services/Monitoring'
import { getWithToken, getWithTokenApiV2, postWithTokenApiV2 } from '../../../webClient'
import { ManyResults, SingleResult } from '../../Results'
import { Family } from '../Family'
export interface Params {
  familyId: number
}

export const getFamilyById = async ({ familyId }: Params) => {
  const { data: { singleResult: family, success, errorMsg } = {}, status } = await getWithToken<
    SingleResult<Family>
  >(`familia/${familyId}`)
  if (!success || status !== 200) {
    const msg = `Error getting family ${familyId}: ${errorMsg}`
    console.error(msg)
    throw new Error(msg)
  }
  return family
}

export const getAllPersonsByFamilyIdApi = async ({ familyId, filter = { ApenasNome: true } }) => {
  const url = `api/pessoa/familia/${familyId}/filtro`
  const {
    data: { results: persons, totalResults },
    status
  } = await postWithTokenApiV2<ManyResults<Dono>, typeof filter>(url, filter)

  if (status !== 200) {
    const msg = `Error when getting Person by family ID ${familyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return {
    persons,
    totalResults
  }
}

export const getImageBYFamilyIdApi = async ({ familyId }: { familyId: number | string }) => {
  const url = `api/familia/imagem/${familyId}`
  const { data, status } = await getWithTokenApiV2<SingleResult<string>>(url)

  console.log(`api/familia/imagem/${familyId}`, { data })

  if (status !== 200) {
    const msg = `Error when getting Person by family ID ${familyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return {
    data
  }
}
