import { noticeError } from '../../../services/Monitoring'
import { authClient } from '../../../webClient'
import Token, { isToken } from '../token'

interface Request {
  email: string
  password: string
}

interface Response {
  ok: boolean
  token: string
}

interface Authentication {
  token: Token
  jwt: string
}

export const authenticate = async ({ email, password }: Request): Promise<Authentication> => {
  const {
    status,
    data: { ok, token: jwt }
  } = await authClient.post<Response>('/authenticate', { email, password })
  if (status === 401 || status === 403) throw new Error('invalidCredentials')
  if (!ok || status !== 200 || !jwt) {
    const msg = `Error ${status} authenticating user ${email}`
    const error = new Error(msg)
    noticeError(error)
    throw error
  }
  const body = jwt.split('.')[1]
  const maybeToken = JSON.parse(atob(body))
  if (!isToken(maybeToken)) {
    const msg = `Invalid JWT content: ${JSON.stringify(maybeToken)}`
    const error = new Error(msg)
    noticeError(error)
    throw error
  }
  return { token: maybeToken, jwt }
}
