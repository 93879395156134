import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export const useRecaptcha = (actionName?: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState<string | null>(null)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const maybeToken = await executeRecaptcha(actionName)
    setToken(maybeToken)
  }, [actionName, executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return {
    handleReCaptchaVerify,
    token
  }
}
