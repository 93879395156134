import React, { useState } from 'react'
import { useCurrentFamilyId, useObjectLocalStorage } from '../../../hooks'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import { LancamentoEdit } from '../../Tables/LancamentosTable/columns'
import Select from '../Select'

interface Props {
  lancamentoId: number
  onSelect?: (lancamentoId: number, orcamentoId: number, categoriaId: number) => void
  defaultPlaceholder?: string
}

export default ({ lancamentoId, defaultPlaceholder, onSelect }: Props) => {
  const [filter, setFilter] = useState('')
  const { value: edited } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})
  const { familyId } = useCurrentFamilyId()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)
  const selectedOrcamentoId = edited[lancamentoId]?.orcamentoId
  const options = selectedOrcamentoId
    ? orcamentos
        ?.find((o) => o.id === selectedOrcamentoId)
        ?.categorias.map((c) => ({ value: c, text: c.nome }))
        ?.filter(({ text }) => text.toLowerCase().includes(filter.toLowerCase())) ?? []
    : []

  return (
    <Select
      filter={filter}
      onFilter={setFilter}
      loading={isLoadingOrcamentos}
      placeholder={defaultPlaceholder || 'Selecione...'}
      options={options}
      onSelection={(categoria) => {
        setFilter('')
        onSelect?.(lancamentoId, selectedOrcamentoId, categoria.id)
      }}
    />
  )
}
