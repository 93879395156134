import React, { useState } from 'react'
import { PageFirstRow } from '../../../components/Headers'
import * as S from './styles'
import { DateRangePicker } from '../../../components/Inputs'
import { endOfMonth, startOfMonth } from 'date-fns'
import { useDateLocalStorage } from '../../../hooks/useLocalStorage'
import { InnerContent } from './InnerContent'
import Button from '../../../components/Buttons'
import { useCoreContext } from '../../../hooks/contexts/coreProvider'

export const Equity = () => {
  const { familiaId: familyId } = useCoreContext()
  const [isOpenEquityModal, setIsOpenEquityModal] = useState(false)
  const { setValue: setEnd, value: end } = useDateLocalStorage('dataFimPatrimonio', endOfMonth(new Date()))
  const { setValue: setStart, value: start } = useDateLocalStorage(
    'dataInicioPatrimonio',
    startOfMonth(new Date())
  )

  const handleOpenModal = () => setIsOpenEquityModal(true)

  return (
    <S.ContentContainer>
      <PageFirstRow title="Patrimônio - Integração"></PageFirstRow>

      <S.Section justify="space-between">
        <S.DateRangePickerContainer>
          <DateRangePicker
            title="Período dos lançamentos"
            start={start}
            end={end}
            onStartChange={setStart}
            onEndChange={setEnd}
          />
        </S.DateRangePickerContainer>
        <Button text="Novo Patrimônio" onClick={handleOpenModal} />
      </S.Section>

      {!!familyId && (
        <InnerContent
          isOpenEquityModal={isOpenEquityModal}
          setIsOpenEquityModal={setIsOpenEquityModal}
          familyId={Number(familyId)}
          start={start}
          end={end}
        />
      )}
    </S.ContentContainer>
  )
}
