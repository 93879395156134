import { noticeError } from '../../../services/Monitoring'
import { annotationsApi } from '../../../webClient'
import { Note, NoteUpsertRequest } from '../Notes'

export const createNote = async (body: NoteUpsertRequest) => {
  const { data: note, status } = await annotationsApi.post<Note>('annotation', body)
  if (status !== 200) {
    const error = new Error('Error creating annotation')
    noticeError(error)
    throw error
  }
  return note
}
