import React from 'react'
import Icon from './Icon'
import searchIcon from '../../assets/img/theme/search.png'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

interface Props {
  onClick?: () => void
  tooltip?: string
}

export default ({ onClick, tooltip }: Props) => (
  <StyledIcon alt="Buscar" tooltip={tooltip} src={searchIcon} onClick={onClick} />
)
