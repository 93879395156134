import { bankIntegrationClient as client } from '../../../webClient'
import { Result } from '../../extrato/api/sendExtrato'

export interface ActiveOrInactiveAccountByIdParams {
  id: string
  isActive: boolean
  itemId: string
}

export type ActiveOrInactiveAccountByIdResult = Result & {
  exception: { message: string }
}

export const updateActiveOrInactiveAccountById = async ({
  id,
  isActive,
  itemId
}: ActiveOrInactiveAccountByIdParams) => {
  const url = `account/${id}`
  const { status, data } = await client.put<ActiveOrInactiveAccountByIdResult>(url, { isActive, itemId })

  if (status !== 200) {
    const msg = `Error suspend account by id ${id}`
    console.error(msg)
    throw new Error(msg)
  }
  return data
}
