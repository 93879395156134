export interface Identifiable {
  id: number | string
}

// TODO: remover any abaixo
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIdentifiable = (value?: any): value is Identifiable =>
  value !== undefined &&
  value !== null &&
  typeof value == 'object' &&
  !Array.isArray(value) &&
  'id' in value &&
  (typeof value.id == 'string' || typeof value.id == 'number')
