import React, { useCallback, useEffect } from 'react'
import { StyledModal } from './DeleteBudgetModal.styles'
import { useBudgetTransactions } from '../../../../queries/orcamento/queries'
import { BudgetTransactions } from '../../../../domain/orcamento/budgetTransactions'
import { formatMonetaryValue } from '../../../../domain/formatter'
import { useDeleteBudget } from '../../../../queries/orcamento/mutations'

interface Props {
  budgetId: number
  open: boolean
  onClose: (deleted: boolean) => void
}

export const DeleteBudgetModal = ({ budgetId, open, onClose }: Props) => {
  const { budgetTransactions, isBudgetTransactionsError, isLoadingBudgetTransactions } =
    useBudgetTransactions(budgetId, {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    })
  const { deleteBudget, isDeletingBudget, isBudgetDeletionError, budgetDeleted, resetBudgetDeletion } =
    useDeleteBudget()

  const isLoading = isLoadingBudgetTransactions
  const isError = isBudgetTransactionsError || isBudgetDeletionError
  const noTransactions = budgetTransactions !== undefined && budgetTransactions.transactionsCount === 0

  const handleDelete = useCallback(() => {
    deleteBudget({ budgetId })
  }, [budgetId, deleteBudget])

  useEffect(() => {
    if (!budgetDeleted) return
    resetBudgetDeletion()
    onClose(true)
  }, [budgetDeleted, onClose, resetBudgetDeletion])

  return (
    <StyledModal
      open={open}
      onClose={() => onClose(true)}
      primaryButton={{
        text: 'Excluir',
        onClick: handleDelete,
        disabled: isLoading || isError || isDeletingBudget
      }}
      title="Exclusão de Orçamento"
      secondaryButton={{
        text: 'Cancelar exclusão',
        onClick: () => onClose(false),
        disabled: isDeletingBudget
      }}
    >
      <p>Tem certeza que deseja excluir? Este procedimento é irreversível!</p>
      <p>Essa ação também deletará os lançamentos associados no Extrato</p>
      {isLoading && <LoadingContent />}
      {isError && <ErrorContent />}
      {noTransactions && <NoTransactionsContent />}
      {budgetTransactions && budgetTransactions.transactionsCount > 0 && (
        <TransactionsContent transactions={budgetTransactions} />
      )}
      {isDeletingBudget && <DeletingTransactionsContent />}
    </StyledModal>
  )
}

const LoadingContent = () => {
  return <p>Carregando informações sobre lançamentos...</p>
}

const ErrorContent = () => {
  return (
    <p>
      <b>Erro ao carregar informações sobre lançamentos, por favor tente mais tarde</b>
    </p>
  )
}

const NoTransactionsContent = () => {
  return <p>Não há lançamentos relacionados a esse orçamento</p>
}

const TransactionsContent = ({
  transactions: { transactionsCount, transactionsValue }
}: {
  transactions: BudgetTransactions
}) => {
  if (transactionsCount === 1)
    return (
      <p>
        Existe <b>um</b> lançamento com valor total de <b>{formatMonetaryValue(transactionsValue, false)}</b>{' '}
        associado a esse orçamento
      </p>
    )

  return (
    <p>
      Existem <b>{transactionsCount}</b> lançamentos com valor total de{' '}
      <b>{formatMonetaryValue(transactionsValue, false)}</b> associados a esse orçamento
    </p>
  )
}

const DeletingTransactionsContent = () => {
  return (
    <p>
      <b>Excluindo orçamento e lançamentos associados...</b>
    </p>
  )
}
