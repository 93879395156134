import { noticeError } from '../../../services/Monitoring'
import { bankIntegrationClient as client } from '../../../webClient'

export interface Params {
  itemId: string
  familyId: number
  userId: number
  daysRange: number
}

interface ErrorResponse {
  error?: string
  message?: string
}

export const createNewConnection = async ({ itemId, userId, familyId, daysRange }: Params) => {
  const url = `item/${itemId}?userId=${userId}&days=${daysRange}&familyId=${familyId}`
  const { status, data } = await client.post<ErrorResponse | null>(url, undefined, {
    validateStatus: () => true
  })
  if (status === 400) {
    const { error, message } = data as ErrorResponse
    const backendMessage = `Status ${status}\n${error}: ${message}`
    const err = new Error(backendMessage)
    noticeError(err)
    console.error(err)
    throw err
  } else if (status !== 200) {
    const msg = `Error ${status} creating item ${itemId} for user ${userId} with ${daysRange} range`
    const error = new Error(msg)
    noticeError(error)
    console.error(msg)
    throw error
  }
  return
}
