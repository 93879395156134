import { type Logger, type LoggerImpl } from './types'

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
  const loggedSet: typeof set = (...rest) => {
    // @ts-expect-error expected-error
    set(...rest)
    if (process.env.DEV) {
      console.log(...(name ? [`${name}:`] : []), get())
    }
  }
  store.setState = loggedSet

  return f(loggedSet, get, store)
}

export const logger = loggerImpl as Logger
