import { noticeError } from '../../../services/Monitoring'
import { bankIntegrationClient as client } from '../../../webClient'
import { Connection, Item, requiresAction } from '../Connection'

export interface Params {
  personId: number
}
export interface ApiResponse {
  success: boolean
  conexoes: Connection[]
}

export interface FetchItemsResponse {
  items: Item[]
}

export const getConnectionsAndAccounts = async (userId: number) => {
  const items = await getItems(userId)
  const connections: Connection[] = items.map(
    ({
      itemId: id,
      connector: { name: conexaoNome },
      lastSyncDate: ultimoSucesso,
      accounts,
      status,
      userId
    }) => ({
      id,
      conexaoNome,
      userId,
      ultimoSucesso: ultimoSucesso ? new Date(ultimoSucesso) : undefined,
      qtdContasCards: accounts.length,
      status,
      acaoRequerida: requiresAction(status),
      contas: accounts.map((conta) => ({
        id: conta.id,
        descricao: conta.name,
        meioPagamentoId: conta.meioPagamentoId,
        tipo: conta.type,
        isActive: conta.isActive,
        itemId: conta.itemId
      }))
    })
  )
  return connections
}

export const getItems = async (familyId: number) => {
  const url = `family/${familyId}/item`
  const {
    status,
    data: { items }
  } = await client.get<FetchItemsResponse>(url)
  if (status !== 200) {
    const msg = `Error getting family ${familyId} items`
    const error = new Error(msg)
    console.error(msg, error)
    noticeError(error)
  }
  return items
}
