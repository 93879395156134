import React, { useState } from 'react'
import styled from 'styled-components'
import NotesIcon from '../../assets/img/notesIcon.png'
import NotesModal from '../Modal/NotesModal/index'

const Button = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  background: transparent;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
  &::after {
    transition: all 0.2s ease-in-out;
    content: 'Bloco de Notas';
    position: absolute;
    bottom: unset;
    top: -80px;
    left: -64px;
    width: 160px;
    transform: scale(0.8);
    z-index: 999;
    padding: 4px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 40px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    opacity: 0;
  }
  &:hover {
    &::after {
      top: unset;
      bottom: -40px;
      transform: scale(1);
      opacity: 1;
    }
  }
`

export default () => {
  const [open, setIsOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsOpen(!open)}>
        <img src={NotesIcon} alt="" />
      </Button>
      <NotesModal open={open} onClose={() => setIsOpen(false)} />
    </>
  )
}
