import { noticeError } from '../../../services/Monitoring'
import { getWithToken } from '../../../webClient'
import { PagedResult } from '../../Results'
import { FamilyBilling } from '../billings'

export type Params = {
  plannerId: number
  includeDeleted?: boolean
  desc: boolean
  orderBy?: string
  pageIndex: number
  pageSize: number
  search?: string
  filterDate: Date
}

export type Response = PagedResult<FamilyBilling> & {
  plannerId: number
  valuePerFamily: number
  totalFee: number
  totalActiveFamilies: number
  totalInactiveFamilies: number
  totalFamilies: number
}

export const getBillingsByPlannerId = async ({
  desc = true,
  includeDeleted = true,
  orderBy,
  pageIndex = 0,
  pageSize = 10,
  plannerId,
  search,
  filterDate
}: Params) => {
  // const url = `planejador/${plannerId}/billing`
  const urlV2 = `planejador/${plannerId}/v2/billing`
  const params = { desc, includeDeleted, orderBy, pageIndex, pageSize, name: search, filterDate }

  const { data: billings, status } = await getWithToken<Response>(urlV2, {
    params
  })

  if (status !== 200) {
    const msg = `Error getting billing for planner ${plannerId}, status ${status}`
    const error = new Error(msg)
    noticeError(error)
    throw error
  }

  return billings
}
