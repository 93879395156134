import { annotationsApi } from '../../../webClient'
import { NoteUpsertRequest } from '../Notes'

export const updateNote = async ({ id, text, title, favorite }: Omit<NoteUpsertRequest, 'familyId'>) => {
  const { data, status } = await annotationsApi.patch(`annotation/${id}`, { text, title, favorite })
  if (status !== 200) {
    const msg = `Error updating annotation: ${id}`
    console.error(msg)
    throw new Error(msg)
  }
  return data
}
