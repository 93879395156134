import React, { useMemo, useState } from 'react'
import { FieldSelect } from '../FieldSelect'
import { useGetFinancialInstitution } from '../../../queries/investments/queries'

interface IValue {
  _id: string
  id: number
  key: number
  name: string
}

interface IOption {
  value: IValue
  label: string
}

interface Props {
  defaultPlaceholder?: string
  label?: string
  id?: string
  onSelect: ({ value }: IOption) => void
  selectedId?: number
  defaultValue?: number | string
}

export const FieldSelectFinancialInstitutions = ({
  selectedId,
  label = 'Instituição Financeira da Transação *',
  id = 'lancamentoIdInstituicaoFinanceira',
  defaultPlaceholder,
  defaultValue,
  onSelect
}: Props) => {
  const [filter, setFilter] = useState('')
  const { financialInstitutions, isLoadingFinancialInstituions } = useGetFinancialInstitution()

  const placeholder =
    financialInstitutions?.find(({ key }) => key === selectedId)?.name || defaultPlaceholder || 'Selecione...'

  const options = useMemo(() => {
    const map: IOption[] = []
    financialInstitutions?.forEach((financialInstitution) => {
      if (financialInstitution?.key && financialInstitution?.name)
        map.push({
          value: {
            ...financialInstitution,
            id: financialInstitution?.key
          },
          label: financialInstitution?.name
        })
    })
    return map
  }, [financialInstitutions])

  const selected = useMemo(
    () => (defaultValue ? options.find(({ value }) => value.id === Number(defaultValue)) : undefined),
    [defaultValue, options]
  )

  return (
    // <FieldSelect
    //   label={label}
    //   filter={filter}
    //   onFilter={setFilter}
    //   loading={isLoadingOrcamentos}
    //   disabled={!lancamentoId}
    //   // @ts-expect-error expected-error
    //   selectedValue={selected?.value ? (selected?.value as Categoria) : undefined}
    //   placeholder={defaultPlaceholder || 'Selecione...'}
    //   // @ts-expect-error expected-error
    //   options={options}
    //   onChange={({ value: categoria }) => {
    //     setFilter('')
    //     // @ts-expect-error expected-error
    //     onSelect?.({ lancamentoId, categoria })
    //   }}
    // />
    <FieldSelect
      filter={filter}
      onFilter={setFilter}
      label={label}
      id={id}
      // @ts-expect-error expected-error
      options={options?.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      disabled={isLoadingFinancialInstituions}
      loading={isLoadingFinancialInstituions}
      placeholder={placeholder}
      onChange={({ value }) => {
        // @ts-expect-error
        onSelect?.({ value })
      }}
      // @ts-expect-error
      selectedValue={selected?.value}
    />
  )
}
