import styled, { css } from 'styled-components'
import Colors from '../../domain/Colors'
import { BackgroundFacadeProps, ContainerProps } from './Modal.types'

export const BackgroundFacade = styled.div<BackgroundFacadeProps>`
  background-color: ${Colors.black}6B;
  display: ${({ hide }) => (hide ? 'flex' : 'none')};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
`

export const Container = styled.div<ContainerProps>`
  ${({ open, padding, top }: ContainerProps) => css`
    background-color: #fff;
    border-radius: 16px;
    box-shadow: rgb(0 0 0 / 14%) 0px 0px 4px, rgb(0 0 0 / 28%) 0px 4px 8px;
    display: ${open ? 'flex' : 'none'};
    left: calc(50vw - 300px);
    max-height: 92%;
    padding: ${padding ? padding : 0};
    position: fixed;
    top: ${top ? top : 2.6}%;
    max-width: 775px;
    z-index: 999999;
    width: 100%;
  `};
`

export const CloseButton = styled.div`
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.secondary};
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Open Sans, sans-serif;
  font-size: 1.8em;
  height: 40px;
  justify-content: center;
  padding-bottom: 5px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(40%, -40%);
  transition: height 120ms ease-in-out, width 120ms ease-in-out;
  width: 40px;
  z-index: 201;

  &:hover {
    height: 45px;
    width: 45px;
  }
`
