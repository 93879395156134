import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const Check = styled.input`
  vertical-align: middle;
  height: 15px;
  width: 15px;
`

const Label = styled.label<{ reverse?: boolean }>`
  align-items: center;
  border: 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding-bottom: 0.5rem;

  input {
    box-shadow: 0 0 0 -2px rgba(0, 0, 0, 0.4);
    transition: box-shadow 200ms ease-in-out;
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.primary};
    input {
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.4);
    }
  }

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
      justify-content: start;
    `}
`

interface Props {
  checked: boolean
  onChange?: (checked: boolean) => void
}

export default ({ onChange, checked }: Props) => (
  <Check
    type="checkbox"
    className="checkbox"
    checked={checked}
    onChange={(event) => {
      event.stopPropagation()
      onChange?.(!checked)
    }}
    onClick={(event) => event.stopPropagation()}
  />
)

export const LabelCheckBox = ({
  onChange,
  checked,
  label,
  reverse = false,
  defaultChecked = false,
  className
}: Props & {
  label?: string | JSX.Element
  reverse?: boolean
  defaultChecked?: boolean
  className?: string
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isChecked, setIsChecked] = useState(defaultChecked || checked)

  return (
    <Label reverse={reverse} className={className}>
      <Check
        type="checkbox"
        className="checkbox"
        checked={isChecked}
        ref={inputRef}
        onChange={(event) => {
          event.stopPropagation()
          setIsChecked((value) => !value)
          onChange?.(Boolean(!inputRef.current?.checked))
        }}
        onClick={(event) => event.stopPropagation()}
      />
      {label}
    </Label>
  )
}
