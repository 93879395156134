import React from 'react'
import * as S from './styles'
import Theme from '../../../../../domain/theme'

export interface ButtonSaveTrancactionProps {
  onClick: () => void
  disabled?: boolean
  isEditing?: boolean
  isSubmitting?: boolean
  theme: Theme
}
export const ButtonSaveTransaction = ({
  onClick,
  theme,
  isEditing,
  isSubmitting = false,
  disabled,
  ...rest
}: ButtonSaveTrancactionProps) => {
  return (
    <S.Button {...rest} disabled={disabled || isSubmitting} onClick={onClick} theme={theme}>
      {isSubmitting ? 'Salvando...' : `${isEditing ? 'Salvar' : 'Criar'} lançamento`}
    </S.Button>
  )
}
