import { getWithToken } from '../../../webClient'
import { BudgetTransactions } from '../budgetTransactions'
import { noticeError } from '../../../services/Monitoring'

export const fetchBudgetTransactions = async (budgetId: number) => {
  const url = `orcamento/${budgetId}/transactions`
  const { data: budgetTransactions, status } = await getWithToken<BudgetTransactions>(url)
  if (status !== 200) {
    const error = new Error(`Error getting budget ${budgetId} transactions. status ${status}`)
    console.error(error)
    noticeError(error, { budgetId })
    throw error
  }
  return budgetTransactions
}
