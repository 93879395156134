import { useCoreContext } from '../../../hooks/contexts/coreProvider'
import { TutorialsClientPlanner } from './clientPlanner'
import { TutorialsPlanner } from './planner'

const TUTORIAL_MAPPER = {
  0: TutorialsPlanner,
  1: TutorialsPlanner,
  2: TutorialsClientPlanner
}

export const Tutorials = () => {
  const { tipoUsuario } = useCoreContext()

  if (typeof tipoUsuario === 'number') return TUTORIAL_MAPPER[tipoUsuario]()
}
