import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SimplifiedOrcamento } from '../../../domain/orcamento/Orcamento'
import { useCurrentFamilyId } from '../../../hooks/useCurrentFamilyId'
import { useFamilyOrcamentos } from '../../../queries/orcamento/queries'
import { useObjectLocalStorage } from '../../../hooks'
import { LancamentoEdit } from '../LancamentosTable/columns'
import { OrcamentoSelect } from '../../Selects'

interface Props {
  selectedId: SimplifiedOrcamento['id']
}

const Text = styled.span`
  text-align: center;
  align-items: center;
`

const SelectContainer = styled.div`
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
  min-width: 135px;
`

const OrcamentoCell = ({ selectedId }: Props) => {
  const { familyId } = useCurrentFamilyId()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentos(familyId) || {}

  const text = useCallback(() => {
    if (isLoadingOrcamentos) {
      return '...'
    }
    let name = 'Sem orçamento'
    // console.log({ selectedId, orcamentos })
    if (orcamentos?.length && selectedId) {
      const getName = orcamentos?.find(({ id }) => {
        // console.log({ id, selectedId })
        if (id === selectedId) {
          return true
        }
      })
      // console.log({ getName })
      // @ts-expect-error expected-error
      if (getName) name = getName?.name ?? getName?.nome
    }

    return name
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOrcamentos, selectedId])

  // if (isLoadingOrcamentos) {
  //   return <Text>...</Text>
  // }

  // const name = orcamentos?.length ? orcamentos?.find(({ id }) => id === selectedId)?.name : 'Sem orçamento'

  // console.log({ orcamentos })
  // return <Text>{name}</Text>

  return <Text>{text()}</Text>
}

export const OrcamentoCellText = ({ lancamentoId, id, onSelectOrcamento, orcamentoPadrao, tipo }) => {
  const { value: edited, setValue } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})

  const OrcamentoField = useCallback(() => {
    const selectedId = edited[lancamentoId]?.orcamentoId

    return (
      <SelectContainer>
        <OrcamentoSelect
          type={tipo}
          onSelect={({ id: orcamentoId }) => {
            setValue({ ...edited, [lancamentoId]: { orcamentoId } })
            onSelectOrcamento?.()
          }}
          selectedId={selectedId}
        />
      </SelectContainer>
    )
  }, [edited, lancamentoId, onSelectOrcamento, setValue, tipo])

  if (orcamentoPadrao) {
    return <OrcamentoField />
  }

  return <OrcamentoCell selectedId={id} />
}

export default OrcamentoCell
