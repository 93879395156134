import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Row } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay-ts'
import routes from '../routes.tsx'
import '../assets/css/spinner-aux.css'

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default')
  }
  getRoutes = (routes) => {
    if (routes == null) {
      return <></>
    }

    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return <Route path={`${prop.layout}${prop.path}`} component={prop.component} key={key} />
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <LoadingOverlay active={global.spinnerActive} spinner text={global.spinnerMessage}></LoadingOverlay>
        <div
          className="auth"
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {/* <div className="main-content auth">
          <AuthNavbar />
          <div className="header py-5 py-lg-5">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white">Seja Bem Vindo!</h1>
                  </Col>
                </Row>
              </div>
            </Container> */}
          {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          {/* </div> */}
          {/* Page content */}
          {/* <Container className="mt--auth"> */}
          <Row className="justify-content-center" style={{ height: '100vh', width: '100vw' }}>
            <Switch>{this.getRoutes(routes)}</Switch>
          </Row>
          {/* </Container> */}
        </div>
        {/* <AuthFooter /> */}
      </>
    )
  }
}

export default Auth
