import React from 'react'
import * as S from './styles'
import { NavLink } from 'reactstrap'
import { TAB_CODE_TO_NAME, TABS } from '../SaveDataTransaction/types'

export const TitleModal = ({ item, state, setActiveTab, activeTab }) => {
  // const activeEquity = state.patrimonioAtivosDados?.[item?.patrimonioAtivoId]
  const title = state.editingTransaction ? 'Edição de Lançamento' : 'Cadastro de Lançamento'
  let subtitle: undefined | string

  if (item?.patrimonio !== null && item?.patrimonio?.vistaType) {
    subtitle = ` - ${item.patrimonio.vistaType}`
  }

  return (
    <S.ModalHeader>
      <div className="pop-up-title">
        <i className="ni ni-check-bold"></i>
        <h2 className="skylight-modal-title">
          {title}
          {subtitle && <span style={{ fontSize: '14px' }}>{subtitle}</span>}
          {/* <span
            style={
              item.operacao === 1 && item.patrimonioAtivoId != null
                ? { fontSize: '14px' }
                : { display: 'none' }
            }
          >
            {' '}
            - Qtd. Atual: {activeEquity !== null ? activeEquity?.[0] : ''} - Valor Atual: R${' '}
            {activeEquity != null && activeEquity[1] != null
              ? activeEquity[1].toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              : ''}
          </span> */}
        </h2>
      </div>

      <S.Navigation>
        {TABS.map((tab) => (
          <S.NavItem
            id={`lancamento-${tab.toLowerCase()}`}
            key={`lancamento-${tab.toLowerCase()}`}
            className={`lancamento-opcoes${activeTab === tab ? ' active' : ''}`}
            style={{ border: 'none', listStyle: 'none' }}
            onClick={(event) => {
              event.preventDefault()

              if (activeTab !== tab) {
                setActiveTab(tab)
              }
            }}
          >
            <NavLink className="nav-link-icon button-border">
              <span className="nav-link-inner--text">{TAB_CODE_TO_NAME[tab]}</span>
            </NavLink>
          </S.NavItem>
        ))}
      </S.Navigation>
    </S.ModalHeader>
  )
}
