import styled from 'styled-components'

export const Title = styled.h2`
  padding-bottom: 2vh;
`

export const Row = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  width: 100%;
`

export const RowForm = styled(Row)`
  flex-wrap: wrap;
  gap: 16px;

  > div {
    flex-basis: 45%;
    min-width: auto;
    max-width: 48%;

    > div {
      padding: 0;
    }
  }
`

export const SelectContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 29vw;
  height: 6rem;
  display: flex;
  flex: 1;
  padding: 0.5vw;
`

export const ErrorMessage = styled.p`
  color: #dd7777;
  padding-top: 0.5rem;
  font-size: 0.8rem;
  font-style: italic;
`
