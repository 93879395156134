import { useMutation, useQueryClient } from 'react-query'
import { cacheBaseKey } from './fixedAssetQueries'
import {
  createFixedAsset as createFixedAssetAPI,
  updateFixedAsset as updateFixedAssetAPI,
  deleteFixedAsset as deleteFixedAssetAPI
} from '../../domain/investments/api'
import {
  FixedAsset,
  FixedAssetCreationRequest,
  FixedAssetUpdateRequest,
  DeleteAssetRequest
} from '../../domain/investments/fixedAsset'
import { asyncDelay } from '../../domain/timing'

export const useCreateFixedAsset = () => {
  const client = useQueryClient()
  const {
    mutate: createFixedAsset,
    mutateAsync: createFixedAssetAsync,
    isLoading: isCreatingFixedAsset,
    isError: isErrorCreatingFixedAsset,
    error: fixedAssetCreationError,
    isSuccess: createdFixedAsset,
    reset: resetFixedAssetCreation
  } = useMutation<FixedAsset, Error, FixedAssetCreationRequest, unknown>(createFixedAssetAPI, {
    retry: false,
    onSuccess: async () => {
      // backend updates are async, so we need to wait a bit before invalidating the cache
      await asyncDelay(750)
      client.invalidateQueries(cacheBaseKey)
    }
  })

  return {
    createFixedAsset,
    createFixedAssetAsync,
    isCreatingFixedAsset,
    isErrorCreatingFixedAsset,
    fixedAssetCreationError,
    createdFixedAsset,
    resetFixedAssetCreation
  }
}

export const useUpdateFixedAsset = () => {
  const client = useQueryClient()
  const {
    mutate: updateFixedAsset,
    mutateAsync: updateFixedAssetAsync,
    isLoading: isUpdatingFixedAsset,
    isError: isErrorUpdatingFixedAsset,
    error: fixedAssetUpdateError,
    isSuccess: updatedFixedAsset,
    reset: resetFixedAssetUpdate
  } = useMutation<FixedAsset, Error, FixedAssetUpdateRequest, unknown>(updateFixedAssetAPI, {
    retry: false,
    onSuccess: async () => {
      await asyncDelay(250)
      client.invalidateQueries(cacheBaseKey)
    }
  })

  return {
    updateFixedAsset,
    updateFixedAssetAsync,
    isUpdatingFixedAsset,
    isErrorUpdatingFixedAsset,
    fixedAssetUpdateError,
    updatedFixedAsset,
    resetFixedAssetUpdate
  }
}

export const useDeleteFixedAsset = () => {
  const client = useQueryClient()
  const {
    mutate: deleteFixedAsset,
    mutateAsync: deleteFixedAssetAsync,
    isLoading: isDeletingFixedAsset,
    isError: isErrorDeletingFixedAsset,
    error: fixedAssetDeletionError,
    isSuccess: deletedFixedAsset,
    reset: resetFixedAssetDeletion
  } = useMutation<void, Error, DeleteAssetRequest, unknown>(deleteFixedAssetAPI, {
    retry: false,
    onSuccess: async () => {
      // backend updates are async, so we need to wait a bit before invalidating the cache
      await asyncDelay(750)
      client.invalidateQueries(cacheBaseKey)
    }
  })

  return {
    deleteFixedAsset,
    deleteFixedAssetAsync,
    isDeletingFixedAsset,
    isErrorDeletingFixedAsset,
    fixedAssetDeletionError,
    deletedFixedAsset,
    resetFixedAssetDeletion
  }
}
