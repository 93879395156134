import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { formatMonetaryValue } from '../../../domain/formatter'
import { SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import { TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { ValueCell, MeioPagamentoCell } from '../Cells'
import styled from 'styled-components'
import { Transaction } from '../../Modal/DeleteTransaction/types'
import { OrcamentoCellText } from '../Cells/OrcamentoCell'
import { CategoriaCellMemoized } from '../Cells/CategoriaCell'
import { useGetFilteredCategories } from '../../../queries/categoria/queries'

const CenteredText = styled.span`
  text-align: center;
`

export interface LancamentoEdit {
  [lancamentoId: number]: {
    orcamentoId: number
  }
}

export const useColumns = (
  selectedRows: SimplifiedLancamento[],
  onCategorization: () => void,
  onSelectOrcamento?: () => void
) => {
  const columns = useMemo(
    (): Column<SimplifiedLancamento>[] => [
      {
        Header: 'Data',
        id: 'Data',
        accessor: ({ data }) => data,
        Cell: ({ value }) => (value ? format(new Date(value), 'dd/MM/yyyy') : ''),
        width: 90
      },
      {
        Header: 'Descrição',
        id: 'Descrição',
        accessor: 'descricao',
        width: 300,
        Cell: ({ value: descricao }) => <CenteredText>{descricao}</CenteredText>
      },
      {
        Header: 'Valor',
        id: 'Valor',
        accessor: (row) => (row.parcelado ? row.valorParcela : row.valor),
        Cell: ({
          value: valor,
          row: {
            original: { tipo, categoriaId }
          }
        }: {
          value: SimplifiedLancamento['valor' | 'valorParcela']
          row: {
            original: SimplifiedLancamento
          }
        }) => {
          const { category } = useGetFilteredCategories({ categoriaId })
          let type = tipo
          if (category?.nome === 'Resgates') {
            type = 4
          }
          return <ValueCell tipo={type}>{formatMonetaryValue(valor ?? 0)}</ValueCell>
        },
        width: 80
      },
      {
        Header: 'Meio de Pagamento',
        id: 'Meio de Pagamento',
        accessor: 'meioPagamentoId',
        Cell: ({ value, row }) => {
          return <MeioPagamentoCell selectedId={value} row={row?.original as unknown as Transaction} />
        },
        disableSortBy: true
      },
      {
        Header: 'Orçamento',
        id: 'Orçamento',
        accessor: 'orcamentoId',
        Cell: ({
          value: id,
          row: {
            original: { id: lancamentoId, orcamentoPadrao, tipo }
          }
        }: {
          value: SimplifiedLancamento['orcamentoId']
          row: { original: SimplifiedLancamento }
        }) => {
          return (
            <OrcamentoCellText
              id={id}
              lancamentoId={lancamentoId}
              tipo={tipo}
              onSelectOrcamento={onSelectOrcamento}
              orcamentoPadrao={orcamentoPadrao}
            />
          )
          // const { value: edited, setValue } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})

          // const selectedId = edited[lancamentoId]?.orcamentoId
          // if (orcamentoPadrao)
          //   return (
          //     <SelectContainer>
          //       <OrcamentoSelect
          //         type={tipo}
          //         onSelect={({ id: orcamentoId }) => {
          //           setValue({ ...edited, [lancamentoId]: { orcamentoId } })
          //           onSelectOrcamento?.()
          //         }}
          //         selectedId={selectedId}
          //       />
          //     </SelectContainer>
          //   )
          // return <OrcamentoCell selectedId={id} />
        },
        width: 180
      },
      {
        Header: 'Categoria',
        id: 'Categoria',
        accessor: 'categoriaId',
        Cell: ({
          value: id,
          row: {
            original: { categoriaPadrao, id: lancamentoId, tipo }
          }
        }) => {
          // console.log({ id, categoriaPadrao, lancamentoId, tipo })
          return (
            <CategoriaCellMemoized
              id={id}
              categoriaPadrao={categoriaPadrao}
              onCategorization={onCategorization}
              selectedRows={selectedRows}
              lancamentoId={lancamentoId}
              tipo={tipo}
            />
          )
          // const { changeCategory, isCategoryChangeError, changedCategory, resetChangeCategory } =
          //   useChangeLancamentoCategory()

          // useEffect(() => {
          //   if (isCategoryChangeError)
          //     noticeError(new Error('Error changing category'), {
          //       orcamentoId: id,
          //       lancamentoId,
          //       tipo
          //     })
          // }, [isCategoryChangeError, id, lancamentoId, tipo])

          // useEffect(() => {
          //   if (changedCategory) resetChangeCategory()
          // }, [changedCategory, resetChangeCategory])

          // if (categoriaPadrao)
          //   return (
          //     <SelectContainer>
          //       <CategoriaSelect
          //         lancamentoId={lancamentoId}
          //         onSelect={(lancamentoId, orcamentoId, idCategoria) => {
          //           changeCategory({ id: lancamentoId, idCategoria })
          //           selectedRows.forEach(({ id }) => changeCategory({ id, idCategoria }))
          //           onCategorization()
          //         }}
          //       />
          //     </SelectContainer>
          //   )

          // return <CategoriaCell selectedId={id} />
        }
      },
      {
        Header: 'Parcelado',
        id: 'Parcelado',
        accessor: ({ parcelado }) => parcelado,
        Cell: ({ value }) => <>{value ? 'Sim' : 'Não'}</>,
        width: 70,
        disableSortBy: true
      },
      {
        Header: 'Tipo',
        id: 'Tipo',
        accessor: ({ tipo }) => tipo,
        Cell: ({ value }) => <ValueCell tipo={value}>{TipoOrcamento[value]}</ValueCell>,
        width: 110,
        disableSortBy: true
      }
    ],
    [onCategorization, onSelectOrcamento, selectedRows]
  )

  return columns
}
