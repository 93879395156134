import React from 'react'

interface Props {
  numbers: number[]
  selection: number
  onChange?: (newSelection: number) => void
  className?: string
}

export default ({ selection, numbers, onChange, className }: Props) => {
  return (
    <select
      value={selection}
      onChange={({ target: { value } }) => onChange?.(Number(value))}
      className={className}
    >
      {numbers.map((number, index) => (
        <option key={`number_${index}`} id={number.toString()}>
          {number}
        </option>
      ))}
    </select>
  )
}
