import { pickRandom } from '../random'

export const fixedAssetType = ['building', 'vehicle', 'company', 'other'] as const
export type FixedAssetType = (typeof fixedAssetType)[number]

export const translateFixedAssetType = (type: string) => {
  switch (type.toLowerCase().trim()) {
    case 'building':
      return 'Imóvel'
    case 'vehicle':
      return 'Veículo'
    case 'company':
      return 'Empresa'
    case 'other':
      return 'Outro'
    default:
      throw new Error(`Invalid fixed asset type: ${type}`)
  }
}

export interface FixedAsset {
  _id: string
  name: string
  familyId: number
  type: FixedAssetType
  value: number
  /**
  @param buyDate
    @description ISO formatted date in utc-0
    @example 
      const currentDateInUTC = new Date(Date.now());
      const currentDateInUTC0 = new Date(currentDateInUTC.toISOString().slice(0, -1) + 'Z');
  **/
  buyDate: string
}

export type FixedAssetCreationRequest = Omit<FixedAsset, '_id'>

export interface FixedAssetUpdateRequest extends Partial<FixedAsset> {
  _id: string
}

export interface DeleteAssetRequest {
  id: string
}

export const getLoadingFixedAsset = (): FixedAsset => ({
  _id: '---',
  buyDate: new Date().toISOString(),
  familyId: 0,
  name: '--- ----- ---',
  type: pickRandom([...fixedAssetType]),
  value: 0
})
