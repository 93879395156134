import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 0;
  width: 100%;

  .family-background {
    flex-shrink: 0;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
`

export const Logo = styled.div<{ $width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  flex: 1;

  img {
    max-width: 170px;
    width: ${({ $width }) => $width};
  }

  @media screen and (min-width: 768px) {
    height: auto;

    img {
      max-width: auto;
    }
  }
`

export const SidebarMenuLink = styled(NavLink)<{ $tipoUsuario: number }>`
  &.active {
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
    color: #fff !important;
  }
  &.active > .fa-angle-right {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
  }

  &.active,
  &.active i {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1
        ? colors.secondaryDarker
        : colors.backgroundSidebarManagerHighlightDarker || colors.primaryDarker} !important;
    border: none !important;
    border-image: none !important;
  }

  &[data-inactive] {
    opacity: 0.5;
  }
`
