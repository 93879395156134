import styled, { css } from 'styled-components'

const Container = styled.div``

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 4%;
  width: 100%;

  ${Container} {
    padding: 0%;
  }
`

export const DateRangePickerContainer = styled.div`
  padding-left: 20px;
`

export const Section = styled.div<{ justify?: string }>`
  align-items: center;
  display: flex;

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  h3,
  img {
    margin-bottom: 0;
  }
`
