import { Account as PluggyAccount, Connector, ItemStatus } from 'pluggy-js'

export interface Connection {
  id: string
  conexaoNome: string
  userId: number
  ultimoSucesso?: Date
  qtdContasCards: number
  status: string
  acaoRequerida: boolean
  contas: Account[]
}

export interface Account {
  id: string
  descricao: string
  meioPagamento?: unknown
  meioPagamentoId?: number
  tipo: 'BANK' | 'CREDIT'
  isActive: boolean
  itemId: string
}

export interface Item {
  _id: string
  itemId: string
  userId: number
  connector: Connector
  status: ItemStatus
  accounts: ItemAccount[]
  lastSyncDate?: string
  createdAt: string
  updatedAt: string
}

export const ITEM_STATUSES: Record<ItemStatus, string> = {
  LOGIN_ERROR: 'LOGIN_ERROR',
  OUTDATED: 'OUTDATED',
  UPDATED: 'UPDATED',
  UPDATING: 'UPDATING',
  WAITING_USER_INPUT: 'WAITING_USER_INPUT'
  // CREATING,
  // MERGING
}

const actionRequiredStatuses = [
  ITEM_STATUSES.LOGIN_ERROR,
  ITEM_STATUSES.OUTDATED,
  ITEM_STATUSES.WAITING_USER_INPUT
]

export const requiresAction = (status: ItemStatus) => actionRequiredStatuses.includes(status)

export interface ItemAccount extends PluggyAccount {
  isActive: boolean
  meioPagamentoId?: number
}
