import { noticeError } from '../../services/Monitoring'
import { UserType } from '../user/userType'

interface Token {
  email: string
  id: number
  type: UserType
  theme?: string
  empresaId?: number
  familiaId?: number
  pessoaId?: number
  planejadorId?: number
  iat: number
  manager: boolean | null
}

export const isJwtValid = (jwt: string): boolean => {
  try {
    parseJwt(jwt)
    return true
  } catch (err) {
    return false
  }
}

// TODO: improve this to validate the signature
export const parseJwt = (jwt?: string): Token | undefined => {
  if (!jwt?.trim().length) return undefined

  const [_, body] = jwt.split('.')
  const maybeToken = JSON.parse(atob(body))
  if (!isToken(maybeToken)) {
    const msg = `Invalid JWT content: ${JSON.stringify(maybeToken)}`
    const error = new Error(msg)
    noticeError(error)
    throw error
  }
  return maybeToken
}

// TODO: remover any abaixo
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isToken = (value?: any): value is Token =>
  value &&
  typeof value.email === 'string' &&
  typeof value.id === 'number' &&
  typeof value.type === 'number' &&
  (value.empresaId == null || typeof value.empresaId === 'number') &&
  (value.familiaId == null || typeof value.familiaId === 'number') &&
  (value.pessoaId == null || typeof value.pessoaId === 'number') &&
  (value.planejadorId == null || typeof value.planejadorId === 'number') &&
  typeof value.iat === 'number'

export default Token
