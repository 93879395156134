import React from 'react'
import { AlertModal } from '../alertModal'

export const Success = ({ onClose, size }) => {
  setTimeout(onClose, 2000)

  return (
    <AlertModal
      mode="toasty"
      toastyType="success"
      open={true}
      onClose={onClose}
      title="Exclusão de Lançamento"
    >
      <h4>
        {size > 1 ? 'Os lançamentos foram excluídos com sucesso!' : 'O lançamento foi excluído com sucesso!'}
      </h4>
    </AlertModal>
  )
}
