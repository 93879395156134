import React from 'react'
import Datepicker from 'react-datepicker'
import MaskedInput from 'react-maskedinput'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

const Container = styled.div`
  .react-datepicker-wrapper {
    display: block !important;
  }
`

export const DateSelect = ({
  id = 'dateSelect',
  onChange,
  selected,
  className
}: {
  id?: string
  onChange: (date: Date) => void
  selected?: Date
  className?: string
}) => {
  return (
    <Container>
      <Datepicker
        className={className}
        locale="br"
        id={id}
        dateFormat="dd/MM/yyyy"
        selected={selected}
        showYearDropdown
        dateFormatCalendar="MMMM"
        scrollableYearDropdown
        yearDropdownItemNumber={5}
        // @ts-expect-error TODO
        onChange={(date) => onChange(date)}
        customInput={<MaskedInput mask="11/11/1111" />}
      />
    </Container>
  )
}
