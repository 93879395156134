import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { InvestmentItemIdProps } from '../../../domain/investments'
import { formatMonetaryValue } from '../../../domain/formatter'
import ReactTooltip from 'react-tooltip'
import { Box } from '@material-ui/core'
import Switch from 'react-switch'
import { useSuspendInvestmentById } from '../../../queries/investments/mutations'

export const useColumns = () => {
  const columns = useMemo((): Column<InvestmentItemIdProps>[] => {
    return [
      {
        Header: 'Descrição',
        id: 'name',
        accessor: 'name'
      },
      {
        Header: 'Valor Aplicado',
        id: 'amountOriginal',
        accessor: 'amountOriginal',
        Cell: ({ value }) =>
          value ? (
            <Box color="success.main">R$ {formatMonetaryValue(value)}</Box>
          ) : (
            <>
              <span data-tip="Valor não informado">- - -</span>
              <ReactTooltip place="top" type="dark" effect="float" />
            </>
          )
      },
      {
        Header: 'Valor Atual',
        id: 'amount',
        accessor: 'amount',
        Cell: ({ value }) => (value ? `R$ ${formatMonetaryValue(value)}` : '- - -')
      },
      {
        Header: 'Ativo / Suspenso',
        id: 'isSuspended',
        accessor: 'isSuspended',
        Cell: ({
          value,
          row: {
            original: { id }
          }
        }) => {
          const { suspendInvestmentByIdAsync } = useSuspendInvestmentById()
          return (
            <Switch
              onChange={() => suspendInvestmentByIdAsync({ id, isSuspended: !value })}
              checked={!value}
            />
          )
        }
      }
    ]
  }, [])
  return columns
}
