import React, { useEffect } from 'react'
import Select from 'react-select'
import { FileType } from '../../domain/extrato/BankAndType'
import { useBankAndTypes } from '../../queries/extrato/queries'

interface Props {
  onChange: (value: FileType) => void
  currentBank?: string
  className?: string
}

export default ({ onChange, currentBank, className }: Props) => {
  const { banksAndTypes } = useBankAndTypes()
  const getBankFormats = (bank?: string) =>
    banksAndTypes?.find((fullBank) => fullBank.bank === bank)?.types ?? []
  const formats = getBankFormats(currentBank)

  const singleOption = formats.length === 1
  useEffect(() => {
    if (!singleOption) return
    onChange(formats[0])
  }, [formats, onChange, singleOption])

  return (
    <Select
      value={singleOption ? formats[0] : undefined}
      options={formats}
      placeholder={formats.length === 1 ? formats[0].label : 'Formato'}
      className={className}
      isDisabled={!formats || formats.length <= 1}
      onChange={onChange}
      filter={formats.length === 1 && formats[0]}
    />
  )
}
