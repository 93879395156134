import { format } from 'date-fns'
import { useMemo } from 'react'
import { Column } from 'react-table'

import { formatMonetaryValue } from '../../../domain/formatter'
import { FamilyBilling } from '../../../domain/planner'

export const useColumns = (valuePerPlanner: number) => {
  const columns = useMemo(
    (): Column<FamilyBilling>[] => [
      {
        Header: 'Nome',
        id: 'name',
        accessor: 'name'
      },
      {
        Header: 'Data de criação',
        id: 'creation',
        accessor: 'creation',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
      },
      {
        Header: 'Status',
        id: 'active',
        accessor: 'active',
        Cell: ({ value }) => (value ? 'Ativo' : 'Inativo')
      },
      {
        Header: 'Data de inativação',
        id: 'lastUpdated',
        accessor: 'lastUpdated',
        Cell: ({
          value,
          row: {
            original: { active }
          }
        }) => (active ? '- - -' : format(new Date(value), 'dd/MM/yyyy'))
      },
      {
        Header: 'Valor',
        id: 'value',
        accessor: () => valuePerPlanner,
        Cell: ({ value }) => `R$ ${formatMonetaryValue(value)}`
      }
    ],
    [valuePerPlanner]
  )
  return columns
}
