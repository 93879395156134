import { useQuery } from 'react-query'
import { fetchFixedAssetsByFamilyId } from '../../domain/investments/api'
import { FixedAsset } from '../../domain/investments/fixedAsset'
import { useCurrentFamilyId } from '../../hooks'
import { QueryOptions } from '../queryOptions'

export const cacheBaseKey = 'investments'

export const useFamilyFixedAssets = (familyId?: number, options?: QueryOptions<FixedAsset[]>) => {
  const {
    data: fixedAssets,
    error: fixedAssetsErrors,
    isLoading: isFixedAssetsLoading,
    isError: isFixedAssetsError
  } = useQuery<unknown, Error, FixedAsset[]>(
    [cacheBaseKey, familyId, 'fixedAssets'],
    () => fetchFixedAssetsByFamilyId(familyId as number),
    {
      ...options,
      enabled: !!familyId
    }
  )
  return {
    fixedAssets,
    fixedAssetsErrors,
    isFixedAssetsLoading,
    isFixedAssetsError
  }
}

export const useCurrentFamilyFixedAssets = (options?: QueryOptions<FixedAsset[]>) => {
  const { familyId } = useCurrentFamilyId()
  return useFamilyFixedAssets(familyId || 0, options)
}
