import { noticeError } from '../../../services/Monitoring'
import { bankIntegrationClient, putWithToken } from '../../../webClient'

export interface Params {
  accountId: string
  meioPagamentoId: number
}

export const changeAccountMeioPagamento = (params: Params) => {
  const linkReq = linkAccountToMeioPagamento(params)
  const reassoaciateReq = reassociateLancamentosToNewMeioPagamento(params)
  return Promise.all([linkReq, reassoaciateReq])
}

const linkAccountToMeioPagamento = async ({ accountId, meioPagamentoId }: Params) => {
  const url = `account/${accountId}/${meioPagamentoId}`
  const { status } = await bankIntegrationClient.post(url)
  if (status !== 200) {
    const msg = `Error linking account ${accountId} to meio pagamento ${meioPagamentoId}`
    const error = new Error(msg)
    console.error(msg, error)
    noticeError(error)
    throw error
  }
}

const reassociateLancamentosToNewMeioPagamento = async ({ accountId, meioPagamentoId }: Params) => {
  const url = `bankintegration/account/${accountId}/${meioPagamentoId}`
  const { status } = await putWithToken(url)
  if (status !== 200) {
    const msg = `Error associating old lançamento account ${accountId} to meio ${meioPagamentoId}`
    const error = new Error(msg)
    console.error(msg, error)
    noticeError(error)
    throw error
  }
}
