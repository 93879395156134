import { noticeError } from '../../../services/Monitoring'
import { extratosApi } from '../../../webClient'
import BankAndType from '../BankAndType'

const bancosRetirados = ['sicoob_cartaoCredito']

const adaptingDataForSelects = (response: Response[]): BankAndType[] =>
  response
    .flatMap(({ bank, label, paymentMethods }) =>
      paymentMethods.map(({ value: paymentValue, label: paymentLabel, types, typesPdf }) => ({
        bank: `${bank}_${paymentValue}`,
        label: `${label} - ${paymentLabel}`,
        types,
        typesPdf
      }))
    )
    .filter(({ bank }) => !bancosRetirados.includes(bank))
    .sort(({ label: first }, { label: second }) => (first > second ? 1 : -1))

interface Response {
  bank: string
  label: string
  paymentMethods: {
    value: string
    label: string
    types: {
      value: string
      label: string
    }[]
    typesPdf: {
      value: string
      label: string
    }[]
  }[]
}

export default async () => {
  try {
    const { status, data } = await extratosApi.get<Response[]>('/types')
    if (status === 200) {
      return adaptingDataForSelects(data)
    }
  } catch (error) {
    const err = new Error(`Erro ao buscar por tipos. Erro: ${JSON.stringify(error)}`)
    noticeError(err)
    throw err
  }
}
