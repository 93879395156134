import styled, { css } from 'styled-components'
import colors from '../../../domain/Colors'

export const Footer = styled.div<{ rounded: boolean }>`
  ${({ rounded }) => css`
    font-weight: bold;
    background-color: ${colors.lightBlue};
    border-radius: 0 0 ${rounded ? '8px 8px' : '0 0'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    min-height: 64px;
  `}
`

export const FooterRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
`

export const Text = styled('span')`
  ${() => css`
    color: ${colors.white};
    font-weight: bold;
  `}
`
