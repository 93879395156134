import styled from 'styled-components'

export const BeneficiosButton = styled.a`
  margin: 8px 16px 8px 16px;
  border-left: solid 2px #9847f4;
  border-right: solid 2px #9847f4;
  padding: 8px 12px 8px 12px;
  font-size: 16px;
  width: fit-content;
  border-radius: 80px;
  font-weight: 600;
  background: #faf6ff;
  color: #9847f4 !important;
  box-shadow: 4px 4px 8px #00000016;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #300660;
    color: #fff !important;
    box-shadow: 4px 4px 8px #00000088;
  }
`
