import React from 'react'
import { showYesNoAlert } from '../../../hooks/useShowAlert'
import { TrashCanIcon } from '../../Icons'
import * as S from './styles'

export const CellDeleteEquity = ({ value: id, row: { original: asset }, onDelete }) => (
  <S.OptionsContainer>
    <TrashCanIcon
      size="small"
      tooltip={`Deletar '${asset.name}'`}
      onClick={() => {
        showYesNoAlert(`Deletar patrimônio ${asset.name}?`, 'Confirma a deleção do patrimônio?', () =>
          onDelete(id)
        )
      }}
    />
  </S.OptionsContainer>
)
