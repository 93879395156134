import { useMutation, useQueryClient } from 'react-query'
import { accountCacheKey, cacheBaseKey } from './queries'
import {
  deleteConnection,
  Params as DeleteConnectionParams
} from '../../domain/bankingIntegration/api/deleteConnection'
import { createNewConnection, Params } from '../../domain/bankingIntegration/api/createNewConnection'
import {
  changeAccountMeioPagamento,
  Params as LinkParams
} from '../../domain/bankingIntegration/api/linkAccountMeioPagamento'

export const useLinkAccountToMeioPagamentoMutation = () => {
  const client = useQueryClient()
  const { mutateAsync, isLoading, isError, error } = useMutation<[void, void], Error, LinkParams, unknown>(
    changeAccountMeioPagamento,
    {
      onSuccess: () => {
        client.invalidateQueries(accountCacheKey)
      }
    }
  )
  return {
    linkAccountToMeioPagamento: mutateAsync,
    isLinking: isLoading,
    isLinkError: isError,
    linkError: error
  }
}

export const useDeleteConnectionMutation = () => {
  const client = useQueryClient()
  const { mutateAsync, isLoading, isError, error } = useMutation<
    void,
    Error,
    DeleteConnectionParams,
    unknown
  >(deleteConnection, {
    onSuccess: () => {
      client.invalidateQueries(accountCacheKey)
    }
  })
  return {
    deleteConnection: mutateAsync,
    isDeletingConnection: isLoading,
    isConnectionDeletionError: isError,
    connectionDeletionError: error
  }
}

export const useCreateConnectionMutation = () => {
  const client = useQueryClient()
  const {
    mutateAsync: createConnection,
    isLoading: isCreatingConnection,
    isError: isConnectionCreationError,
    error: connectionCreationError
  } = useMutation<void, Error, Params, unknown>(createNewConnection, {
    onSuccess: () => {
      client.invalidateQueries(cacheBaseKey)
    }
  })
  return {
    createConnection,
    isCreatingConnection,
    isConnectionCreationError,
    connectionCreationError
  }
}
