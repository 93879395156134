import React, { useEffect } from 'react'
import * as S from '../../styles'
import { HeaderCellProps } from './types'

export const HeaderCell = <T extends object>({ column, sortable, onSort }: HeaderCellProps<T>) => {
  const headerProps = sortable ? column.getSortByToggleProps() : undefined
  const { isSorted, isSortedDesc, id } = column

  useEffect(() => {
    const sort = isSorted ? (isSortedDesc ? 'desc' : 'asc') : 'none'
    onSort?.(id as string, sort)
  }, [id, isSorted, isSortedDesc, onSort])

  return (
    <S.HeadCell width={column.width as number} {...column.getHeaderProps(headerProps)}>
      {column.render('Header')}
      {isSorted ? isSortedDesc ? <>↑</> : <>↓</> : null}
    </S.HeadCell>
  )
}
