import React from 'react'
import styled from 'styled-components'
import { useRequiredAuth } from '../hooks/useAuth'

const FitaDiv = styled.div<{ isClient: boolean }>`
  position: relative;
  margin: 2px auto;
  width: 90%;
  line-height: 28px;
  height: 30px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  background: ${({ theme: { colors } }) => colors.backgroundSidebarManagerDarker || colors.secondaryDarker};
  ${({ isClient }) => (isClient ? 'display: none;' : '')}
  color: #FFF;

  &::before,
  &::after {
    content: '';
    line-height: 0;
    border-style: solid;
    border-width: 15px;
    position: absolute;
    top: 3px;
    z-index: -2000;
    width: 5px;
  }

  &::before {
    border-color: ${({ isClient, theme: { colors } }) =>
      isClient
        ? `${colors.primaryDarker} ${colors.primaryDarker} ${colors.primaryDarker} transparent`
        : `${colors.backgroundSidebarManagerDarker || colors.secondaryDarker} ${
            colors.backgroundSidebarManagerDarker || colors.secondaryDarker
          } ${colors.backgroundSidebarManagerDarker || colors.secondaryDarker} transparent`};
    left: -25px;
  }
  &::after {
    border-color: ${({ isClient, theme: { colors } }) =>
      isClient
        ? `${colors.primaryDarker} transparent ${colors.primaryDarker} ${colors.primaryDarker}`
        : `${colors.backgroundSidebarManagerDarker || colors.secondaryDarker} transparent ${
            colors.backgroundSidebarManagerDarker || colors.secondaryDarker
          } ${colors.backgroundSidebarManagerDarker || colors.secondaryDarker}`};
    right: -25px;
  }
`

const Fitas = () => {
  const { isPlanned, isManager, isSupport } = useRequiredAuth()

  return (
    <FitaDiv isClient={isPlanned}>
      {isPlanned ? 'Cliente' : isSupport ? 'Administrador' : isManager ? 'Gestor' : 'Planejador'}
    </FitaDiv>
  )
}

export default Fitas
