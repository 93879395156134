import styled from 'styled-components'
import colors from '../../../domain/Colors'
import { Colors } from '../../../domain/Colors'

export const Button = styled.button<{ bgColor?: Colors }>`
  background: ${({ bgColor }) => bgColor || colors.darkGray};
  border-radius: 5px;
  border: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
  outline: none;
  padding: 6px 18px;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }
`

export const Title = styled.h2`
  margin-bottom: 1rem;
`

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 5;
  margin-top: 16px;
`
