import { noticeError } from '../../../services/Monitoring'
import { deleteWithToken } from '../../../webClient'

export interface Props {
  ids?: number[]
  excluirFuturasParcelas?: boolean
  /**
   * @description Remove a atual e as parcelas futuras
   */
  excluirParcial?: boolean
}

export const deleteManyById = async ({ ids, excluirFuturasParcelas, excluirParcial }: Props) => {
  if (!ids || ids.length === 0) return

  let url = `lancamento/bulk/${ids.join()}`
  let params

  // Somente selecionados
  if (!excluirFuturasParcelas && !excluirParcial) {
    params = {
      excluirFuturosLancamentos: false
    }
  }

  // Parcial
  if (excluirParcial) {
    url = `lancamento/bulk/currentAndFutureOnly/${ids.join()}`
  }

  // Total
  if (excluirFuturasParcelas) {
    params = { excluirFuturosLancamentos: excluirFuturasParcelas }
  }

  const { status } = await deleteWithToken(url, {}, { params, timeout: 10 * 1000 })

  if (status !== 200) {
    const message = `Error deleting lançamentos ${ids.join()}`
    console.error(message, { ids })
    const error = new Error(message)
    noticeError(error, { ids })
    throw error
  }
}
