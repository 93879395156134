import React, { useEffect, useMemo } from 'react'
import { UserType, userTypes } from '../../../domain/user/userType'
import { MAP_PLANNER } from './helpPlanner'
import { MAP } from './helpClientPlanner'
import { getRouteNameByPath } from '../../../routes'
import { ModalV2 } from '../ModalV2'
import { Tooltip, withStyles } from '@material-ui/core'
import * as S from './styles'
import NavLink from 'reactstrap/lib/NavLink'
import { useTutorialsContext } from '../../../hooks/contexts/tutorialsContext'
import { useCoreContext } from '../../../hooks/contexts/coreProvider'
import { useLocalStorage } from '../../../hooks'

const HelpTooltip = withStyles((_theme) => ({
  tooltip: {
    backgroundColor: '#0FA4C3',
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Open Sans'
  }
}))(Tooltip)

export const getRouteProps = () => {
  const pageId = window.location.pathname.replace(/\/v1\/admin/, '')
  const routeName = getRouteNameByPath(pageId) ?? pageId

  return {
    pageId,
    path: routeName,
    name: routeName?.replace(/\//gi, '')
  }
}

const getPage = ({ pageId, userType: type }) => {
  const userType: UserType = userTypes[type]
  let Page: () => JSX.Element | undefined

  if (['planejador', 'admin'].includes(userType)) {
    Page = MAP_PLANNER[pageId]

    if (!Page) {
      Page = MAP[pageId]
    }
  } else {
    Page = MAP[pageId]
  }

  return {
    Page
  }
}

export const HelpModal = () => {
  const { tipoUsuario: userType } = useCoreContext()
  const { isOpen, setIsOpen } = useTutorialsContext()
  const { pageId, name } = getRouteProps()
  const { Page } = getPage({ pageId, userType })
  const { value: storageValue, setValue: setStorageValue } = useLocalStorage(pageId?.replace('/v1/admin', ''))
  const automaticOpen = !storageValue

  const handleClose = () => {
    setIsOpen?.(false)
    globalThis.bodyScroll(false)
  }

  useEffect(() => {
    if (automaticOpen) {
      setIsOpen?.(true)
      setStorageValue('ok')
    }
  }, [])

  useEffect(() => {
    if (isOpen && pageId) {
      setStorageValue('ok')
      globalThis.bodyScroll(isOpen)
    }
  }, [pageId, setStorageValue, isOpen])

  if (!Page) {
    return <></>
  }

  const Header = () => (
    <S.Header>
      <h4>{name}</h4>
      <S.LinkButton href="/admin/tutoriais">Ver todos os tutoriais</S.LinkButton>
    </S.Header>
  )

  return (
    <ModalV2 open={isOpen} close={handleClose} title={<Header />}>
      {Page()}
    </ModalV2>
  )
}

export const HelpModalButton = ({ bgColor }: { bgColor: string }) => {
  const { value: tipoUsuarioSetado } = useLocalStorage('tipo-usuario')
  const { setIsOpen } = useTutorialsContext()
  const { pageId, name } = getRouteProps()
  const _name = name === 'Tutoriais' ? false : name
  const { Page } = getPage({ pageId, userType: tipoUsuarioSetado })

  return useMemo(
    () => (
      <S.HelpModalButton disabled={!Page}>
        <HelpTooltip title={`Tutoriais${_name ? `: ${_name}` : ''}`} placement="right">
          <NavLink
            id="abrir-ajuda"
            className="nav-link-icon adaptarNavLinkBlue"
            style={{
              background: bgColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => setIsOpen(true)}
          >
            <span
              style={{
                fontWeight: 'bolder',
                color: 'white'
              }}
            >
              <i className="fas fa-play-circle" />
            </span>
          </NavLink>
        </HelpTooltip>
      </S.HelpModalButton>
    ),
    [Page, _name, bgColor, setIsOpen]
  )
}
