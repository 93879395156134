export type Size = 'small' | 'normal'

export const withSize = (size?: Size) => {
  if (!size) return '1.5rem'
  switch (size) {
    case 'small':
      return '1rem'
    case 'normal':
    default:
      return '1.5rem'
  }
}
