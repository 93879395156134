import { TTransactionTypes } from '../../../../../domain/lancamento/lancamento'

export type OrcamentoType = 0 | 1 | 2 | 3

export interface Transaction {
  accountId?: number
  agrupamento: unknown
  ativoId?: number
  categoria: Categoria
  categoriaId: number | null
  categoriaPadrao: number
  corretagem?: number
  corretoraId?: number
  cotizadoCorretamente?: boolean
  custoOperacional?: number
  data: Date
  dataVencimento: string | null
  descricao: string
  editInstallments: number
  editRepeated: boolean
  emolumentos?: number
  empresaId: string | number | null
  enableBudgetManager?: boolean
  familiaId: string | number | null
  financialInstitution?: string
  financialInstitutionCode: number
  id: number
  idInstituicaoFinanceira: number
  idMovimentacao?: number
  idTransacaoBob?: number
  impostoRenda?: number
  impostoRendaFormatado?: string
  iof?: number
  iofFormatado?: string
  isManual?: boolean
  lancamentoPatrimonioAtivo?: Patrimonio
  meioPagamento: MeioPagamento
  meioPagamentoId: number | null
  nomeCategoria?: string
  nomeMeioPagamento?: string
  nomeOrcamento?: string
  operacao: 0 | 1 | null
  orcamento: Orcamento
  orcamentoId: number
  orcamentoPadrao: number
  parcelado: boolean
  parcelas: number
  patrimonio: Patrimonio
  patrimonioAtivoId?: number
  patrimonioId: number | null
  pluggyInvestmentId: number
  pluggyItemId: number
  pluggyTransactionId: number
  precoCota: number
  quantidade: number | null
  quantidadeCota: number
  quantidadeFormatado?: string
  repeatedParcels?: number
  statusCategorizacaoBob?: number
  taxaAnual?: number
  taxaAnualFormatada?: string
  taxaCustomizada?: number
  taxaCustomizadaFormatada?: string
  tipo: number
  tipoDeParcela: TTransactionTypes | null
  tipoMeioPagamento?: number
  tipoOrcamento: OrcamentoType
  tipoParceladoAlteracaoParcela?: number
  tipoParceladoAlteracaoParcelaValor?: number
  tipoRetirada?: number
  typeKey: 1 | 2
  unbudget?: boolean
  uncategorized?: boolean
  valor: number
  valorCalculado: number | null
  valorFormatado: string
  valorParcela: number
}

export interface IParseTransactionData {
  lancamento: Transaction
  empresaId: number
  familiaId: number
  onSuccess?: () => void
  onError?: (message: string) => void
  parceled?: number | null
}

export interface Patrimonio {
  empresaId: number
  familiaId: number
  responsavelId: number
  produtoCustomizadoId: number
  descricao: 'CDB Pós'
  tipoPatrimonio: 3
  dataPrimeiraCompra: string
  qtdAtual: number
  valorAplicado: number
  valorAtual: number
  tipoInvestimento: number
  responsavel: string
  produtoCustomizado: null
  idCarteira: null
  cnpj: null
  naturezaFundo: null
  idAcaoBovespa: null
  idTesouroDireto: null
  idDebenture: null
  idEmissor: null
  idProduto: null
  idIndexador: null
  serieDaMoeda: null
  lancamentos: null
  empresa: null
  familia: null
  id: number
  dataCriacao: string
  usuarioCriacaoId: null
  usuarioCriacao: null
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: null
  usuarioUltimaAtualizacao: null
  ativo: number
}

export interface MeioPagamento {
  dono: Dono
  nome: string
  tipoMeioPagamento: number
  bandeira: unknown
  diaVencimento: unknown
  banco: Banco
  agencia: unknown
  conta: unknown
  bandeiraPrePago: unknown
  lancamentos: unknown
  dividas: unknown
  empresaId: number
  familiaId: number
  empresa: unknown
  familia: unknown
  id: number
  dataCriacao: string
  usuarioCriacaoId: number
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: number
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface IUserProps {
  ativo: number
  dataCriacao: string
  dataUltimaAtualizacao: string
  email: string
  empresaId: number
  familiaId: number
  habilitado: number
  id: number
  pessoaId: number
  planejador: string
  planejadorId: number
  senha: string
  tipo: number
  usuarioCriacao: number
  usuarioCriacaoId: number
  usuarioUltimaAtualizacao: number
  usuarioUltimaAtualizacaoId: number
}
export interface Dono {
  ativo: number
  celular: string
  chaveBob: unknown
  dataCriacao: string
  dataNascimento: string
  dataUltimaAtualizacao: string
  documento: string
  empresa: unknown
  empresaId: number
  estadoCivil: number
  familia: unknown
  familiaId: number
  id: number
  idContaFinanceira: string
  nome: string
  nomeUsuario: unknown
  sobrenome: string
  telefone: unknown
  temCarteira: number
  usuario: IUserProps
  usuarioCriacao: unknown
  usuarioCriacaoId: number
  usuarioUltimaAtualizacao: unknown
  usuarioUltimaAtualizacaoId: number
}

export interface Banco {
  nome: string
  codigoComp: string
  ispb: string
  id: number
  dataCriacao: string
  usuarioCriacaoId: unknown
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: unknown
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface Categoria {
  ativo: number
  dataCriacao: string
  dataUltimaAtualizacao: string
  desejaNotificado: number
  empresa: number
  empresaId: number
  familia: number
  familiaId: number
  frequencia: number
  grupo: number
  historicosCategoria: number
  id: number
  lancamentos: number
  meioPagamentoPadrao: {
    id: number
    nome: string
  }
  nome: string
  orcamento: Orcamento
  orcamentoId: number
  percentualparaNotificar: number
  suspenso: number
  usuarioCriacao: number
  usuarioCriacaoId: number
  usuarioUltimaAtualizacao: number
  usuarioUltimaAtualizacaoId: number
  valorEstimado: number
}

export interface Orcamento {
  ativo: number
  categorias: number
  dataCriacao: string
  dataUltimaAtualizacao: string
  empresa: number
  empresaId: number
  familia: number
  familiaId: number
  id: number
  nome: string
  orcamentosPadrao: number
  suspenso: number
  tipoOrcamento: OrcamentoType
  usuarioCriacao: number
  usuarioCriacaoId: number
  usuarioUltimaAtualizacao: number
  usuarioUltimaAtualizacaoId: number
}

export interface TransactionData {
  item: Transaction
  familiaId: number
  empresaId: number
}

export interface SaveDataEntryProps {
  data: {
    item: Transaction
    familiaId: number
    empresaId: number
  }
  title: string
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const TIPO_ORCAMENTO_CODE_NAME = {
  0: 'Receita',
  1: 'Despesa',
  2: 'Investimento',
  3: 'Dívida'
}

export const TABS = ['Receita', 'Despesa', 'Investimento', 'Dívida'] as const
export type TTabs = (typeof TABS)[number]

export const TAB_CODE_TO_NAME: { [key in TTabs]: string } = {
  [TABS[0]]: 'Receita',
  [TABS[1]]: 'Despesa',
  [TABS[2]]: 'Investimento',
  [TABS[3]]: 'Dívida'
}

export const TIPO_ORCAMENTO_NAME_CODE: { [key in TTabs]: OrcamentoType } = {
  Receita: 0,
  Despesa: 1,
  Investimento: 2,
  Dívida: 3
}

export interface DetailsTransactionProps {
  transaction?: Transaction
  editingTransaction: boolean
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  onError?: (message?: string) => void
}

export interface GetErrorProps {
  exception?: { Message: string }
  message: string
  status: number
}
