import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { IsManager, NotIsManager } from './PlannersByCompanyIdTable.styles'
import { formatMonetaryValue } from '../../../domain/formatter'
import { PlannerByCompanyComplete } from '../../../domain/planner/planner'

export const useColumns = () => {
  const columns = useMemo(
    (): Column<PlannerByCompanyComplete>[] => [
      {
        Header: 'Nome',
        id: 'name',
        accessor: 'name'
      },
      {
        Header: 'E-mail',
        id: 'email',
        accessor: 'email'
      },
      {
        Header: 'Documento',
        id: 'document',
        accessor: 'document'
      },
      {
        Header: 'Clientes',
        id: 'quantityOfFamilies',
        accessor: 'quantityOfFamilies'
      },
      {
        Header: 'Fatura',
        id: 'totalInvoice',
        accessor: 'totalInvoice',
        Cell: ({ value }) => `R$ ${formatMonetaryValue(value)}`
      },
      {
        Header: 'Gestor',
        id: 'manager',
        accessor: 'manager',
        Cell: ({ value }) => {
          return <>{value ? <IsManager /> : <NotIsManager />}</>
        }
      }
    ],
    []
  )
  return columns
}
