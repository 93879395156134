import styled from 'styled-components'

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DatePickerContainer = styled.div`
  position: relative;
`
