import { noticeError } from '../../../services/Monitoring'
import { postWithToken } from '../../../webClient'

export interface Props {
  id?: number
  data?: unknown
}

export const saveTransaction = async ({ id, data }: Props) => {
  let url = '/lancamento'
  if (id) url = `/lancamento/v2/${id}`

  const { status, data: lancamento } = await postWithToken(url, data, { timeout: 10 * 1000 })

  if (status !== 200) {
    const message = `Error ${id ? 'updating' : 'creating'} lançamento ${id}`
    console.error(message, { id })
    const error = new Error(message)
    noticeError(error, { id })
    console.error(error)
    return {
      status,
      data: lancamento
    }
  }

  return {
    status,
    data: lancamento
  }
}
