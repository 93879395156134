import { getWithToken } from '../../../webClient'

export type Params = {
  userId: number
}

export type Response = {
  firstName: string
  lastName: string
  email: string
  tipo: number
  familyName: string
  familyId: number
}

export const getPersonalByUserId = async ({ userId }: Params) => {
  const url = `/usuario/${userId}/personal`
  const { data: personal, status } = await getWithToken<Response>(url)

  if (status !== 200) {
    const msg = `Error getting User ${userId}`
    console.error(msg)
    throw new Error(msg)
  }
  return personal
}
