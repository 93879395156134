import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icons'
import { FaCheck } from 'react-icons/fa'
import closeIcon from '../../assets/img/theme/close-icon.svg'
import ReactTooltip from 'react-tooltip'
import colors from '../../domain/Colors'

type ToggleSwitchButtonProps = {
  onClick?: (checked: boolean) => void
  checked: boolean
  title?: string
}

export const ToggleSwitchButton = ({ onClick, checked, title }: ToggleSwitchButtonProps) => {
  return (
    <>
      <Wrapper data-tip={title} data-for={`icon_${title}`}>
        <ContainerInput isTrue={checked}>
          <input type="checkbox" onChange={() => onClick?.(!checked)} checked={checked} />
          <span />
          {checked ? <CheckIcon /> : <CloseIcon alt="Close icon" src={closeIcon} />}
        </ContainerInput>
      </Wrapper>
      <Tooltip effect="solid" place="bottom" id={`icon_${title}`} arrowColor="#4b4b4b" />
    </>
  )
}

const Tooltip = styled(ReactTooltip)`
  font-size: x-small !important;
  padding: 0.3vw !important;
  background-color: ${colors.highLightGray2} !important;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  p {
    margin-left: 0.5rem;
    color: #000;
    font-size: 0.8rem;
    line-height: 0;
    margin-bottom: 0.5rem;
  }
`

const styleIcons = css`
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
`

const CheckIcon = styled(FaCheck)`
  ${styleIcons}
  left: 7px;
`

const CloseIcon = styled(Icon)`
  ${styleIcons}
  right: 7px;
  opacity: 1;
  margin-bottom: 0;
  width: 12px;
`

const ContainerInput = styled.label<{ isTrue?: boolean }>`
  background-color: #ccc;
  ${({ isTrue }) => isTrue && 'background-color: #008800;'}
  width: 3rem;
  height: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  span {
    background-color: #fff;
    position: absolute;
    left: 0;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }

  input:checked + span {
    ${({ isTrue }) => (isTrue ? 'left: 1.5rem' : 'left: 0')};
  }
`
