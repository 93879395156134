import React from 'react'
import styled from 'styled-components'

const ClearBackground = styled.button`
  border: none;
  background: transparent;
  line-height: 1;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #943dff;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #7e32d0;
  }
`

interface Props {
  text: string
  onClick: () => void
}

export default ({ text: title, onClick }: Props) => (
  <ClearBackground onClick={onClick}>{title}</ClearBackground>
)
