import { cacheKey } from './queries'
import { cacheBaseKey as transactionsCacheKey } from '../lancamento/queries'
import {
  Params as DeleteBudgetParams,
  deleteBudget as deleteBudgetAPI
} from '../../domain/orcamento/api/deleteBudget'
import { BudgetDeletionResult } from '../../domain/orcamento/budgetDeletionResult'
import { useMutation, useQueryClient } from 'react-query'

export const useDeleteBudget = () => {
  const client = useQueryClient()
  const {
    mutate: deleteBudget,
    isLoading: isDeletingBudget,
    isError: isBudgetDeletionError,
    error: budgetDeletionError,
    isSuccess: budgetDeleted,
    reset: resetBudgetDeletion
  } = useMutation<BudgetDeletionResult, Error, DeleteBudgetParams, unknown>(deleteBudgetAPI, {
    retry: false,
    onSuccess: () => {
      client.invalidateQueries(cacheKey)
      client.invalidateQueries(transactionsCacheKey)
    }
  })
  return {
    deleteBudget,
    isDeletingBudget,
    isBudgetDeletionError,
    budgetDeletionError,
    budgetDeleted,
    resetBudgetDeletion
  }
}
