import styled, { css } from 'styled-components'
import { FooterRow } from '../Footer/Footer.styles'

export const ErrorContainer = styled('div')`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: 'flex-start';
    width: fit-content;
  `}
`

export const ExpandedContainer = styled('div')`
  ${() => css`
    padding: 20px;
    width: 100%;
  `}
`

export const Footer = styled(FooterRow)`
  ${() => css`
    text-align: center;

    span:first-child {
      width: 64px;
    }

    span + span {
      flex: 1;
    }
  `}
`
