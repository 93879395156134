import React from 'react'
import { OrcamentoSelect } from '../../../Selects'
import { ColumnFilterSelect } from '../columnNamesAndFilters'

export default ({ currentSelected, onChange }: ColumnFilterSelect) => {
  if (currentSelected !== undefined && typeof currentSelected !== 'number') {
    console.warn(`not numeric value passed to OrcamentoSelect: ${currentSelected}`)
    currentSelected = undefined
  }
  return (
    <OrcamentoSelect
      onSelect={({ id }) => onChange(id)}
      selectedId={currentSelected}
      defaultPlaceholder="Nenhum"
    />
  )
}
