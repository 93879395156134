type OrcamentoType = 0 | 1 | 2 | 3

export type InvestmentNumberCodes = 1 | 2 | 3 | 4 | 6 | 8 | 9 | 10 | 99
export type InvestmentNames =
  | 'Fundos'
  | 'Previdência'
  | 'Renda Fixa Pós'
  | 'Tesouro Direto'
  | 'Renda Fixa Pré'
  | 'Ações'
  | 'Debentures'
  | 'Moeda'
  | 'Customizado'

export const INVESTMENT_CODE_NAME: { [key in InvestmentNumberCodes]: InvestmentNames } = {
  1: 'Fundos',
  2: 'Previdência',
  3: 'Renda Fixa Pós',
  4: 'Tesouro Direto',
  6: 'Renda Fixa Pré',
  8: 'Ações',
  9: 'Debentures',
  10: 'Moeda',
  99: 'Customizado'
}

export const INVESTMENT_NAME_CODE = {
  Fundos: 1,
  Previdencia: 2,
  RendaFixaPos: 3,
  TesouroDireto: 4,
  RendaFixaPre: 6,
  Ações: 8,
  Debentures: 9,
  Moeda: 10,
  Customizado: 99
}

export const OPERACAO_NAME_CODE = {
  COMPRA: 0,
  VENDA: 1,
  SALDOINICIAL: 2,
  TAX: 3,
  TRANSFER: 4
}

export const PARCELED_CODE_NAME = {
  0: 'Parcelado',
  1: 'Repetido'
}

export const PARCELED_NAME_CODE = {
  Parcelado: 0,
  Repetido: 1
}

export const OPERACAO_CODE_NAME = {
  0: 'COMPRA',
  1: 'VENDA',
  2: 'SALDOINICIAL',
  3: 'TAX',
  4: 'TRANSFER'
}

export type PatrimonyTypes = 'Imóvel' | 'Veículo' | 'Empresa' | 'Investimento' | 'Outros'

export interface Categoria {
  nome: string
  orcamento: Orcamento
  orcamentoId: number
  valorEstimado: number
  frequencia: number
  grupo: number
  meioPagamentoPadrao: unknown
  suspenso: number
  lancamentos: unknown
  historicosCategoria: unknown
  empresaId: number
  familiaId: number
  empresa: unknown
  familia: unknown
  id: number
  dataCriacao: string
  usuarioCriacaoId: number
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: number
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface Orcamento {
  nome: string
  tipoOrcamento: OrcamentoType
  orcamentosPadrao: unknown
  suspenso: number
  categorias: unknown
  empresaId: number
  familiaId: number
  empresa: unknown
  familia: unknown
  id: number
  dataCriacao: string
  usuarioCriacaoId: number
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: number
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface MeioPagamento {
  dono: Dono
  nome: string
  tipoMeioPagamento: number
  bandeira: number
  diaVencimento: number
  banco: Banco
  agencia: string
  conta: string
  bandeiraPrePago: unknown
  lancamentos: unknown
  dividas: unknown
  empresaId: number
  familiaId: number
  empresa: unknown
  familia: unknown
  id: number
  dataCriacao: string
  usuarioCriacaoId: number
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: number
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface Dono {
  nomeUsuario: unknown
  nome: string
  sobrenome: string
  documento: string
  celular: string
  dataNascimento: string
  estadoCivil: number
  telefone: unknown
  idContaFinanceira: string
  temCarteira: number
  usuario: unknown
  chaveBob: unknown
  empresaId: number
  familiaId: number
  empresa: unknown
  familia: unknown
  id: number
  dataCriacao: string
  usuarioCriacaoId: number
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: number
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface Banco {
  nome: string
  codigoComp: string
  ispb: string
  id: number
  dataCriacao: string
  usuarioCriacaoId: unknown
  usuarioCriacao: unknown
  dataUltimaAtualizacao: string
  usuarioUltimaAtualizacaoId: unknown
  usuarioUltimaAtualizacao: unknown
  ativo: number
}

export interface TransactionProps {
  categoria: Categoria
  categoriaId: number
  categoriaPadrao: unknown
  data: string
  dataVencimento: string
  descricao: string
  financialInstitutionCode: unknown
  id: number
  meioPagamento: MeioPagamento
  meioPagamentoId: number
  operacao: number
  orcamento: Orcamento
  orcamentoId: number
  orcamentoPadrao: unknown
  parcelado: boolean
  parcelas: number
  patrimonio: {
    tipoInvestimento: InvestmentNumberCodes
    tipoPatrimonio: number
    patrimonioId: number
    patrimonio: unknown
  }
  pluggyInvestmentId: unknown
  pluggyItemId: unknown
  pluggyTransactionId: unknown
  precoCota: unknown
  quantidade: number
  quantidadeCota: unknown
  tipo: number
  tipoDeParcela: number
  tipoOrcamento: OrcamentoType
  valor: number
  valorFormatado: string
  valorParcela: number
  taxaCustomizadaFormatada: number
  taxaCustomizada: number
  taxaAnualFormatada: number
  taxaAnual: number
  lancamentoPatrimonioAtivo: number
  idInstituicaoFinanceira: number
  quantidadeFormatado: number | string
  tipoRetirada: string
  custoOperacional: number
  impostoRendaFormatado: number
  impostoRenda: number
  iof: number
  iofFormatado: number
  icms: number
  icmsFormatado: number
  patrimonioAtivoId: number
}

export interface OtherProps {
  editRepeated: boolean
  editInstallments: boolean
}
