import { postWithToken, getWithToken, postWithTokenApiV2 } from '../../../webClient'
import { SimplifiedOrcamento, SimplifiedOrcamentoWithCategory } from '../Orcamento'
import { ManyResults } from '../../Results'
import { noticeError } from '../../../services/Monitoring'
import { IOrcamentoFamily } from '../../planner/planner'

interface Response {
  label: string
  value: number
  tipo: number
}

interface Filter {}

export const fetchOrcamentoByFamilyIdApi = async (
  familyId: number
): Promise<{ orcamentos: SimplifiedOrcamento[] }> => {
  if (!familyId) {
    console.log('fetchByFamily: familyId is required')
    return {
      orcamentos: []
    }
  }
  const url = `orcamento/familia/${familyId}`
  const {
    data: { results },
    status
  } = await postWithToken<ManyResults<Response>, unknown>(url)

  if (status !== 200) {
    const error = new Error(`Error getting family ${familyId} orcamentos. status ${status}`)
    console.error(error)
    noticeError(error, { familyId })
    throw error
  }

  // return results?.map(({ label: name, value: id, tipo }) => ({ name, id, tipo })) || []
  return {
    orcamentos: results?.map(({ label: name, value: id, tipo }) => ({ name, id, tipo }))
  }
}

interface TableResponse {
  orcamentos: SimplifiedOrcamentoWithCategory[]
}

export const fetchByFamilyForTable = async (familyId: number): Promise<SimplifiedOrcamentoWithCategory[]> => {
  if (!familyId) {
    console.log('fetchByFamilyForTable: familyId is required')
    return []
  }

  const url = `orcamento/familia/${familyId}/tabela`
  const {
    data: { orcamentos },
    status
  } = await getWithToken<TableResponse>(url)
  if (status !== 200) {
    const error = new Error(`Error getting family ${familyId} orcamentos for table. status ${status}`)
    console.error(error)
    noticeError(error, { familyId })
    throw error
  }
  return orcamentos
}

export const fetchOrcamentoByFamilyIdFiltro = async (familyId: number, filter: Filter) => {
  const url = `api/orcamento/familia/${familyId}/filtro`
  const {
    data: { results: orcamentos, success, errorMsg, totalResults },
    status
  } = await postWithTokenApiV2<ManyResults<IOrcamentoFamily>, Filter>(url, filter)
  if (!success || status !== 200) {
    const msg = `Error getting orcamentos for family ${familyId} with filters ${JSON.stringify(filter)}`
    console.error(msg, errorMsg)
    throw new Error(msg)
  }
  return { orcamentos, totalResults }
}
