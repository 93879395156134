import React, { useMemo, useState } from 'react'

import { PageFirstRow } from '../../../components/Headers'
import * as S from './styles'
import { getRouteNameByPath } from '../../../routes'
import { MAP_PLANNER } from '../../../components/Modal/HelpModal/helpPlanner'
import { Showcase } from './Showcase'

export const TutorialsPlanner = () => {
  const [Page, setPage] = useState<{ value: () => JSX.Element; path: string }>()

  const List = useMemo(() => {
    return Object.entries(MAP_PLANNER)?.map(([path, value]) => {
      const name = getRouteNameByPath(path)

      if (!name || !value) return

      return (
        <li key={path}>
          <S.TutorialsButton disabled={path === Page?.path} onClick={() => setPage({ path, value })}>
            {name}
          </S.TutorialsButton>
        </li>
      )
    })
  }, [Page])

  return (
    <S.Container>
      <PageFirstRow title="Tutoriais">
        <Showcase />
      </PageFirstRow>

      <S.Stage empty={!Page?.path}>{Page?.path && <Page.value />}</S.Stage>

      <S.InfoStage>
        <S.Infos>
          <h3>Explore Nossos Tutoriais</h3>
          <p>
            Aprenda novas habilidades com guias passo a passo e conteúdos detalhados.{' '}
            {!Page?.path && 'Para começar escolha um dos tutoriais.'}
          </p>
        </S.Infos>
        <S.TutorialsList>{List}</S.TutorialsList>
      </S.InfoStage>
      <hr />
    </S.Container>
  )
}
