export const TIPO_ORCAMENTO_CODE_NAME = {
  0: 'Receita',
  1: 'Despesa',
  2: 'Investimento',
  3: 'Divida'
}

export const TIPO_ORCAMENTO_NAME_CODE = {
  Receita: 0,
  Despesa: 1,
  Investimento: 2,
  Divida: 3
}

export const ParceledType = {
  0: 'Parcelado',
  1: 'Repetido'
}
