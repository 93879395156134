import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useRequiredAuth } from '../../hooks/useAuth'
import { authenticate } from '../../domain/auth'
import { useCoreContext } from '../../hooks/contexts/coreProvider'

const userTypes = ['admin', 'planejador', 'pessoa'] as const
type UserType = (typeof userTypes)[number]
interface Token {
  email: string
  empresaId?: number
  familiaId?: number
  iat: number
  id: number
  manager: boolean | null
  pessoaId?: number
  planejadorId?: number
  theme?: string
  type: UserType
}

interface Authentication {
  jwt: string
  token: Token
}

export interface ILoginBody {
  email: string
  password: string
}

export const useLoginMutation = () => {
  // const { setIsAuthenticated } = useAuthStore()
  const { setUsuarioEmail, setTipoUsuario, setUsuarioId, setEmpresaId } = useCoreContext()
  const { setJwt } = useRequiredAuth()

  const {
    mutateAsync: loginAsync,
    isLoading: isLoadingLogin,
    isError: isErrorLogin,
    error: errorLogin,
    isSuccess: isSuccessLogin,
    data: credentials,
    reset: loginReset
  } = useMutation<Authentication, AxiosError, ILoginBody>(['login'], authenticate, {
    retry: 0
  })

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (credentials?.jwt) {
      setJwt(credentials?.jwt)
      setUsuarioEmail(credentials?.token?.email)
      setTipoUsuario(credentials?.token?.type)
      if (credentials?.token?.id) setUsuarioId(String(credentials?.token?.id))
      if (credentials?.token?.empresaId) setEmpresaId(String(credentials?.token?.empresaId))

      timeout = setTimeout(() => {
        window.location.href = '/v1/admin/home'
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [credentials, setJwt, setUsuarioEmail, setTipoUsuario, setUsuarioId, setEmpresaId])

  return {
    credentials,
    loginAsync,
    isLoadingLogin,
    isErrorLogin,
    errorLogin,
    isSuccessLogin,
    loginReset
  }
}
