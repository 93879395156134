import React from 'react'

export default () => (
  <svg width="100%" height="100%" viewBox="0 0 710 609" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M551.333.333 518 33.667H.667V.333h550.666ZM.667 133.667V167h384L418
  133.667H.667Zm150.666 266.666H.667v33.334H122.5a49.226 49.226 0 0 1
  9.4-13.9l19.433-19.434ZM.667 267v33.333h250.666L284.667 267h-284ZM699
  64.8a32.176 32.176 0 0 1 10.268 22.872A32.168 32.168 0 0 1 700 110.967l-438.5
  438.4L125 607.9a12.364 12.364 0 0 1-17.047-9.013 12.368 12.368 0 0
  1 .78-7.22L167.267 455.1l438.4-438.4a32.162 32.162 0 0 1 35.772-6.454 32.165
  32.165 0 0 1 10.394 7.454L699 64.8ZM234.667 524.6 192 481.933 160
  556.6l74.667-32Zm369.1-364.6-47.1-47.133L213.4 456.133l47.1 47.134L603.767
  160Zm62.166-81.5-26.8-27.933a13.996 13.996 0 0 0-15.387-3.29 13.997 13.997
  0 0 0-4.613 3.056l-38.9 39 47.1 47.101 38.4-38.367a13.999 13.999 0 0 0
  .2-19.567Z"
      fill="#323232"
    />
  </svg>
)
