import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { formatMonetaryValue } from '../../../domain/formatter'
import { FixedAsset, translateFixedAssetType } from '../../../domain/investments/fixedAsset'
import { TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { ValueCell } from '../Cells'
import { TrashCanIcon } from '../../Icons'
import styled from 'styled-components'
import { showYesNoAlert } from '../../../hooks/useShowAlert'

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  align-items: center;
`

export const useColumns = (onDelete: (id: string) => void) => {
  const columns = useMemo(
    (): Column<FixedAsset>[] => [
      {
        Header: 'Descrição',
        id: 'Nome',
        accessor: 'name'
      },
      {
        Header: 'Tipo de Patrimônio',
        id: 'Tipo de Patrimônio',
        accessor: 'type',
        Cell: ({ value: type }) => translateFixedAssetType(type)
      },
      {
        Header: 'Data da Compra',
        id: 'Data da Compra',
        accessor: 'buyDate',
        Cell: ({ value: buyDate }) => format(new Date(buyDate), 'dd/MM/yyyy')
      },
      {
        Header: 'Valor Atual',
        id: 'Valor Atual',
        accessor: 'value',
        Cell: ({ value }) => (
          <ValueCell tipo={value >= 0 ? TipoOrcamento.Receita : TipoOrcamento.Despesa}>
            {formatMonetaryValue(value)}
          </ValueCell>
        )
      },
      {
        Header: 'Opções',
        id: 'opções',
        accessor: '_id',
        width: 80,
        Cell: ({ value: id, row: { original: asset } }) => (
          <OptionsContainer>
            <TrashCanIcon
              size="small"
              tooltip={`Deletar '${asset.name}'`}
              onClick={() => {
                showYesNoAlert(`Deletar patrimônio ${asset.name}?`, 'Confirma a deleção do patrimônio?', () =>
                  onDelete(id)
                )
              }}
            />
          </OptionsContainer>
        )
      }
    ],
    [onDelete]
  )
  return columns
}
