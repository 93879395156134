import styled, { css } from 'styled-components'
import colors from '../../../domain/Colors'
import { Colors } from '../../../domain/Colors'
import BaseModal from '../Modal'

const SizesMap = {
  small: '320px',
  medium: '520px',
  large: '720px',
  full: '100%'
}

export const Toasty = styled.div<{
  open?: boolean
  startAnimation?: boolean
  toastyType?: 'success' | 'error' | 'warning' | 'info'
}>`
  background: #fff;
  border-radius: 12px;
  bottom: -15%;
  box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.2);
  display: none;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 10px;
  transition: bottom 200ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 150ms ease-in-out;
  width: 380px;
  z-index: 9999;

  ${({ open }) =>
    open &&
    css`
      display: flex;
    `}

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      opacity: 1;
      bottom: 5%;
    `}
`

export const ToastyType = styled.div<{
  toastyType?: 'success' | 'error' | 'warning' | 'info'
}>`
  align-items: center;
  background: #55c593;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  width: 60px;

  ${({ toastyType }) => {
    switch (toastyType) {
      case 'success':
        return css`
          background: ${colors.green};
        `
      case 'error':
        return css`
          background: ${colors.red};
        `
      case 'warning':
        return css`
          background: ${colors.red};
        `
      case 'info':
        return css`
          background: ${colors.blue};
        `
      default:
        return css``
    }
  }}

  span {
    color: #fff;
  }
`
export const ToastyContent = styled.div`
  padding: 10px 15px;

  h2 {
    font-size: 1.1rem;
  }

  h4 {
    font-weight: normal;
  }
`

export const Modal = styled(BaseModal)`
  &[data-backdrop] {
    align-items: center;
    justify-content: center;
    min-height: 400px;
    padding: 20px;
    max-height: auto;

    @media screen and (min-width: 1024px) {
      padding: 40px 20px;
    }
  }

  [data-modal] {
    display: flex;
    height: fit-content;
    left: 0;
    top: 0;
    max-height: fill-available;
    position: relative;
    transform: translate(0);
    min-width: 320px;
    width: 100%;

    @media screen and (min-width: 1024px) {
      width: ${({ size }) => (size ? SizesMap[size] : '55%')};
    }
  }
`

export const Button = styled.button<{ bgColor?: Colors }>`
  background: ${({ bgColor }) => bgColor || colors.darkGray};
  border-radius: 5px;
  border: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
  outline: none;
  padding: 6px 18px;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }
`

export const Title = styled.h2`
  margin-bottom: 1rem;
  text-transform: capitalize;
`

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow-x: auto; */
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 5;
  margin-top: 16px;
`
