import React from 'react'
import { MapProps } from './types'
import { IframeVimeo } from './components/VideoIframe/Iframe'
import { ConcatChildrenPagination, Pagination } from './components/Pagination'
import {
  HelpDestinacaoPatrimonio,
  HelpDividas,
  HelpExtrato,
  HelpGestaoOrcamento,
  HelpGestaoPlanos,
  HelpIndependenciaFinanceira,
  HelpIntegracao,
  HelpPatrimonioIntegracao,
  HelpPlannerWizard,
  HelpPlanosSonhos
} from './helpClientPlanner'
import { COMMOM_CONTENT } from './commomContent'

export const HelpPlannerHome = () => (
  <ConcatChildrenPagination>
    <IframeVimeo title="Home" videoId="1005516952?h=4209765b31" />
    <IframeVimeo title="Vista AI" videoId="1006570863?h=74493a4118" />
    <IframeVimeo title="Bloco de Notas" videoId="1008221505?h=c63681e481" />
    <COMMOM_CONTENT.Home.Cliente />
  </ConcatChildrenPagination>
)

export const HelpPlannerUsuarios = () => (
  <Pagination>
    <COMMOM_CONTENT.Configuracoes.Usuarios>
      <div>
        <h3>Mais sobre os Planos</h3>

        <p>
          <b>Plano Individual:</b> ideal para solteiros, clientes que não possuem investimentos ou casais que
          possuem 1 carteira de investimentos (Ex: o trabalho é feito com uma família, mas somente um dos
          cônjuges possui investimentos no seu nome).
        </p>

        <p>
          <b>Plano Família:</b> ideal para famílias que possuem 2 carteiras de investimentos (Ex: o trabalho é
          feito com uma família, sendo que cada um dos cônjuges possui investimentos no seu nome). <br />
          <br />* Os planos podem ser modificados a qualquer momento.
        </p>

        <h4>Qual o dia da cobrança?</h4>

        <p>
          Todo dia 10 de cada mês subsequente ao cadastramento do cliente, o planejador pagará a sua fatura
          mensal referente aos seus clientes, de acordo com o plano escolhido. O pagamento pode ser feito
          através do cartão de crédito ou débito. Na primeira cobrança o planejador receberá um link no seu
          e-mail para cadastrar o cartão de crédito ou débito, e aquele cartão ficará salvo para as próximas
          faturas.
        </p>

        <p>
          Ex: Planejador tem 3 clientes no Plano Individual de 49,90 (total de 149,70) e 4 clientes no Plano
          Família de 59,90 (239,60). Além disso, o planejador utiliza o gateway de pagamento de 59,90.
        </p>

        <p>Total da Fatura = 149,70 + 239,60 + 59,90 = 449,20.</p>

        <p>Esse valor será cobrado no cartão de crédito/débito cadastrado do planejador.</p>

        <p>
          <b>Importante:</b> O valor cobrado pelo cliente será pro-rata. Ou seja, caso o planejador cadastre o
          cliente durante o mês (ou cancele o cliente durante o mês), ele irá pagar o valor proporcional do
          cliente na próxima fatura.
        </p>
      </div>
    </COMMOM_CONTENT.Configuracoes.Usuarios>
  </Pagination>
)

export const HelpPlannerClientes = () => (
  <Pagination>
    <div>
      <IframeVimeo videoId="1004608609?h=a86b4acb57" title="Como cadastrar clientes" />
    </div>

    <div>
      <IframeVimeo videoId="1003803798?h=604c338c92" title="Como editar ou excluir um cliente" />
    </div>

    <div>
      <IframeVimeo videoId="1003803339?h=ac2aaf05dd" title="Cadastrar novos usuários dentro de uma família" />
    </div>

    <div>
      <IframeVimeo videoId="1005515552?h=327d76e120" title="Registrar meios de Pagamento do usuário" />
    </div>

    <div>
      <IframeVimeo videoId="1005515853?h=ffd3cd2c70" title="A diferença entre família e usuário" />
    </div>

    <div>
      <IframeVimeo videoId="1005516190?h=96dbb27147" title="Configuração inicial" />
    </div>
  </Pagination>
)

const HelpPlannerFatura = () => (
  <div>
    <IframeVimeo title="Fatura Vista" videoId="1006568296?h=7b2dbb6e9c" />
  </div>
)

export const MAP_PLANNER: MapProps = {
  '/clientes': HelpPlannerClientes,
  '/destinacao-patrimonio': HelpDestinacaoPatrimonio,
  '/dividas': HelpDividas,
  '/extrato': HelpExtrato,
  '/faturas': HelpPlannerFatura,
  '/gestao-planos-sonhos': HelpGestaoPlanos,
  '/home': HelpPlannerHome,
  '/independencia-financeira': HelpIndependenciaFinanceira,
  '/integracoes': HelpIntegracao,
  '/orcamento': HelpGestaoOrcamento,
  '/patrimonio-integracao': HelpPatrimonioIntegracao,
  '/planos-sonhos': HelpPlanosSonhos,
  '/usuarios': HelpPlannerUsuarios,
  '/wizard': HelpPlannerWizard
}
