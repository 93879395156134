import { investmentsClient as client } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'
import { DeleteAssetRequest } from '../fixedAsset'

export const deleteFixedAsset = async ({ id }: DeleteAssetRequest) => {
  const { status } = await client.delete(`/fixedAsset/${id}`)
  if (status !== 200) {
    const error = new Error(`Error deleting asset ${id}. Status: ${status}`)
    noticeError(error)
    throw error
  }
}
