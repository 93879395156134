import React from 'react'
import styled from 'styled-components'
import { DownloadIcon } from '../Icons'

const ButtonLinkDownload = styled.a`
  padding: 5px 16px;
  border: solid 1px ${({ theme }) => theme.colors.secondary};
  border-radius: 24px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  line-height: 1;
  .icon {
    width: 18px;
    height: 18px;
    margin: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary}16;
  }
`

export default () => {
  return (
    <ButtonLinkDownload href="/modeloExcelPadraoVista.xlsx" download>
      <DownloadIcon classNames="icon" />
      Excel Padrão Vista
    </ButtonLinkDownload>
  )
}
