import { noticeError } from '../../../services/Monitoring'
import { annotationsApi } from '../../../webClient'

export const deleteNote = async (id: string) => {
  const { status } = await annotationsApi.delete<void>(`annotation/${id}`)
  if (status !== 200) {
    const error = new Error(`Error deleting annotation: ${id}`)
    noticeError(error)
    throw error
  }
}
