import { noticeError } from '../../../services/Monitoring'
import { s3Client } from '../../../webClient'

export const listThemes = async (): Promise<string[]> => {
  const { data: maybeThemes, status } = await s3Client.get('index')
  if (status !== 200 || !maybeThemes) {
    const message = `Error ${status} listing themes`
    const error = new Error(message)
    noticeError(error)
    throw error
  }

  return maybeThemes.split('\n').map((theme) => theme.replace('.json', ''))
}
