import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { GoBackButton } from '../../../components/Buttons'
import { PageFirstRow } from '../../../components/Headers'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { loginPath } from '../../../routes'
import PlannerView from './PlannerView'
import ManagerView from './ManagerView'
import SupportView from './SupportView'
import { useDateLocalStorage } from '../../../hooks/useLocalStorage'
import { formatDateForInvoice } from '../../../domain/formatter'
import { TabsPanel } from './components/TabsPanel'

const ContentContainer = styled.div`
  padding: 0 39px 0 39px;
`

const InnerContent = ({ filterDate }) => {
  const { isSupport, isPlanner, isManager } = useRequiredAuth()

  if (isSupport) return <SupportView filterDate={filterDate} />
  else if (isManager) return <ManagerView filterDate={filterDate} />
  else if (isPlanner) return <PlannerView filterDate={filterDate} />
  else return <Redirect to={loginPath} />
}

export default () => {
  useRequiredAuth()
  const currentDate = new Date()
  const { value: invoiceDate, setValue: setInvoiceDate } = useDateLocalStorage('invoice-date', currentDate)

  useEffect(() => {
    setInvoiceDate(new Date())
  }, [])

  return (
    <ContentContainer>
      <PageFirstRow title="Faturas">
        <GoBackButton />
      </PageFirstRow>
      <TabsPanel invoiceDate={invoiceDate} setInvoiceDate={setInvoiceDate} />
      <InnerContent filterDate={formatDateForInvoice(invoiceDate)} />
    </ContentContainer>
  )
}
