import React from 'react'
import LoadingCircleBackground, { LoadingBackground } from '../Loading'
import * as S from './Modal.styles'
import { ModalProps } from './Modal.types'

const Modal = ({
  children,
  className,
  closeable = true,
  id,
  isLoading,
  loadingMessage,
  onClose,
  open = true,
  padding = '2vw',
  size = 'medium',
  top
}: ModalProps) => (
  <S.Container
    aria-label={`modal-${open ? 'open' : 'closed'}`}
    size={size}
    open={open}
    top={top}
    id={id}
    padding={padding}
    className={className}
    data-modal="modal"
    data-modal-container
  >
    {isLoading && (
      <LoadingBackground rounded>
        <LoadingCircleBackground text={loadingMessage} />
      </LoadingBackground>
    )}
    {closeable && <S.CloseButton onClick={() => onClose?.()}>x</S.CloseButton>}
    {children}
  </S.Container>
)

export default ({
  children,
  className,
  closeable = true,
  hideBackground = false,
  id,
  isLoading,
  loadingMessage,
  onClose,
  open = true,
  padding,
  size,
  top
}: ModalProps) => {
  return hideBackground ? (
    <S.BackgroundFacade hide={open} className={className} data-backdrop="backdrop">
      <Modal
        children={children}
        className={className}
        closeable={closeable}
        data-modal="modal"
        id={id}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        onClose={onClose}
        open={open}
        padding={padding}
        size={size}
        top={top}
      />
    </S.BackgroundFacade>
  ) : (
    <div className={className}>
      <Modal
        children={children}
        className={className}
        closeable={closeable}
        data-modal="modal"
        id={id}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        onClose={onClose}
        open={open}
        padding={padding}
        size={size}
        top={top}
      />
    </div>
  )
}
