import React, { useEffect, useState } from 'react'

const convertMaskPercent = (value: string) => {
  return (parseInt(value.replace(/[^0-9]/g, '')) * 0.01).toLocaleString('pt-BR', { style: 'percent' })
}

const onlyNumbers = (value: string) => {
  return value.replace(/[^0-9]/g, '')
}

interface InputPercentageProps {
  defaultValue?: number
  disabled?: boolean
  maxLength?: number
  name?: string
  value?: number | string
  onChange?: (value: number | string) => void
  onError?: (message: string | null) => void
  onBlur?: () => void
  validRange?: [number, number]
}

const formatPercentage = (value?: string | number) => {
  if (!value) return '0%'
  let _value = String(value).replace(/\D/g, '')
  _value = _value.slice(0, _value.length)
  _value = `${_value}%`

  return _value
}

export const InputPercentage = ({
  disabled = false,
  maxLength = 4,
  name = 'percentageInput',
  defaultValue,
  onBlur,
  onError,
  onChange,
  validRange = [0, 100]
}: InputPercentageProps) => {
  const [inputValue, setInputValue] = useState<string | number | undefined>(defaultValue)

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      const { value } = event.currentTarget
      const _value = formatPercentage(value)
      setInputValue(_value)
      event?.currentTarget.setSelectionRange(_value.length - 1, _value.length - 1)
    }
  }

  const handlerChangeMaskPercent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const number = parseInt(onlyNumbers(value))

    if (number < validRange[0] || number > validRange[1]) {
      onError?.(`O percentual deve estar entre ${validRange[0]} e ${validRange[1]}%.`)
    } else {
      onError?.(null)
    }

    if (Number.isNaN(number)) {
      setInputValue('')
    } else {
      const newValue = convertMaskPercent(value)
      setInputValue(newValue)
    }

    onChange?.(onlyNumbers(value))
    event?.currentTarget.setSelectionRange(value.length - 1, value.length - 1)
  }

  useEffect(() => {
    setInputValue(disabled ? 0 : defaultValue)
  }, [defaultValue, disabled])

  return (
    <input
      type="text"
      name={name}
      disabled={disabled}
      onKeyUp={handleKeyUp}
      onChange={handlerChangeMaskPercent}
      onBlur={onBlur}
      onClick={(event) =>
        event?.currentTarget.setSelectionRange(
          event.currentTarget.value.length - 1,
          event.currentTarget.value.length - 1
        )
      }
      value={formatPercentage(inputValue)}
      defaultValue={defaultValue}
      placeholder="0%"
      pattern="[0-9]*"
      style={{
        opacity: disabled ? 0.5 : 1
      }}
      maxLength={maxLength}
    />
  )
}
