import { IAllInvestment, IGetAllTransactionsResultItem } from './api/ManualInvestments/types'

export type InvestmentProps = {
  amount: number
  amountOriginal?: number
  balance: number
  code: string
  currencyCode: string
  date?: string
  dateQuota?: string
  dueDate: string
  familyId: number
  id: string
  institution: string | null
  name: string
  precoCota: number
  quantidadeCota: number
  quantityQuota?: number
  subtype: string
  taxes: number
  type: string
  unitPriceQuota?: number
  userId: number
  vistaType: string
  isManual: boolean
}

export type TAllInvestments = InvestmentProps & IAllInvestment[]
export type InvestmentsByUserId = {
  ['type']: string
  ['currentValue']: number
  ['originalValue']: number
  ['balanceValue']?: number
  ['percentageInvested']?: number
  investments?: TAllInvestments
}

export const getLoadingInvestmentsByUserId = (): InvestmentsByUserId => ({
  type: '--- --- --- --- ---',
  balanceValue: 0,
  currentValue: 0,
  originalValue: 0,
  percentageInvested: 0
})

export type InvestmentTransaction = {
  data: string
  dateQuota?: string
  descricao: string
  financialInstitutionCode: string | null
  precoCota?: number
  quantidadeCota?: number
  taxes?: number
  valor: number
}

export const getLoadingInvestmentTransaction = (): InvestmentTransaction => ({
  data: '2023-07-05T03:00:00',
  valor: 0,
  financialInstitutionCode: '',
  quantidadeCota: 0,
  descricao: ''
})

export const getLoadingManualInvestmentTransaction = (): IGetAllTransactionsResultItem => ({
  amount: 0,
  buyDate: '2023-07-05T03:00:00',
  description: '',
  instituition: '',
  quantity: 0
})

export type InvestmentItemIdProps = {
  id: string
  name: string
  amount: number
  amountOriginal: number
  isSuspended: false
}

export const getLoadingInvestmentsByItemId = (): InvestmentItemIdProps => ({
  id: '',
  name: '--- --- --- --- ---',
  amount: 0,
  amountOriginal: 0,
  isSuspended: false
})
