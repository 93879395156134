export type Company = {
  id: number
  name: string
  document: string
  planners: number
  customers: number
  totalInvoice: number
}

export const getLoadingCompanies = (): Company => ({
  id: 0,
  name: '--- --- ---',
  document: '--- --- ---',
  planners: 0,
  customers: 0,
  totalInvoice: 0
})
