import { FixedAsset, FixedAssetUpdateRequest } from '../fixedAsset'
import { investmentsClient as client } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'

export const updateFixedAsset = async (update: FixedAssetUpdateRequest) => {
  const { _id: id } = update
  const payload = { ...update, _id: undefined }
  const { data: asset, status } = await client.patch<FixedAsset>(`/fixedAsset/${id}`, payload)
  if (status !== 200) {
    const error = new Error(`Error patching asset ${JSON.stringify(update)}. Status: ${status}`)
    noticeError(error)
    throw error
  }
  return asset
}
