import { useTheme } from '../../../hooks'
import { useLoginMutation } from '../../../queries/auth/querries'
import { Card, CardBody, FormGroup, Form, Label, Input, InputGroup, Row, Col } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import Button from '../../../components/Buttons'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { useCoreContext } from '../../../hooks/contexts/coreProvider'
import { safeClearStorage } from '../../../components/AuthRoute/utils'
import { AlertModal } from '../../../components/Modal'
import { useAuthStore } from '../../../store/auth'
import { AuthLayout } from '../../../layouts/AuthV2'

export const Login = () => {
  const history = useHistory()
  const { setUsuarioEmail, setTipoUsuario, loginRedirect, setUsuarioId, setEmpresaId } = useCoreContext()
  const { setJwt: setRequiredAuthJwt } = useRequiredAuth()
  const { loginAsync, isSuccessLogin, isErrorLogin, credentials, loginReset } = useLoginMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setJwt: setValueJwt, jwt: valueJwt } = useAuthStore()
  const { theme, changeTheme } = useTheme()

  const [form, setForm] = useState<{ email?: string; senha?: string }>({
    email: undefined,
    senha: undefined
  })

  const authenticate = useCallback(() => {
    const { email, senha: password } = form

    if (!email || !password) return

    loginAsync({
      email,
      password
    })
  }, [form, loginAsync])

  const isValid = useCallback(() => {
    const { email, senha } = form
    const basic = email && email !== '' && senha && senha !== ''
    return basic && email?.length >= 6 && senha.length > 5
  }, [form])

  const onSubmitSuccess = useCallback(() => {
    const { jwt, token } = credentials || {}
    if (!jwt || !token) return

    setValueJwt(jwt)
    setRequiredAuthJwt(jwt)
    setUsuarioEmail(token.email)
    setTipoUsuario(token.type)

    if (token?.theme) changeTheme(token.theme)
    if (token?.id) setUsuarioId(String(token?.id))
    if (token?.empresaId) setEmpresaId(String(token?.empresaId))
  }, [
    changeTheme,
    credentials,
    setEmpresaId,
    setRequiredAuthJwt,
    setTipoUsuario,
    setUsuarioEmail,
    setUsuarioId,
    setValueJwt
  ])

  useEffect(() => {
    if (isSuccessLogin && credentials) {
      onSubmitSuccess()
    }
  }, [credentials, isSuccessLogin, onSubmitSuccess])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isSuccessLogin && loginRedirect && valueJwt) {
      timer = setTimeout(() => history.push('/admin/home'), 6000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [history, isSuccessLogin, loginRedirect, valueJwt])

  useEffect(() => {
    if (isErrorLogin) {
      setIsSubmitting(false)
      safeClearStorage()
    }
  }, [isErrorLogin, setValueJwt])

  useEffect(() => {
    safeClearStorage()
  }, [])

  return (
    <>
      <AuthLayout>
        <Card
          className="bg-secondary shadow border-0"
          style={{ width: '90%', borderRadius: 15, maxWidth: 500 }}
        >
          <CardBody className="px-lg-5 py-lg-5" style={{ backgroundColor: '#FFF', borderRadius: 15 }}>
            <div className="text-center text-muted mb-4">
              <h2 style={{ fontWeight: 700 }}>{theme.login.cardTitleText}</h2>
            </div>

            <Form
              role="form"
              onSubmit={(event) => {
                event.preventDefault()
                authenticate()
              }}
            >
              <FormGroup className="mb-3">
                <Label
                  for="email"
                  style={{
                    marginLeft: 15,
                    fontSize: '0.9rem',
                    fontWeight: 600
                  }}
                >
                  E-mail
                </Label>
                <InputGroup>
                  <Input
                    type="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        email: e.target.value.trim()
                      })
                    }
                    // onKeyPress={({ key }) => {
                    //   if (key === 'Enter') authenticate()
                    // }}
                    style={{
                      borderRadius: 25,
                      paddingLeft: 20,
                      border: 'none',
                      backgroundColor: '#f7faff'
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label
                  for="senha"
                  style={{
                    marginLeft: 15,
                    fontSize: '0.9rem',
                    fontWeight: 600
                  }}
                >
                  Senha
                </Label>
                <InputGroup>
                  <Input
                    type="password"
                    value={form.senha}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        senha: e.target.value.trim()
                      })
                    }
                    // onKeyPress={({ key }) => {
                    //   if (key === 'Enter') authenticate()
                    // }}
                    style={{
                      borderRadius: 25,
                      paddingLeft: 20,
                      border: 'none',
                      backgroundColor: '#f7faff'
                    }}
                  />
                </InputGroup>
                <Row>
                  <Col className="text-right mt-2">
                    <Link to="/auth/reset" style={{ fontSize: '0.9rem', fontWeight: 600 }}>
                      Esqueci minha senha
                    </Link>
                  </Col>
                </Row>
              </FormGroup>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Button
                  className="featured-button my-4"
                  color="primary"
                  disabled={isSubmitting || !isValid()}
                  // @ts-expect-error
                  style={{
                    background:
                      `linear-gradient(45deg, ${theme.colors.primary} 0%, ` +
                      `${theme.colors.secondary} 100%)`,
                    minWidth: 150,
                    textTransform: 'uppercase',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClick={() => {
                    setIsSubmitting(true)
                    authenticate()
                  }}
                  text={isSubmitting ? 'Entrando...' : 'Entrar'}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </AuthLayout>

      <AlertModal title="Falha no Login" open={isErrorLogin} onClose={loginReset}>
        Usuário ou senha inválidos.
      </AlertModal>
    </>
  )
}
