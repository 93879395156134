import React from 'react'
import DatePicker from './DatePicker'
import { DatePickerContainer, DateRangeContainer } from './DateRangePicker.styles'
import { OneIcon, ThreeIcon } from '../Icons'
import { addMonths, endOfDay, endOfMonth, startOfMonth } from 'date-fns'

interface Props {
  start: Date
  end: Date
  onStartChange: (newDate: Date) => void
  onEndChange: (newDate: Date) => void
  title?: string
}

export const DateRangePicker = ({ start, end, onStartChange, onEndChange, title }: Props) => {
  return (
    <DateRangeContainer>
      <h3>{title ?? 'Período'}</h3>
      <DatePickerContainer>
        <DatePicker
          currentDate={start}
          onChange={(newDate) => {
            if (newDate) onStartChange(newDate)
          }}
        />
        <DatePicker
          currentDate={end}
          onChange={(newDate) => {
            if (newDate) onEndChange(newDate)
          }}
        />
      </DatePickerContainer>
      <OneIcon
        tooltip="Clique para ver apenas os lançamentos do mês atual"
        onClick={() => {
          onStartChange(startOfMonth(new Date()))
          onEndChange(endOfMonth(endOfDay(new Date())))
        }}
      />
      <ThreeIcon
        tooltip="Clique para ver os lançamentos dos últimos três meses"
        onClick={() => {
          onStartChange(addMonths(startOfMonth(new Date()), -3))
          onEndChange(addMonths(endOfMonth(endOfDay(new Date())), -1))
        }}
      />
    </DateRangeContainer>
  )
}
