import { getWithToken } from '../../../webClient'
import { SingleResult } from '../../Results'
import { CancellationReason } from '../Family'

export const getCancelationReasons = async () => {
  const { data, status } = await getWithToken<SingleResult<CancellationReason[]>>(
    'MotivoCancelamento/CancellationReason'
  )
  if (status !== 200) {
    const msg = 'Error getting Cancellation Reasons'
    console.error(msg)
    throw new Error(msg)
  }
  return data
}
