import { annotationsApi } from '../../../webClient'
import { NoteResponse } from '../Notes'

interface Params {
  familyId: number
  pageIndex: number | undefined
  desc?: boolean
  pageSize?: number
  search?: string
  favorite?: boolean
}

export const getListOfNotes = async ({
  familyId,
  pageIndex,
  desc = true,
  pageSize = 10,
  search,
  favorite = false
}: Params) => {
  const { data, status } = await annotationsApi.get<NoteResponse>(
    `annotation/?familyId=${familyId}
      ${pageIndex && `&pageIndex=${pageIndex}`}
      &desc=${desc}&favorite=${favorite}
      &pageSize=${pageSize}
      &search=${search}`
  )
  if (status !== 200) {
    const msg = `
      Error getting annotations
      status: ${status}
      familyId: ${familyId}
      pageIndex: ${pageIndex}
      desc: ${desc}
      favorite: ${favorite}
    `
    console.error(msg)
    throw new Error(msg)
  }
  return data
}
