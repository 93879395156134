import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Row } from 'reactstrap'
import { CalendarIcon } from '../Icons'
import BetaTag from '../Utils/BetaTag'

interface Props {
  title: string
  children?: ReactNode
  className?: string
  beta?: boolean
}

const Container = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
`

const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
`

const RightSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const Title = styled.h3`
  padding-left: 0.65vw;
  padding-right: 0.65vw;
  margin-bottom: 0;
`

export default ({ title, children, className, beta }: Props) => {
  return (
    <Container className={className}>
      <LeftSide>
        <CalendarIcon />
        <Title>{title}</Title>
        {beta && <BetaTag />}
      </LeftSide>
      <RightSide>{children}</RightSide>
    </Container>
  )
}
