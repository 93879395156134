import styled, { css } from 'styled-components'

export const TextInput = styled('textarea')`
  ${() => css`
    background: none;
    border: none;
    cursor: text;
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
    overflow: hidden;
    resize: none;
  `}
`

export const NumberInput = styled('input')`
  ${() => css`
    background: none;
    border: none;
    cursor: text;
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
  `}
`
