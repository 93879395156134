import React, { useMemo } from 'react'
import { ExpandedContainer } from '../styles'
import { IAllInvestment } from '../../../../domain/investments/api/ManualInvestments/types'
import { IFixedEquity } from '../../../Modal/FixedEquityModal/types'
import Table from '../../Table'
import { useColumnsAcoes, useColumnsFundos, useColumnsRendaFixa } from '../Columns'
import { IEquityDelete, TInvestmentsColumnMap } from '../types'
import { TransactionsByManualInvestmentTable } from '../../TransactionsByManualInvestmentTable'

export const EquitysTable = ({
  type,
  investments,
  transactionsStartDate,
  transactionsEndDate,
  familyId,
  handleRefetch,
  fixedEquityModalMethods,
  widthBeforeHorizontalScroll,
  setRowDeleted,
  setDeleteModalOpen,
  userId
}) => {
  const handleRowClick = (row: IAllInvestment) => {
    const { vistaType } = row || {}

    fixedEquityModalMethods?.setSelectedFixedAsset({
      ...row,
      userId,
      type: 'investments',
      investmentType: vistaType
    } as unknown as IFixedEquity)

    fixedEquityModalMethods?.openModal()
  }

  const handleDelete = (props: IEquityDelete) => {
    if (!props) return
    setRowDeleted(props)
    setDeleteModalOpen(true)
    handleRefetch()
  }

  const columnsRendaFixa = useColumnsRendaFixa({
    handleDelete
  })
  const columnsAcoes = useColumnsAcoes({
    handleDelete
  })
  const columnsFundos = useColumnsFundos({
    handleDelete
  })

  const COLUMNS_MAP: TInvestmentsColumnMap = useMemo(
    () => ({
      'Conta Bancária': columnsRendaFixa,
      'Fundos De Investimentos': columnsFundos,
      'Fundos De Previdência': columnsFundos,
      'Investimentos No Exterior': columnsFundos,
      'Renda Fixa Pós': columnsRendaFixa,
      'Renda Fixa Pré': columnsRendaFixa,
      'Renda Fixa': columnsRendaFixa,
      'Tesouro Direto': columnsRendaFixa,
      Ações: columnsAcoes,
      COE: columnsRendaFixa,
      Criptomoedas: columnsAcoes,
      Debêntures: columnsAcoes,
      ETFs: columnsAcoes,
      Fiis: columnsAcoes,
      Fundos: columnsFundos,
      Moedas: columnsAcoes,
      Outros: columnsRendaFixa,
      Poupança: columnsRendaFixa,
      Previdência: columnsRendaFixa
    }),
    [columnsAcoes, columnsFundos, columnsRendaFixa]
  )

  if (!COLUMNS_MAP?.[type]) {
    console.error(Error(`[EquitysTable:76] Inexistent investment type ${type} on COLUMNS_MAP`))
    return <></>
  }

  return (
    <ExpandedContainer>
      <Table
        onRowClick={handleRowClick}
        title=" "
        data={investments || []}
        columns={COLUMNS_MAP?.[type] ?? COLUMNS_MAP['Renda Fixa']}
        filterEnabled={false}
        toggledOffByDefaultColumns={['quantityQuota', 'amountQuota', 'unitPriceQuota', 'dateQuota', 'taxes']}
        sortable={true}
        downloadFileName={`Investimentos-${type}-${new Date().toISOString().split('T')[0]}`}
        renderExpanded={(equityItem) => (
          <TransactionsByManualInvestmentTable
            familyId={familyId}
            investmentId={equityItem?.id}
            start={transactionsStartDate}
            end={transactionsEndDate}
            handleRefetch={handleRefetch}
            setRowDeleted={setRowDeleted}
            setDeleteModalOpen={setDeleteModalOpen}
            equityItem={equityItem}
            toggledOffByDefaultColumns={[
              'quantityQuota',
              'amountQuota',
              'unitPriceQuota',
              'dateQuota',
              'taxes'
            ]}
            isManualInvestment={equityItem?.isManual}
          />
        )}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
      />
    </ExpandedContainer>
  )
}
