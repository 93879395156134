import {
  MeioDePagamentoSelect,
  OrcamentoSelect,
  CategoriaSelect,
  TipoOrcamentoSelect,
  ActiveSelect
} from './Selects'
import styled from 'styled-components'

export const availableColumnNames = [
  'Meios de pagamento',
  'Orçamentos',
  'Categorias',
  'Tipo de orçamento',
  'Incluir inativos'
] as const

export const COLUMNS_MAP = {
  methodid: 'Meio de pagamento',
  budgetid: 'Orçamento',
  category: 'Categoria',
  type: 'Tipo de orçamento'
}

export type AvailableColumnNames = (typeof availableColumnNames)[number]

const StyledCategoriaSelect = styled(CategoriaSelect)`
  width: 100%;
`

const namesAndSelects: Record<AvailableColumnNames, (options: ColumnFilterSelect) => JSX.Element> = {
  'Meios de pagamento': MeioDePagamentoSelect,
  Orçamentos: OrcamentoSelect,
  Categorias: StyledCategoriaSelect,
  'Tipo de orçamento': TipoOrcamentoSelect,
  'Incluir inativos': ActiveSelect
} as const

export const getColumnSelect = (columnName: AvailableColumnNames) => namesAndSelects[columnName]

export interface ColumnFilterSelect {
  currentSelected?: string | number | boolean
  onChange: (value?: string | number | boolean) => void
}
