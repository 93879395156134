import { noticeError } from '../../../services/Monitoring'
import { getWithToken } from '../../../webClient'

export interface Props {
  id: number
  idCategoria: number
}

export const changeCategory = async ({ id, idCategoria }: Props) => {
  const url = `lancamento/setacategorizacao/${id}/categoria/${idCategoria}`
  const { status } = await getWithToken(url)
  if (status !== 200) {
    const message = `Error updating lançamento ${id} to categoria ${idCategoria}`
    console.error(message, { id, idCategoria })
    const error = new Error(message)
    noticeError(error, { id, idCategoria })
    throw error
  }
}
