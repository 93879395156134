import React, { useCallback, useEffect, useState } from 'react'
import { Row as ReactTableRow } from 'react-table'
import styled from 'styled-components'
import Colors from '../../domain/Colors'
import { ExpandRowIcon } from '../Icons'

const Row = styled.tr<{ index: number; clickable: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-content: start;
  min-height: 2.5rem;
  background-color: ${({ index }) => (index % 2 === 0 ? Colors.tableRowGray : Colors.white)};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  &:hover {
    background-color: ${Colors.gray};
    transition: background-color 0.4s ease-in-out;
  }
`

const ExpandedRow = styled.tr`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: ${Colors.white};
`

const ExpanderIconCell = styled.td`
  justify-content: center;
  display: flex;
  flex: 25;
  padding-left: 8px;
  margin-right: 2px;
`

const Cell = styled.td<{ width?: number }>`
  justify-content: center;
  text-align: center;
  display: flex;
  flex: ${({ width }) => (width ? width : 1)};
  width: 100%;
`

interface Props<T extends object> {
  row: ReactTableRow<T>
  index: number
  onClick?: (value: T) => void
  renderExpanded?: (columnValue: T) => JSX.Element
  onExpand?: (index: number) => void
  isRowExpanded?: boolean
  prepareRow?: (row: ReactTableRow<T>) => void
}

export default <T extends object>({
  row,
  index,
  isRowExpanded = false,
  onClick,
  onExpand,
  renderExpanded
}: Props<T>) => {
  const isExpandable = !!renderExpanded
  const expand = () => onExpand?.(index)
  const [PreRenderedRow, setRow] = useState<JSX.Element | null>(<></>)

  const preRenderExpandedRow = useCallback(() => {
    if (renderExpanded && row?.original) setRow(renderExpanded?.(row?.original))
  }, [renderExpanded, row])

  useEffect(() => {
    preRenderExpandedRow()
  }, [preRenderExpandedRow, row])

  return (
    <React.Fragment key={`frag_${index}_${row?.getRowProps()?.key}`}>
      <Row
        index={index}
        clickable={!!onClick}
        onClick={
          onClick
            ? () => {
                onClick(row?.original)
              }
            : undefined
        }
        {...row?.getRowProps()}
      >
        {isExpandable && (
          <ExpanderIconCell>
            <ExpandRowIcon isExpanded={isRowExpanded} onClick={expand} />
          </ExpanderIconCell>
        )}
        {row?.cells?.map((cell) => (
          <Cell width={Number(cell?.column?.width)} {...cell?.getCellProps()}>
            {cell?.render('Cell')}
          </Cell>
        ))}
      </Row>
      {isRowExpanded && (
        <ExpandedRow>
          <Cell>{PreRenderedRow}</Cell>
        </ExpandedRow>
      )}
    </React.Fragment>
  )
}
