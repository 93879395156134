import React, { useCallback, useEffect, useState } from 'react'
import { TimeoutModal } from './TimeoutModal'
import { addEventListeners, removeEventListeners } from './eventListenerUtil'
import { SessionTimeoutProps } from './types'

const defaultDisabledPaths = ['/v1/auth/login']

export const SessionTimeout = ({
  cacheTime = 30,
  disabledForPaths = defaultDisabledPaths,
  logOffPath = '/v1/auth/login'
}: SessionTimeoutProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const timer = (cacheTime - 1) * 60 * 1000
  const timerMinute = 1 * 60 * 1000

  const redirectToLogin = useCallback(() => {
    console.warn('Redirecting to login page...')
    window.location.pathname = `${logOffPath}/to${window.location.pathname.replace('/v1', '')}`
  }, [logOffPath])

  useEffect(() => {
    if (isDisabled) return
    const createTimeout1 = () =>
      setTimeout(() => {
        setModalOpen(true)
      }, timer)

    const createTimeout2 = () => {
      return setTimeout(redirectToLogin, timerMinute)
    }

    const listener = () => {
      if (!isModalOpen) {
        clearTimeout(timeout)
        timeout = createTimeout1()
      }
    }

    // Initialization
    let timeout = isModalOpen ? createTimeout2() : createTimeout1()
    addEventListeners(listener)

    // Cleanup
    return () => {
      removeEventListeners(listener)
      clearTimeout(timeout)
    }
  }, [isDisabled, isModalOpen, redirectToLogin, timer, timerMinute])

  useEffect(() => {
    const isDisabled = disabledForPaths.includes(window.location.pathname)
    setIsDisabled(isDisabled)
  }, [disabledForPaths])

  return (
    <>
      {!isDisabled && (
        <TimeoutModal
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
          onLogOff={redirectToLogin}
        />
      )}
    </>
  )
}
