import React from 'react'
import axios from 'axios'

class PlanejadorService extends React.Component {
  getDadosPlanejador(planejadorId, callback) {
    //var url = `${global.server_api}api/planejador/${planejadorId}`;
    var url = `${global.server_api_new}/planejador/${planejadorId}`

    axios.get(url).then((res) => {
      if (callback != null) {
        callback(res.data)
      }
    })
  }
}

export default PlanejadorService
