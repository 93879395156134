import { Row } from 'reactstrap'
import { useCoreContext } from '../../../hooks/contexts/coreProvider'
import { ModalV2 } from '../../Modal/ModalV2'
import { useGetFamiliesQuery } from '../../../queries/lancamento/queries'
import { useHistory } from 'react-router-dom'
import { FieldSelect } from '../../Selects/FieldSelect'
import { useLocalStorage } from '../../../hooks'
import { IFamiliesResponse } from '../../../domain/lancamento/api/fetchByFamily'

const orderByName = (array?: IFamiliesResponse[]) => {
  return array?.sort((a, b) => {
    const nameA = a?.nome?.toUpperCase()
    const nameB = b?.nome?.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
}

export const ModalClientSelect = ({
  isOpen,
  onClose,
  onChange
}: {
  isOpen: boolean
  onClose: () => void
  onChange?: (familyId: string) => void
}) => {
  const { tipoUsuario: userType, setEmpresaId } = useCoreContext()
  const { setValue: setFamiliaId } = useLocalStorage('familia-id')
  const { families } = useGetFamiliesQuery()
  const history = useHistory()
  const orderedFamilies = orderByName(families)
  const tipoUsuario = Number(userType)

  const familiesOptions: { value: string | number | null; label: string }[] = [
    {
      value: null,
      label: 'Voltar para o Vista Planejador'
    }
  ]

  orderedFamilies?.forEach((family) =>
    familiesOptions.push({
      value: family.id,
      label: family.nome
    })
  )

  const handleFamilyChange = ({ value: familyId }) => {
    const isAdmin = tipoUsuario === 0
    //Se é o admin tem que limpar a empresa da familia
    if (isAdmin) setEmpresaId(null)

    setFamiliaId(familyId || '')

    onClose?.()

    const timer = setTimeout(() => {
      history.replace('/admin/home')
      onChange?.(familyId)
      clearTimeout(timer)
    }, 500)
  }

  return (
    <ModalV2
      usePrimary={Number(tipoUsuario) > 1}
      open={isOpen}
      close={onClose}
      title={
        <div>
          <div className="pop-up-title">
            <i className="ni ni-check-bold"></i>
            <h2>Seleção de Cliente</h2>
          </div>
        </div>
      }
    >
      <Row>
        <FieldSelect
          id="familia-select"
          placeholder="Selecione..."
          options={familiesOptions}
          onChange={handleFamilyChange}
          size={{ lg: 12, xl: 12 }}
        />
      </Row>
    </ModalV2>
  )
}
