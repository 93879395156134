import React from 'react'
import Icon from './Icon'
import previous from '../../assets/img/theme/previous.png'
import { Size, withSize } from '../withSize'
import styled from 'styled-components'

interface Props {
  size?: Size
  disabled?: boolean
  onClick?: () => void
}

const StyledIcon = styled(Icon)<Props>`
  max-width: ${({ size }) => withSize(size)};
  max-height: ${({ size }) => withSize(size)};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  margin: 0;
  cursor: pointer;
`

export default ({ size = 'small', disabled, onClick }: Props) => (
  <StyledIcon
    alt="."
    src={previous}
    size={size}
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
  />
)
