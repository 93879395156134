import React, { useCallback, useState } from 'react'
import { useColumns } from './columns'
import { ContainerTable, ErrorContainer, ExpandedContainer } from '../Table.styles'
import { Text } from '../Footer/Footer.styles'
import Button from '../../Buttons'
import PlannerBillingTable from '../BillingsByPlannerIdTable'
import { getLoadingLonelyPlanners } from '../../../domain/planner'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { noticeError } from '../../../services/Monitoring'
import Table from '../Table'
import { useLonelyPlanners } from '../../../queries/planner/queries'
import { TableFooter } from '../Footer'
import { FooterRow } from '../Footer/Footer.styles'
import { formatMonetaryValue } from '../../../domain/formatter'

const defaultSort: Sort = { desc: true, prop: 'name' }

const loadingData = getLoadingLonelyPlanners()

interface Sort {
  prop: string
  desc: boolean
}

interface Props {
  widthBeforeHorizontalScroll?: number
  filterDate: Date
}

export const LonelyPlannersTable = ({ widthBeforeHorizontalScroll, filterDate }: Props) => {
  const token = useRequiredAuth()
  const [search, setSearch] = useState<string>()
  const [page, setPage] = useState(0)
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage('planners-lonely-planners', 10)
  const [sort, setSort] = useState<Sort>(defaultSort)
  const onSort = useCallback((columnId: string, order: 'desc' | 'asc' | 'none') => {
    if (order === 'none') setSort(defaultSort)
    else setSort({ desc: order === 'desc', prop: columnId })
  }, [])

  const {
    isLoadingLonelyPlanners,
    isLonelyPlannersError,
    lonelyPlanners,
    LonelyPlannersError,
    refetchGetLonelyPlanners
  } = useLonelyPlanners({
    desc: sort?.desc,
    orderBy: sort?.prop,
    pageIndex: page,
    pageSize,
    search,
    filterDate
  })

  const totalPages = lonelyPlanners ? Math.ceil(lonelyPlanners.totalPlanners / pageSize) : 1

  const columns = useColumns()

  if (isLonelyPlannersError) {
    noticeError(LonelyPlannersError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchGetLonelyPlanners()} />
      </ErrorContainer>
    )
  }

  return (
    <ContainerTable>
      <Table
        title="Planejadores"
        data={lonelyPlanners?.planners || []}
        columns={columns}
        toggledOffByDefaultColumns={['companyName', 'companyDocument']}
        isLoading={isLoadingLonelyPlanners}
        loadingData={loadingData}
        filterEnabled={true}
        onFilterChange={setSearch}
        pageable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        sortable={true}
        onSort={onSort}
        downloadFileName={`Planejadores-Solitários-${new Date().toISOString().split('T')[0]}`}
        renderExpanded={({ id }) => (
          <ExpandedContainer>
            <PlannerBillingTable plannerId={id} widthBeforeHorizontalScroll={499} filterDate={filterDate} />
          </ExpandedContainer>
        )}
        renderFooter={() => (
          <TableFooter>
            <FooterRow>
              <Text>Total de famílias: {lonelyPlanners?.totalFamilies}</Text>
              <Text>Total da fatura: R$ {formatMonetaryValue(lonelyPlanners?.totalInvoice || 0)}</Text>
            </FooterRow>
          </TableFooter>
        )}
        widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1230}
      />
    </ContainerTable>
  )
}
