import styled, { css } from 'styled-components'
import Colors from '../../../../../domain/Colors'

export const Container = styled.div`
  align-items: center;
  background-color: ${Colors.grayBlue};
  border-radius: 1vw 1vw 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* height: 3.2em; */
  justify-content: space-between;
  padding: 10px 1.6vw;
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const IconSpacer = styled.div`
  padding-left: 0.35vw;
  padding-right: 0.35vw;
`

export const ShowRelative = styled.div`
  position: relative;
`

export const ActiveFlters = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 18px;
  background: ${Colors.blue};
  border-radius: 50%;
  color: ${Colors.white};
  font-size: 0.7rem;
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(7px, -7px);
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.span`
  color: ${Colors.black};
  font-weight: 600;
  font-family: Open Sans, sans-serif;
`

export const ActiveFltersBar = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`

export const ActiveFilter = styled.span<{ control?: boolean }>`
  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.primary};
    `}
  display: block;
  color: ${Colors.white};
  border-radius: 5px;
  padding: 5px 8px;
  position: relative;

  ${({ control }) =>
    control &&
    css`
      align-self: center;
      background: white;
      color: black;
      font-size: 11px;
    `}
`

export const ActiveFilterName = styled.span`
  display: block;
  font-size: 10px;
`

export const ActiveFilterValue = styled.span`
  font-size: 12px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
`

export const ButtonClear = styled.button`
  background-color: ${Colors.white};
  border-radius: 50%;
  border: none;
  color: ${Colors.black};
  box-shadow: 0 1px 1px 1px #f2f1f1;
  font-size: 10px;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
`
