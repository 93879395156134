import { noticeError } from '../../../services/Monitoring'
import { getWithToken } from '../../../webClient'
import { LonelyPlanners } from '../planner'

export type Params = {
  desc: boolean
  orderBy?: string
  pageIndex: number
  pageSize: number
  search?: string
  filterDate: Date
}

export type Response = {
  planners: LonelyPlanners[]
  totalPlanners: number
  totalFamilies: number
  totalInvoice: number
}

export const getLonelyPlanners = async ({
  desc = true,
  orderBy,
  pageIndex = 0,
  pageSize = 10,
  search,
  filterDate
}: Params) => {
  // const url = '/dashboard/LonelyPlanners'
  const urlV2 = '/dashboard/v2/LonelyPlanners'
  const params = { desc, orderBy, pageIndex, pageSize, nameOrEmail: search, filterDate }

  const { data: lonelyPlanners, status } = await getWithToken<Response>(urlV2, {
    params
  })

  if (status !== 200) {
    const msg = `Error getting getLonelyPlanners, status ${status}`
    const error = new Error(msg)
    noticeError(error)
    throw error
  }

  return lonelyPlanners
}
