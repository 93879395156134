import React, { useCallback, useState } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import * as S from './styles'
import { AlertModal } from '../alertModal'
import { useDeleteLancamentos } from '../../../queries/lancamento/mutations'
import { Success } from './success'
import { Error } from './error'
import { ModalDeleteTransactionProps } from './types'
import { getTransactionTypeFromArray } from '../../../domain/lancamento/lancamento'
// import { Link } from 'react-router-dom'
import { useDeleteManyTransactionEquitys } from '../../../queries/investments/mutations'

export const ModalDeleteTransaction = ({
  open,
  onSuccess,
  onClose,
  data = []
}: ModalDeleteTransactionProps) => {
  const [radioValue, setRadioValue] = useState<number | null>(null)
  const { hasParceled, hasRepeated } = getTransactionTypeFromArray({ transactions: data })
  const parceledSize = data?.length
  const manualTransactions = data?.filter(({ isManual }) => isManual)
  const defaultTransactions = data?.filter(({ isManual }) => !isManual)
  const manualTransactionsIds = manualTransactions?.map((item) => ({
    investmentId: item?.pluggyInvestmentId,
    transactionId: item?.pluggyTransactionId
  }))
  const defaultTransactionIds = defaultTransactions?.map((item) => item.id)
  const name = hasParceled ? 'parcelado' : hasRepeated ? 'repetido' : ''
  const parceledOrRepeated = hasParceled || hasRepeated
  // const hasOnlyManual = Boolean(manualTransactions?.length > 0 && !defaultTransactionIds?.length)

  const {
    deleteLancamentosAsync,
    resetLancamentosDeletion,
    deletedLancamentos,
    lancamentosDeletionError,
    isDeletingLancamentos
  } = useDeleteLancamentos()

  const { deleteManyTransactionEquitysAsync, isSuccessDeleteEquityTransaction } =
    useDeleteManyTransactionEquitys()

  const submit = () => {
    if (manualTransactionsIds?.length > 0) {
      deleteManyTransactionEquitysAsync({
        data: manualTransactionsIds
      })
    }

    if (defaultTransactionIds?.length > 0) {
      deleteLancamentosAsync({
        ids: defaultTransactionIds,
        excluirFuturasParcelas: radioValue === 0,
        excluirParcial: radioValue === 1
      })
    }
  }

  const primaryButton = {
    text: isDeletingLancamentos ? 'Excluindo...' : `Excluir lançamento${parceledSize > 1 ? 's' : ''}`,
    onClick: submit,
    disabled: isDeletingLancamentos
  }

  const secondaryButton = {
    text: 'Cancelar',
    onClick: onClose,
    disabled: isDeletingLancamentos
  }

  const ParceledRepeatedTitle = () => {
    const word = hasParceled ? 'parcelado' : 'repetido'
    if (parceledSize === 1) {
      return `Este lançamento é ${name}. Gostaria de deletar somente este lançamento ou todas as ${word}?`
    }

    return `Existem lancamentos ${name}s entre as exclusões. Gostaria de deletar somente este lançamento ou todas as ${word}?`
  }

  const handleChange = (event) => {
    setRadioValue(Number(event.currentTarget.value))
  }

  const showSuccess = useCallback(() => {
    let success = false
    if (manualTransactionsIds?.length > 0) {
      success = isSuccessDeleteEquityTransaction
    }

    if (defaultTransactionIds?.length > 0) {
      success = deletedLancamentos
    }
    return success
  }, [defaultTransactionIds, deletedLancamentos, isSuccessDeleteEquityTransaction, manualTransactionsIds])

  return (
    <>
      <S.ToastyWrapoer>
        {showSuccess() && (
          <Success
            onClose={() => {
              setRadioValue(null)
              resetLancamentosDeletion()
              onSuccess?.()
            }}
            size={defaultTransactionIds?.length}
          />
        )}
        {lancamentosDeletionError && <Error onClose={onSuccess} />}
      </S.ToastyWrapoer>

      {!deletedLancamentos && !lancamentosDeletionError && (
        <AlertModal
          onClose={onClose}
          open={open}
          primaryButton={primaryButton}
          // hidePrimaryButton={hasOnlyManual}
          secondaryButton={secondaryButton}
          title="Exclusão de lançamentos"
        >
          {/* {manualTransactions?.length > 0 && (
            <S.Details>
              <p>
                Existem lançamentos manuais entre os selecionados. Só é possível excluí-los através da tela
                <Link to="/admin/patrimonio-integracao">Patrimônio - Integração</Link>.
              </p>
            </S.Details>
          )} */}
          {defaultTransactionIds?.length > 0 && !hasParceled && !hasRepeated && (
            <S.Details>
              <p>
                Atenção, a exclusão é um procedimento <strong>irreversível</strong>. Tem certeza que deseja
                excluir {parceledSize === 1 ? 'este lancamento' : 'estes lançamentos'}?
              </p>
            </S.Details>
          )}

          {defaultTransactionIds?.length > 0 && parceledOrRepeated && (
            <S.Form>
              <FormControl component="fieldset">
                <S.Subtitle>{ParceledRepeatedTitle()}</S.Subtitle>

                <RadioGroup
                  aria-label="transaction"
                  name="transaction"
                  value={radioValue}
                  onChange={handleChange}
                  aria-disabled={isDeletingLancamentos}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={
                      <>
                        <S.FeaturedStrong>Excluir todos</S.FeaturedStrong>
                        {/* <S.LabelDescription>
                          Exclui todos os lançamentos envolvidos{' '}
                          {hasParceled ? 'no parcelamento' : 'nas repetições'}.
                        </S.LabelDescription> */}
                      </>
                    }
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={
                      <>
                        <S.FeaturedStrong>Excluir este e os futuros</S.FeaturedStrong>
                        {/* <S.LabelDescription>
                          Exclui o lançamento marcado para exclusão e os lançamentos futuros{' '}
                          {hasParceled ? 'do mesmo parcelamento' : 'da mesma repetição'}.
                        </S.LabelDescription> */}
                      </>
                    }
                  />

                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label={
                      <>
                        <S.FeaturedStrong>Excluir apenas os selecionados</S.FeaturedStrong>
                        {/* <S.LabelDescription>Exclui somente os lançamentos selecionados.</S.LabelDescription> */}
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </S.Form>
          )}
        </AlertModal>
      )}
    </>
  )
}
