import React, { useEffect, useMemo } from 'react'
// import { useCurrentFamilyFixedAssets } from '../../../queries/investments/fixedAssetQueries'
import { useColumns } from './columns'
import Footer from './Footer'
import Table from '../Table'
import { getLoadingFixedAsset } from '../../../domain/investments/fixedAsset'
import { useDeleteFixedAsset } from '../../../queries/investments/fixedAssetsMutations'
import { noticeError } from '../../../services/Monitoring'
import { showAlert } from '../../../hooks'
import { IFixedAsset } from '../../../domain/investments/api/ManualInvestments/types'

interface Props {
  fixedAssets?: IFixedAsset[]
  isFixedAssetsLoading?: boolean
  onFixedAssetClick?: (asset: IFixedAsset) => void
  onDeleteAsset?: () => void
}

export const EquityAssetsTable = ({
  fixedAssets,
  isFixedAssetsLoading,
  onFixedAssetClick,
  onDeleteAsset
}: Props) => {
  // const { fixedAssets, isFixedAssetsLoading } = useCurrentFamilyFixedAssets()
  const {
    deleteFixedAssetAsync,
    isDeletingFixedAsset,
    fixedAssetDeletionError,
    isErrorDeletingFixedAsset,
    resetFixedAssetDeletion,
    deletedFixedAsset
  } = useDeleteFixedAsset()

  const onDelete = (id: string) => deleteFixedAssetAsync({ id })
  const columns = useColumns(onDelete)
  const isLoading = isFixedAssetsLoading || isDeletingFixedAsset
  const loadingData = useMemo(getLoadingFixedAsset, [])

  if (isErrorDeletingFixedAsset) {
    noticeError(fixedAssetDeletionError)
    showAlert(
      'Erro ao deletar patrimônio imobilizado',
      'Erro interno do servidor, tente novamente mais tarde, por favor.',
      resetFixedAssetDeletion
    )
  }

  useEffect(() => {
    if (deletedFixedAsset) {
      onDeleteAsset?.()
    }
  }, [deletedFixedAsset, onDeleteAsset])

  return (
    <Table
      title="Imobilizados"
      columns={columns}
      data={fixedAssets || []}
      downloadFileName="Imobilizados"
      renderFooter={() => <Footer assets={fixedAssets || []} />}
      loadingData={fixedAssets ? undefined : loadingData}
      isLoading={isLoading}
      widthBeforeHorizontalScroll={930}
      onRowClick={onFixedAssetClick}
    />
  )
}
