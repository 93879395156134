import styled, { css } from 'styled-components'
import Colors from '../../domain/Colors'

export const ChipContainer = styled('div')`
  ${() => css`
    background: ${({ theme: { colors } }) =>
      `linear-gradient(180deg, ${colors.secondary} 0%, ${colors.primary} 100%)`};
    border-radius: 30px;
    padding: 5px 25px;
    text-align: center;

    > div + div {
      margin-top: 5px;
    }
  `}
`

export const ChipSubTitle = styled('div')`
  ${() => css`
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: ${Colors.white};
  `}
`

export const ChipTitle = styled('div')`
  ${() => css`
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: ${Colors.white};
  `}
`
