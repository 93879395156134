import React from 'react'
import { Redirect } from 'react-router-dom'
import { PlannerBillingTable } from '../../../components/Tables'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { loginPath } from '../../../routes'

export default ({ filterDate }) => {
  const token = useRequiredAuth()
  if (!token.isPlanner) return <Redirect to={loginPath} />

  return <PlannerBillingTable plannerId={token.plannerId} filterDate={filterDate} />
}
