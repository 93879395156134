import React from 'react'
import styled from 'styled-components'
import Colors from '../../domain/Colors'
import LoadingCircleBackground from './LoadingCircleBackground'
import { useLoadingScreen } from '../../hooks/useLoadingScreen'

const Background = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Colors.black}B3;
  z-index: 9998;
`

export default () => {
  const { isLoading, text } = useLoadingScreen()
  return (
    <Background isLoading={isLoading}>
      <LoadingCircleBackground text={text} />
    </Background>
  )
}
