import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { getTransactionType, TTransactionTypes } from '../../../domain/lancamento/lancamento'
import styled from 'styled-components'
import { Col } from 'reactstrap'

const TextFieldStyled = styled(TextField)`
  .MuiInputLabel-formControl {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525f7f;
    position: relative;
    margin: 0;
  }

  .MuiInputBase-root.MuiInput-root {
    margin-top: 0;

    .MuiInputBase-input {
      min-height: 28px;
    }

    &:after,
    &:before {
      display: none;
    }
  }
`

export const CurrencyParceledInput = ({
  size = { lg: 6, xl: 6 },
  id,
  style,
  label = 'Valor',
  onChange,
  partialData,
  disabled
}: {
  size?: { lg: number; xl: number }
  id?: string
  style?: React.CSSProperties
  label?: string
  onChange: (value: unknown) => void
  disabled?: boolean
  partialData: {
    id?: number
    valor?: number
    parcelas?: number
    valorParcela?: number
    tipoDeParcela?: TTransactionTypes | null
  }
}) => {
  const { isParceled, isRepeated } = getTransactionType({ transactionType: partialData?.tipoDeParcela })
  const isEditing = !!partialData?.id
  const getLabel = () => {
    let text = label

    if (isParceled) {
      if (!isEditing) text = 'Valor total'
      if (isEditing) text = 'Valor da parcela'
    }

    return text
  }

  const parceledOrRepeted = isParceled || isRepeated
  const amountValue = isEditing && (isParceled || isRepeated) ? partialData?.valorParcela : partialData?.valor

  return (
    <Col lg={size.lg} xl={size.xl}>
      <CurrencyInputV2
        id={id}
        style={style}
        label={getLabel()}
        value={amountValue}
        disabled={disabled}
        onChangeEvent={(formattedValue, value) => {
          if (isEditing) {
            onChange({
              valor: value,
              editRepeated: isRepeated,
              editInstallments: isParceled,
              valorParcela: parceledOrRepeted ? value : null,
              parcelado: parceledOrRepeted,
              parcelas: parceledOrRepeted && partialData?.parcelas ? partialData?.parcelas : null,
              valorFormatado: formattedValue
            })
            return
          }

          onChange({
            valor: value,
            valorFormatado: formattedValue
          })
        }}
      />
    </Col>
  )
}

export const CurrencyInputV2 = ({
  id,
  style,
  label,
  value,
  onChangeEvent,
  disabled
}: {
  id?: string
  style?: React.CSSProperties
  label?: string
  value?: number
  onChangeEvent: (formattedValue: string, value: number) => void
  disabled?: boolean
}) => {
  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, '') // Remove caracteres não-numéricos
    const numericValue = parseFloat(inputValue) / 100
    const formattedValue = currencyFormatter.format(numericValue || 0)
    setValue(formattedValue)
    onChangeEvent(formattedValue, numericValue)
  }

  const [_value, setValue] = useState(currencyFormatter.format(value || 0))

  return (
    <TextFieldStyled
      id={id}
      style={style}
      label={label}
      value={_value}
      onChange={handleChange}
      fullWidth
      disabled={disabled}
      variant="standard"
      InputLabelProps={{
        shrink: true
      }}
      // InputProps={{
      //   startAdornment: <span>R$</span>
      // }}
    />
  )
}
