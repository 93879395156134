import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { SearchIcon } from '../Icons'
import styled from 'styled-components'
import Colors from '../../domain/Colors'

const SearchInput = styled.input`
  background-color: ${Colors.grayBlue};
  border: 0;
  border-bottom: 1px solid ${Colors.darkBlue};
  width: 25vw;
  text-align: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
`

interface Props {
  setFilter: (value?: string) => void
  filter: string
  defaultFilter?: string
}

export default ({ setFilter, filter, defaultFilter }: Props) => {
  const [isFiltering, setIsFiltering] = useState(!!defaultFilter)
  const [value, setValue] = useState(filter || defaultFilter)
  const debounceFilter = useAsyncDebounce(setFilter, 500)

  return (
    <div>
      {isFiltering && (
        <SearchInput
          type="text"
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            debounceFilter(e.target.value)
          }}
        />
      )}
      <SearchIcon onClick={() => setIsFiltering((value) => !value)} tooltip="Procurar" />
    </div>
  )
}

export type { Props as FilterProps }
