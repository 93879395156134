import React, { useCallback, useState } from 'react'
import { RadioGroup, Radio } from 'react-radio-group'
import { Input } from 'reactstrap'
import { showAlert } from '../../hooks'
import { InfoMessage } from '../Navbars/AdminNavbar/components/InfoMessage/infoMessage'
import {
  getTransactionType,
  SimplifiedLancamento,
  TTransactionTypes
} from '../../domain/lancamento/lancamento'

interface InputQuantityProps {
  tipoDeParcela?: TTransactionTypes | null
  parcelas?: number
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onRadioChange?: (value: number) => void
  isEditing?: boolean
  initialData: SimplifiedLancamento & {
    repeatedParcels?: number
  }
}

export const InputQuantity = ({
  tipoDeParcela = 99 as TTransactionTypes,
  parcelas,
  onInputChange,
  onRadioChange,
  isEditing,
  initialData
}: InputQuantityProps) => {
  const [bloquedParcels, setBlockParcels] = useState(false)
  const { isParceled, isRepeated, isSingle } = getTransactionType({
    transactionType: tipoDeParcela
  })
  const isDisabled = isEditing && isRepeated && typeof parcelas === 'number' && parcelas > 1

  const quantityCodeNameLabel = {
    0: 'Quantidade de parcelas',
    1: 'Quantidade de repetições',
    99: 'Quantidade'
  }

  const handleClickAlertNotChange = () => {
    showAlert(
      'Atenção',
      `Não é possível editar a quantidade de vezes repetidos. Para isso,
        delete os lançamentos e faça novamente.`
    )
    setBlockParcels(true)
  }

  const QuantityMessage = useCallback(() => {
    if (
      (isRepeated || isParceled) &&
      (typeof parcelas !== 'number' || (typeof parcelas === 'number' && parcelas <= 1))
    ) {
      return (
        <InfoMessage
          message={`${quantityCodeNameLabel[tipoDeParcela as TTransactionTypes]} deve ser maior que 1`}
        />
      )
    }
    return <></>
  }, [isParceled, isRepeated, parcelas, quantityCodeNameLabel, tipoDeParcela])

  const installments = useCallback(() => {
    if (isEditing && isRepeated && initialData?.repeatedParcels) return initialData.repeatedParcels
    if ([0, 1].includes(tipoDeParcela as TTransactionTypes) && parcelas) return parcelas

    return ''
  }, [initialData, isEditing, isRepeated, parcelas, tipoDeParcela])

  return (
    <>
      <label>{quantityCodeNameLabel[tipoDeParcela ?? 99]}</label>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '10px'
        }}
      >
        <label onClick={() => isDisabled && handleClickAlertNotChange()}>
          <Input
            name="lancamentoParcelas"
            onBlur={onInputChange}
            disabled={Boolean(
              (isEditing && isRepeated && bloquedParcels) ||
                ![0, 1].includes(tipoDeParcela as TTransactionTypes)
            )}
            id="lancamentoParcelas"
            onChange={onInputChange}
            style={{ height: 38, paddingLeft: 25 }}
            type="number"
            value={installments()}
          />
        </label>
        <QuantityMessage />
        {/* {(isRepeated || isParceled) &&
          (typeof parcelas !== 'number' || (typeof parcelas === 'number' && parcelas <= 1)) && (
            <InfoMessage
              message={`${quantityCodeNameLabel[tipoDeParcela as TTransactionTypes]} deve ser maior que 1`}
            />
          )} */}
      </div>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <RadioGroup name="tipoDeParcela" selectedValue={tipoDeParcela ?? 99} onChange={onRadioChange}>
          <label
            className="lancamento despesa"
            style={{
              cursor: 'pointer',
              float: 'left',
              marginRight: '10px',
              marginBottom: '10px',
              width: 'auto'
            }}
          >
            <Radio value={99} checked={isSingle} /> Único
          </label>

          <label
            className="lancamento despesa"
            style={{
              cursor: 'pointer',
              float: 'left',
              marginRight: '10px',
              marginBottom: '10px',
              width: 'auto'
            }}
          >
            <Radio value={0} checked={isParceled} /> Parcelado
          </label>

          <label
            className="lancamento despesa"
            style={{
              cursor: 'pointer',
              float: 'left',
              marginRight: '10px',
              marginBottom: '10px',
              width: 'auto'
            }}
          >
            <Radio value={1} checked={isRepeated} /> Repetido
          </label>
        </RadioGroup>
      </div>
    </>
  )
}
