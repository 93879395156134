import { useMutation, useQueryClient } from 'react-query'
import { NoteUpsertRequest } from '../../domain/Notes/Notes'
import { deleteNote as deleteNoteAPI } from '../../domain/Notes/api/deleteNote'
import { updateNote as updateNoteAPI } from '../../domain/Notes/api/updateNote'
import { createNote as createNoteAPI } from '../../domain/Notes/api/createNote'
import { cacheKey } from './queries'

export const useCreateNote = () => {
  const client = useQueryClient()
  const {
    mutate: createNote,
    mutateAsync: createNoteAsync,
    isLoading: isCreatingNote,
    isError: isCreatingNoteError,
    error: createNoteError,
    isSuccess: noteCreated
  } = useMutation<unknown, Error, NoteUpsertRequest, unknown>(createNoteAPI, {
    retry: false,
    onSuccess: () => client.invalidateQueries(cacheKey)
  })
  return {
    createNote,
    createNoteAsync,
    isCreatingNote,
    isCreatingNoteError,
    createNoteError,
    noteCreated
  }
}

export const useDeleteNote = () => {
  const client = useQueryClient()
  const {
    mutate: deleteNote,
    mutateAsync: deleteNoteAsync,
    isLoading: isDeletingNote,
    isError: isDeletingNoteError,
    error: deleteNoteError,
    isSuccess: noteDeleted
  } = useMutation<unknown, Error, string, unknown>(deleteNoteAPI, {
    onSuccess: () => client.invalidateQueries(cacheKey)
  })
  return {
    deleteNote,
    deleteNoteAsync,
    isDeletingNote,
    isDeletingNoteError,
    deleteNoteError,
    noteDeleted
  }
}

export const useUpdateNote = () => {
  const client = useQueryClient()
  const {
    mutate: updateNote,
    mutateAsync: updateNoteAsync,
    isLoading: isUpdatingNote,
    isError: isUpdatingNoteError,
    error: UpdateNoteError,
    isSuccess: noteUpdated
  } = useMutation<unknown, Error, Omit<NoteUpsertRequest, 'familyId'>, unknown>(updateNoteAPI, {
    onSuccess: () => client.invalidateQueries(cacheKey)
  })
  return {
    updateNote,
    updateNoteAsync,
    isUpdatingNote,
    isUpdatingNoteError,
    UpdateNoteError,
    noteUpdated
  }
}
