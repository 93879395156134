import React from 'react'
import Icon from './Icon'
import Filters from '../../assets/img/theme/filter.svg'
import styled from 'styled-components'

interface Props {
  onClick?: () => void
  tooltip?: string
}

const StyledIcon = styled(Icon)`
  max-width: 24px;
  max-height: 20px;
  cursor: pointer;
`

export default ({ onClick, tooltip }: Props) => (
  <StyledIcon alt="Filtros" src={Filters} onClick={onClick} tooltip={tooltip} />
)
