import { patchWithTokenApiV2 } from '../../../webClient'
import { Result } from '../../extrato/api/sendExtrato'

export interface SuspendInvestmentByIdParams {
  id: string
  isSuspended: boolean
}

export type SuspendInvestmentByIdResult = Result & {
  exception: { message: string }
}

export const updateSuspendInvestmentById = async ({ id, isSuspended }: SuspendInvestmentByIdParams) => {
  const { data, status } = await patchWithTokenApiV2<SuspendInvestmentByIdResult, undefined>(
    `/investments/investment/${id}/suspended/${isSuspended}`
  )
  if (status !== 200) {
    const msg = `Error suspend investment by id ${id}`
    console.error(msg)
    throw new Error(msg)
  }
  return data
}
