import styled from 'styled-components'
import { NewModal } from '../Modal'

export const Modal = styled(NewModal)`
  [data-modal] {
    min-height: fit-content;
    width: 300px;

    @media screen and (min-width: 1024px) {
      width: 40%;
    }
  }
`

export const Content = styled.div`
  margin-bottom: 10px;
`

export const Footer = styled.div`
  display: flex;
  font-size: 12px;
  gap: 10px;
`
