export const fixedAssetType = ['investments', 'building', 'vehicle', 'company', 'other'] as const
export type FixedAssetType = (typeof fixedAssetType)[number]

export const fixedEquityType = ['Investimentos', 'Imóvel', 'Veículo', 'Empresa', 'Outro'] as const
export type TFixedEquity = (typeof fixedEquityType)[number]

export const EQUITY_TYPES = {
  INVESTIMENTOS: 'investments',
  IMÓVEL: 'building',
  VEÍCULO: 'vehicle',
  EMPRESA: 'company',
  OUTRO: 'other'
}

export const translateEquityType = (type: string) => {
  switch (type.toLowerCase().trim()) {
    case 'investments':
      return 'Investimentos'
    case 'building':
      return 'Imóvel'
    case 'vehicle':
      return 'Veículo'
    case 'company':
      return 'Empresa'
    case 'other':
      return 'Outro'
    default:
      throw new Error(`Invalid fixed asset type: ${type}`)
  }
}
