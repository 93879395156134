import { createContext } from 'react'
import { Transaction } from '../../../components/Navbars/AdminNavbar/components/SaveDataTransaction/types'

export type ISaveTransaction = { parceled?: number; transaction?: Transaction }

export interface ITransactionContext {
  empresaId: string
  error?: Error | null
  familiaId: string
  isEditing: boolean
  isError: boolean
  isOpenEditing: boolean
  isSubmitting: boolean
  isSuccess: boolean
  parentThis: IParentThis
  saveTransaction: (props: ISaveTransaction) => void
  setIsEditing: (value: boolean) => void
  setIsOpenEditing: (value: boolean) => void
  setParentThis: (parentThis: object) => void
  setShowError: (value: boolean) => void
  setShowSuccess: (value: boolean) => void
  onSuccessClose: () => void
  setTransaction: (transaction?: Transaction) => void
  showError: boolean
  showSuccess: boolean
  transaction?: Transaction
  modal: {
    close: () => void
    show: () => void
    open: boolean
  }
  setEditingEntry: (value: Transaction) => void
}

export interface IParentThis {
  atualizarDados: () => void
  limparSelecao: () => void
  cadastroLancamento: {
    hide: () => void
    show: () => void
  }
  setState: (state: object) => void
  state: {
    saveEntry: {
      isSubmitting: boolean
      open: boolean
    }
    item: Transaction
  }
}

export const TransactionContext = createContext<ITransactionContext>({
  empresaId: '',
  familiaId: '',
  isEditing: false,
  isError: false,
  isOpenEditing: false,
  isSubmitting: false,
  isSuccess: false,
  parentThis: {} as IParentThis,
  saveTransaction: () => {},
  onSuccessClose: () => {},
  setIsEditing: (_value: boolean) => {},
  setIsOpenEditing: (_value: boolean) => {},
  setParentThis: (_parentThis: object) => {},
  setShowError: (_value: boolean) => {},
  setShowSuccess: (_value: boolean) => {},
  setTransaction: (_transaction?: Transaction) => {},
  showError: false,
  showSuccess: false,
  transaction: {} as Transaction,
  modal: {
    show: () => {},
    close: () => {},
    open: false
  },
  setEditingEntry: (_value: Transaction) => {}
})
