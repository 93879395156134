import React, { createContext, FC, ReactNode, useCallback, useContext } from 'react'
import { ISelectComboContext } from './types'
import { useInitialInitialDataContext } from '../initialDataContext'
import { MeioPagamento } from '../../../domain/meioPagamento/meioPagamento'
import { IOrcamentoFamily } from '../../../domain/planner/planner'

const DEFAULT_OPTION = { value: null, label: 'Selecione um item' }

export const SelectComboContext = createContext<ISelectComboContext>({})

export const SelectComboContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    // manualInvestments,
    // isManualInvestmentsError,
    // manualInvestmentsError,
    orcamentosFiltro,
    meiosPagamentos
    // categoriasFiltro,
    // patrimoniosAtivosRendaFixa,
    // isLoading,
  } = useInitialInitialDataContext()

  const MEIOS_PAGAMENTOS_OPTIONS = useCallback(() => {
    const data: { value: MeioPagamento; label: string }[] = []

    meiosPagamentos?.forEach((meio) => {
      data.push({
        value: meio,
        label: meio.nome
      })
    })

    return data
  }, [meiosPagamentos])

  const ORCAMENTOS = useCallback(() => {
    const DESPESAS_OPTIONS: { value: IOrcamentoFamily | null; label: string }[] = [DEFAULT_OPTION]
    const RECEITAS_OPTIONS: { value: IOrcamentoFamily | null; label: string }[] = [DEFAULT_OPTION]

    if (orcamentosFiltro) {
      orcamentosFiltro.forEach((item) => {
        if (item.tipoOrcamento === 0 && !item.suspenso) {
          RECEITAS_OPTIONS.push({
            value: item,
            label: item.nome
          })
        }
        //Apenas orcamentos de despesas
        else if (item.tipoOrcamento === 1 && !item.suspenso) {
          DESPESAS_OPTIONS.push({
            value: item,
            label: item.nome
          })
        }
      })

      if (RECEITAS_OPTIONS.length === 1) {
        RECEITAS_OPTIONS[0].label = 'Nenhuma categoria de receita ativa encontrada'
      }

      if (DESPESAS_OPTIONS.length === 1) {
        DESPESAS_OPTIONS[0].label = 'Nenhuma categoria de despesa ativa encontrada'
      }
    }

    return {
      DESPESAS_OPTIONS,
      RECEITAS_OPTIONS
    }
  }, [orcamentosFiltro])

  // console.log('ORCAMENTOS', ORCAMENTOS())

  return (
    <SelectComboContext.Provider
      value={{
        MEIOS_PAGAMENTOS_OPTIONS: MEIOS_PAGAMENTOS_OPTIONS(),
        ORCAMENTOS: ORCAMENTOS()
      }}
    >
      {children}
    </SelectComboContext.Provider>
  )
}

export function useSelectComboContext() {
  const context = useContext(SelectComboContext)
  if (!context) {
    throw new Error('SelectComboContext ===> Need Wrap User container')
  }

  return context
}
