import { noticeError } from '../../../services/Monitoring'
import { deleteWithToken } from '../../../webClient'
import { BudgetDeletionResult } from '../budgetDeletionResult'

export interface Params {
  budgetId: number
}

export const deleteBudget = async ({ budgetId }: Params) => {
  const url = `orcamento/${budgetId}`
  const { data: deletion, status } = await deleteWithToken<BudgetDeletionResult, void>(url)
  if (status !== 200) {
    const error = new Error(`Error deleting budget ${budgetId}. status ${status}`)
    console.error(error)
    noticeError(error, { budgetId })
    throw error
  }
  return deletion
}
