import React, { useEffect } from 'react'
import * as Styled from './styles'
import {
  useDeleteEquityInvestmentById,
  useDeleteEquityTransactionById
  // useDeleteInvestmentById
} from '../../../../queries/investments/mutations'

interface IDeleteInvestmentModal {
  open: boolean
  onDeletion: () => void
  onClose: () => void
  investmentId: string
  investmentName: string
  isManual?: boolean
  isTransaction?: boolean
  transactionId?: string
}

export const DeleteInvestmentModal = ({
  open,
  investmentId,
  transactionId,
  investmentName,
  isManual,
  isTransaction,
  onClose,
  onDeletion
}: IDeleteInvestmentModal) => {
  // const { deleteSelectedInvestmentAsync, isLoadingDeleteEntryInvestmentById, deletedInvestment } =
  //   useDeleteInvestmentById()
  const {
    deleteEntryInvestmentByIdAsync,
    isLoadingDeleteEntryInvestmentById,
    isSuccesDeleteEntryInvestmentById
  } = useDeleteEquityInvestmentById()

  const { deleteEquityTransactionAsync, isSuccessDeleteEquityTransaction } = useDeleteEquityTransactionById()

  useEffect(() => {
    if (isSuccessDeleteEquityTransaction) onDeletion()
  }, [isSuccessDeleteEquityTransaction, onDeletion])

  useEffect(() => {
    if (isSuccesDeleteEntryInvestmentById) onDeletion()
  }, [isSuccesDeleteEntryInvestmentById, onDeletion])

  return (
    <Styled.AlertModal
      open={open}
      title="Exclusão de Investimento"
      onClose={onClose}
      primaryButton={{
        text: 'Excluir Investimento',
        disabled: isLoadingDeleteEntryInvestmentById,
        onClick: () => {
          if (isTransaction) {
            if (transactionId) {
              deleteEquityTransactionAsync({ investmentId, transactionId })
              return
            }
            console.error(`Delete investment: transactionId not found. investmentId: ${investmentId}`)
            return
          }
          deleteEntryInvestmentByIdAsync({ id: investmentId, isManualInvestment: isManual })
        }
      }}
      secondaryButton={{
        text: 'Voltar',
        onClick: onClose,
        disabled: isLoadingDeleteEntryInvestmentById
      }}
    >
      <p>Tem certeza que deseja excluir todos os resgates e aplicações relacionadas ao investimento:</p>

      <Styled.AlertFamilyList>
        <li key={investmentId}>{investmentName}</li>
      </Styled.AlertFamilyList>

      <Styled.InfoMessage message="Esta ação é irreversível" />
    </Styled.AlertModal>
  )
}
