import React from 'react'
import { ReactComponent as ArrowDown } from '../../assets/svgs/arrow-down.svg'
import styled from 'styled-components'
import Colors from '../../domain/Colors'

interface Props {
  grayscale?: boolean
}

const StyledSvg = styled(ArrowDown)<{ color: string }>`
  color: ${({ color }) => color};
`

export default ({ grayscale }: Props) => <StyledSvg color={grayscale ? Colors.arrowGray : Colors.black} />
