import { NavLink } from 'reactstrap'
import styled from 'styled-components'

export const Navigation = styled.nav`
  align-items: center;
  background-color: #f7faff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 101;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

export const Group = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    align-items: center;
    display: flex;
    /* padding: 0 0.75rem; */
    position: relative;

    &.no-padding {
      padding: 0;
    }

    > a.nav-link {
      padding: 0;
    }
  }

  &.divider {
    padding: 1rem;

    li {
      &::after {
        content: '';
        background-color: #e4eaf5;
        bottom: 0;
        margin: auto;
        overflow: hidden;
        position: absolute;
        right: -0.85rem;
        top: 0;
        width: 1.5px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
    margin: 0 0 0 2rem;
    padding: 0;
    width: auto;

    &.divider {
      padding: 0;

      li {
        padding: 0 0.75rem;
      }
    }
  }
`

export const LogoutButton = styled.button`
  align-items: center;
  background: #e4eaf4;
  border: none;
  color: #98aecb;
  cursor: pointer;
  display: flex;
  gap: 7px;
  height: 82px;
  padding: 0 0.7rem;
  padding: 0;
  min-width: 80px;
  justify-content: center;

  i {
    color: #98aecb;
    fill: #98aecb !important;
  }
`

export const ButtonReportarProblema = styled(NavLink)`
  align-items: center;
  background: #ec97a8;
  border: none;
  border-radius: 1rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem !important;
`
