import React from 'react'
import { TableFooter } from '../Footer'
import Colors from '../../../domain/Colors'
import styled from 'styled-components'
import { formatMonetaryValue } from '../../../domain/formatter'
import { IFixedAsset } from '../../../domain/investments/api/ManualInvestments/types'

const ValueContainer = styled.div`
  color: ${Colors.white};
  font-weight: bold;
  min-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

interface Props {
  assets: IFixedAsset[]
}

export default ({ assets }: Props) => {
  const totalValue = assets.reduce((acc, { value }) => acc + value, 0)
  return (
    <TableFooter rounded>
      <ValueContainer>Valor total: {formatMonetaryValue(totalValue)}</ValueContainer>
    </TableFooter>
  )
}
