import { InvestmentItemIdProps } from '../investments'
import { getWithTokenApiV2 } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'

export type Params = {
  itemId: string
  desc?: boolean
  orderBy?: string
  pageIndex: number
  pageSize: number
}

export type Response = InvestmentItemIdProps[]

export const getInvestmentsByItemId = async ({
  itemId,
  desc = true,
  orderBy,
  pageIndex = 0,
  pageSize = 10
}: Params) => {
  const params = {
    columLevelOne: orderBy,
    sort: desc ? 'desc' : 'asc',
    pageIndex,
    pageSize
  }
  const url = `investments/investment/${itemId}/investments`

  const { data: investmentsByItemId, status } = await getWithTokenApiV2<Response>(url, { params })

  if (status !== 200) {
    const msg = `Error getting investments by item id ${itemId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error, { itemId })
    throw new Error(msg)
  }

  return investmentsByItemId
}
