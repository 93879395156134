import React from 'react'
import { MeioPagamento } from '../../../domain/meioPagamento/meioPagamento'
import { useCurrentFamilyId } from '../../../hooks/useCurrentFamilyId'
import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'
import styled from 'styled-components'
import { Transaction } from '../../Modal/DeleteTransaction/types'
import { useAuth } from '../../../hooks/contexts/authContext'
import { useInitialInitialDataContext } from '../../../hooks/contexts/initialDataContext'

interface Props {
  selectedId?: MeioPagamento['id']
  isManual?: boolean
  row?: Transaction
}

const Text = styled.span`
  text-align: center;
`

export default ({ selectedId, row }: Props) => {
  const auth = useAuth()
  const { token } = auth || {}
  const { familyId: plannerFamilyId } = useCurrentFamilyId()
  const familyId = token?.familiaId || plannerFamilyId
  const { meiosPagamentos: meios, isLoadingMeios } = useFamilyMeiosPagamentos(familyId)
  const { financialInstitutionCode, isManual } = row || {}
  const { financialInstitutions } = useInitialInitialDataContext()

  let selectedName = ''

  if (isManual && financialInstitutionCode) {
    const name = financialInstitutions?.find(
      ({ key }) => Number(key) === Number(financialInstitutionCode)
    )?.name

    selectedName = name ?? 'Sem meio'
  } else {
    selectedName = meios?.find(({ id }) => id === selectedId)?.nome ?? 'Sem meio'
  }

  return <Text>{isLoadingMeios ? '...' : selectedName}</Text>
}
