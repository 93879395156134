import React from 'react'
import { AlertModal } from '../alertModal'

export const Error = ({ onClose }) => {
  setTimeout(onClose, 2000)

  return (
    <AlertModal mode="toasty" toastyType="error" open={true} onClose={onClose} title="Exclusão de Lançamento">
      <h4>Ocorreu um erro</h4>
    </AlertModal>
  )
}
