import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const WrapperForLinks = styled.div<{ tipoUsuario: number }>`
  .nav-link,
  .active {
    background: ${({ tipoUsuario, theme: { colors } }) =>
      tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
    color: #fff !important;
  }
  .active > .fa-angle-right {
    color: #fff !important;
    background: ${({ tipoUsuario, theme: { colors } }) =>
      tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
  }
  .nav-link.active,
  .nav-link.active i {
    color: #fff !important;
    background: ${({ tipoUsuario, theme: { colors } }) =>
      tipoUsuario <= 1
        ? colors.secondaryDarker
        : colors.backgroundSidebarManagerHighlightDarker || colors.primaryDarker} !important;
    border: none !important;
    border-image: none !important;
  }
`

export const NavLinkItem = styled(NavLink)<{ $tipoUsuario: number }>`
  &.active {
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
    color: #fff !important;
  }
  &.active > .fa-angle-right {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1 ? colors.secondaryDarker : colors.primaryDarker} !important;
  }

  &.active,
  &.active i {
    color: #fff !important;
    background: ${({ $tipoUsuario, theme: { colors } }) =>
      $tipoUsuario <= 1
        ? colors.secondaryDarker
        : colors.backgroundSidebarManagerHighlightDarker || colors.primaryDarker} !important;
    border: none !important;
    border-image: none !important;
  }
`

export default WrapperForLinks
