import React, { createContext, FunctionComponent, useContext, useState } from 'react'

interface TutorialsContextValue {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

interface TutorialsProviderProps {
  children?: React.ReactNode
}

const TutorialsContext = createContext<TutorialsContextValue | undefined>(undefined)

const TutorialsProvider: FunctionComponent<TutorialsProviderProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <TutorialsContext.Provider
      value={{
        isOpen,
        setIsOpen: (open) => setOpen(open)
      }}
    >
      {children}
    </TutorialsContext.Provider>
  )
}

export { TutorialsProvider, TutorialsContext }
export type { TutorialsProviderProps, TutorialsContextValue }

export const useTutorialsContext = () => {
  const context = useContext(TutorialsContext)
  if (!context) {
    throw new Error('TutorialsContext ===> Need Wrap User container')
  }
  return context
}
