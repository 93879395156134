import { UserType } from '../domain/user/userType'
import { isAbsent } from '../helpers'
import { useLocalStorage } from './useLocalStorage'

/**@deprecated prefer using authContext and useAuthHook */
export const useUserType = (): UserType => {
  const { value: tipoUsuario } = useLocalStorage('tipo-usuario')
  if (isAbsent(tipoUsuario)) throw new Error('tipo-usuario not found')
  switch (tipoUsuario) {
    case '0':
      return 'admin'
    case '1':
      return 'planejador'
    case '2':
      return 'pessoa'
    default:
      throw new Error(`Invalid user type: ${tipoUsuario}`)
  }
}
