import { IDefaultParams, TInvestmentTypes } from './types'

export const OPERATION_OPTIONS = [
  { value: 0, label: 'Aplicação' },
  { value: 1, label: 'Resgate' }
]

export const REDEEM_OPTIONS = [
  { value: 1, label: 'Resgate' },
  { value: 3, label: 'Come Cotas' }
]

export const getOperation = (operationCode?: 0 | 1 | 2 | null) => {
  if (typeof operationCode !== 'number')
    return {
      isInvesting: false,
      isRedeem: false
    }

  return {
    isInvesting: operationCode === 0,
    isRedeem: operationCode === 1
  }
}

export const getVistaType = (vistaType: TInvestmentTypes) => {
  return {
    isRendaFixaPos: vistaType === 'Renda Fixa Pós',
    isRendaFixaPre: vistaType === 'Renda Fixa Pré',
    isAcoesMoedas: ['Ações', 'ETFs', 'Fiis', 'Moedas'].includes(vistaType),
    isRendaFixa: ['Renda Fixa Pós', 'Renda Fixa Pré'].includes(vistaType)
  }
}

export const defaultParams: IDefaultParams = { type: 'type', sort: 'desc', columnLevelTwo: 'date' }

export const ERROR_MESSAGES = {
  'The balance of the investment cannot be negative': 'O valor do investimento não pode ser negativo.'
}
