import { useEffect } from 'react'

export const useOnScrollEnd = (ref: HTMLDivElement | null, callback: () => void) => {
  useEffect(() => {
    const handleScroll = () => {
      if (!ref) {
        console.warn('usage of useInfiniteScroll hook without ref')
        return
      }

      const scrollHeight = ref.scrollHeight
      const scrollTop = ref.scrollTop
      const clientHeight = ref.clientHeight
      if (scrollHeight - scrollTop <= clientHeight * 1.2) callback()
    }

    ref?.addEventListener('scroll', handleScroll)

    return () => ref?.removeEventListener('scroll', handleScroll)
  }, [callback, ref])
}
