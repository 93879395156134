import { CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import Iframe from 'react-iframe'
import styled from 'styled-components'
import GoBackIconButton from '../../components/Buttons/GoBackButton/GoBackIconButton'

const Container = styled.div`
  width: 100%;
  height: 83vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
const GobackContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`
const LoadingContainer = styled.div`
  position: absolute;
`

interface Props {
  match: {
    params: {
      simulador: 'vida' | 'financial_planning' | 'beneficios-fiscais'
    }
  }
}
const SimuladorSeguro = (props: Props) => {
  const { simulador } = props.match.params
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Container>
      <GobackContainer>
        <GoBackIconButton />
      </GobackContainer>

      {isLoading && (
        <LoadingContainer>
          <CircularProgress size={50} />
        </LoadingContainer>
      )}

      <Iframe
        width="100%"
        height="100%"
        onLoad={() => setIsLoading(false)}
        id="pagina-simuladores"
        url={`https://simuladores.simulife.io/vista/${simulador}?ownerId=62`}
      />
    </Container>
  )
}

export default SimuladorSeguro
