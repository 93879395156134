import React from 'react'
import Button from '../../Buttons'

export interface Option<T> {
  text: string
  value: T
  tooltip?: string
}

interface Props<T> {
  option: Option<T>
  onChange?: (selected: T) => void
}

export default <T,>({ option: { text, value, tooltip }, onChange }: Props<T>) => {
  return (
    <Button
      text={text}
      onClick={(e) => {
        e.preventDefault()
        onChange?.(value)
      }}
      tooltip={tooltip}
    />
  )
}
