import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useOverflowY } from '../../../hooks'

export interface Selectable<T> {
  text: string
  value: T
}

interface Props<T> {
  options: Selectable<T>[]
  onSelection: (selected: T) => void
}

const OptionsContainer = styled.div<{ verticalFlow: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: ${({ verticalFlow }) => (verticalFlow ? 'inherit' : '100%')};
  bottom: ${({ verticalFlow }) => (verticalFlow ? '100%' : 'inherit')};
  margin-top: 2%;
  z-index: 200;
  width: 100%;
  border-radius: 0.25rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  box-shadow: 0.15rem 0.15rem 0.8rem gray;
  background-color: white;
  max-height: 30vh;
  overflow-y: auto;
`

export default <T,>({ options, onSelection }: Props<T>) => {
  const { ref, isOverflowY } = useOverflowY()
  return (
    <OptionsContainer ref={ref} verticalFlow={isOverflowY}>
      {options.map((o, i) => (
        <SingleOption option={o} key={i} onSelected={onSelection} />
      ))}
    </OptionsContainer>
  )
}

const OptionContainer = styled.div`
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-left: 0.6rem;
  background-color: white;
  &:hover {
    background-color: LightSkyBlue;
  }
  transition-property: background-color;
  transition-duration: 0.1337s;
  transition-timing-function: ease-in-out;
`

interface OptionProps<T> {
  option: Selectable<T>
  onSelected: (value: T) => void
}

const SingleOption = <T,>({ option, onSelected }: OptionProps<T>) => {
  const onSelection = useCallback(() => onSelected(option.value), [onSelected, option])
  return <OptionContainer onClick={onSelection}>{option.text}</OptionContainer>
}
