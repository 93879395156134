import React, { useEffect, useMemo } from 'react'
import Select from '../Select'
import { useGetPersonByFamilyId } from '../../../queries/family/mutations'
import { Dono } from '../../Navbars/AdminNavbar/components/SaveDataTransaction/types'

export const PersonsSelect = ({
  selectedPerson,
  disabled,
  onChange,
  borderless = false,
  familyId
}: {
  selectedPerson?: Dono
  disabled?: boolean
  onChange: (value: Dono) => void
  borderless?: boolean
  familyId?: number
}) => {
  const { persons, isLoadingGetPersonByFamilyId, getPersonByFamilyIdAsync } = useGetPersonByFamilyId()
  const selected = selectedPerson?.nome
    ? `${selectedPerson?.nome} ${selectedPerson?.sobrenome}`
    : 'Selecione...'
  // const responsaveisComCarteira: Dono[] = []

  const MANAGER_OPTIONS: { value: Dono; text: string }[] = useMemo(
    () =>
      persons?.map((item) => ({
        value: item,
        text: `${item.nome} ${item.sobrenome}`
      })) || [],
    [persons]
  )

  useEffect(() => {
    getPersonByFamilyIdAsync({ familyId })
  }, [familyId, getPersonByFamilyIdAsync])

  return (
    <Select
      borderless={borderless}
      disabled={disabled}
      grayscale={true}
      loading={isLoadingGetPersonByFamilyId}
      onSelection={onChange}
      options={MANAGER_OPTIONS}
      placeholder={selected}
    />
  )
}
