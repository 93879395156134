import styled from 'styled-components'

const WrapperForDatePicker = styled.div`
  position: relative;
  width: 100px;
  .react-datepicker-wrapper input {
    border-bottom: 1px solid ${({ color }) => color} !important;
  }
  .inputCurrency {
    border-bottom: 1px solid ${({ color }) => color} !important;
  }
`

export const GroupedDatePicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-shrink: 0;
  width: 210px;
`

export const DatePickerInfo = styled.p`
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.primary};
  width: 100%;
`

export default WrapperForDatePicker
