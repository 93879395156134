import React from 'react'
import { getRoute, getRoutePath } from '../../../routes'
import { MdOutlineArrowBack } from 'react-icons/md'
import styled from 'styled-components'

const BorderLessButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 20px;
  cursor: pointer;
  color: #6c757d;
`

export default () => {
  const goBack = () => {
    if (window.history.length <= 1) window.open(getRoutePath(getRoute('Home')), '_self')
    else window.history.back()
  }
  return (
    <BorderLessButton>
      <MdOutlineArrowBack onClick={goBack} size={30} />
    </BorderLessButton>
  )
}
