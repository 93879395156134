// import { useLocalStorage } from './useLocalStorage'

import { useFamilyOrcamentosForTable } from '../queries/orcamento/queries'
import { useFetchPatrimonioByFamilyId } from '../queries/patrimonio/mutation'
import { useCoreContext } from './contexts/coreProvider'
import { useInitialInitialDataContext } from './contexts/initialDataContext'

export const useStore = () => {
  const { familiaId } = useCoreContext()
  const { categoriasFiltro, meiosPagamentos, financialInstitutions, manualPatrimonies } =
    useInitialInitialDataContext()
  const { orcamentos } = useFamilyOrcamentosForTable(familiaId as number)
  const { patrimoniosByFamilyId } = useFetchPatrimonioByFamilyId()

  const findOrcamento = (orcamentoId?: number | null) => orcamentos?.find((item) => item?.id === orcamentoId)
  const findCategory = (categoriaId?: number | null) =>
    categoriasFiltro?.find((item) => item?.id === categoriaId)
  const findMeioPagamento = (meioPagamentoId?: number | null) =>
    meiosPagamentos?.find((item) => item?.id === meioPagamentoId)
  const findPatrimony = (patrimonioId) => patrimoniosByFamilyId?.find((item) => item?.id === patrimonioId)
  const findManualPatrimony = (patrimonioId) => manualPatrimonies?.find((item) => item?.id === patrimonioId)
  const findInstitution = (idInstituicaoFinanceira: number) =>
    financialInstitutions?.find((item) => item.key === Number(idInstituicaoFinanceira))

  return {
    orcamentos,
    categoriasFiltro,
    meiosPagamentos,
    findOrcamento,
    findCategory,
    findMeioPagamento,
    findPatrimony,
    findInstitution,
    findManualPatrimony
  }
}
