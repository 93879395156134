import { useMutation, useQueryClient } from 'react-query'
import { cacheBaseKey } from './queries'
import {
  updateInvestment as updateInvestmentAPI,
  Params as UpdateInvestmentNameParams
} from '../../domain/investments/api/updateInvestment'
import { asyncDelay } from '../../domain/timing'
import {
  DeleteSelectedInvestmentParams,
  DeleteSelectedInvestmentResult,
  deleteInvestmentById
} from '../../domain/investments/api/deleteInvestmentById'
import { updateSuspendInvestmentById } from '../../domain/investments'
import {
  deleteEquityInvestmentById,
  editEquityInvestmentById,
  createTransactionManualInvestment,
  saveManualInvestment,
  deleteEquityTransactionById,
  deleteManyTransactionEquitysApi
} from '../../domain/investments/api/ManualInvestments/manualInvestments'
import { updateActiveOrInactiveAccountById } from '../../domain/investments/api/activateOrInactiveAccount'
import { accountCacheKey } from '../bankingIntegration/queries'
import { ERROR_MESSAGES } from '../../domain/investments/api/ManualInvestments/constants'
import { AxiosError } from 'axios'

export const useDeleteInvestmentById = () => {
  const client = useQueryClient()

  const {
    isLoading: isLoadingDeleteInvestment,
    isError: isErrorDeleteInvestment,
    data: deleteInvestmentData,
    error: deleteInvestmentError,
    isSuccess: deletedInvestment,
    mutateAsync: deleteSelectedInvestmentAsync
  } = useMutation<DeleteSelectedInvestmentResult, Error, DeleteSelectedInvestmentParams, unknown>(
    deleteInvestmentById,
    {
      onSuccess: () => {
        // Atualizações ocorrem assíncronamente no backend, por isso precisamos aguardar um pouco
        asyncDelay(400).then(() => {
          client.invalidateQueries(cacheBaseKey)
        })
      }
    }
  )

  return {
    deleteInvestmentData,
    deleteInvestmentError,
    deleteSelectedInvestmentAsync,
    isErrorDeleteInvestment,
    isLoadingDeleteInvestment,
    deletedInvestment
  }
}

export const useUpdateInvestmentById = () => {
  const client = useQueryClient()

  const {
    isError: isErrorUpdateInvestment,
    isLoading: isLoadingUpdateInvestment,
    mutateAsync: updateInvestmentAsync,
    mutate: updateInvestment,
    data: updateInvestmentResponse,
    error: updateInvestmentError
  } = useMutation<unknown, Error, UpdateInvestmentNameParams, unknown>(updateInvestmentAPI, {
    onSuccess: () => {
      // Atualizações ocorrem assíncronamente no backend, por isso precisamos aguardar um pouco
      asyncDelay(400).then(() => {
        client.invalidateQueries(cacheBaseKey)
      })
    }
  })

  return {
    isLoadingUpdateInvestment,
    isErrorUpdateInvestment,
    updateInvestmentAsync,
    updateInvestment,
    updateInvestmentResponse,
    updateInvestmentError
  }
}

export const useSuspendInvestmentById = () => {
  const client = useQueryClient()

  const { mutateAsync: suspendInvestmentByIdAsync } = useMutation(updateSuspendInvestmentById, {
    onSuccess: () => {
      // Atualizações ocorrem assíncronamente no backend, por isso precisamos aguardar um pouco
      asyncDelay(400).then(() => {
        client.invalidateQueries(cacheBaseKey)
      })
    }
  })

  return { suspendInvestmentByIdAsync }
}

export const useSaveManualInvestment = () => {
  const client = useQueryClient()

  const {
    mutate: createManualInvestment,
    mutateAsync: createManualInvestmentAsync,
    isLoading: isLoadingCreateManualInvestment,
    isError: isErrorCreateManualInvestment,
    error: createManualInvestmentError,
    isSuccess: createManualInvestmentSuccess,
    reset: resetCreateManualInvestment
  } = useMutation(saveManualInvestment, {
    onSuccess: () => {
      asyncDelay(400).then(() => {
        client.invalidateQueries(cacheBaseKey)
      })
    }
  })

  return {
    createManualInvestment,
    createManualInvestmentAsync,
    isLoadingCreateManualInvestment,
    isErrorCreateManualInvestment,
    createManualInvestmentError,
    createManualInvestmentSuccess,
    resetCreateManualInvestment
  }
}

export const useSaveEntryManualInvestment = () => {
  const client = useQueryClient()

  const {
    mutate: createEntryManualInvestment,
    mutateAsync: createEntryManualInvestmentAsync,
    isLoading: isLoadingCreateEntryManualInvestment,
    isError: isErrorCreateEntryManualInvestment,
    error,
    isSuccess: createEntryManualInvestmentSuccess,
    reset: resetCreateEntryManualInvestment
  } = useMutation(createTransactionManualInvestment, {
    onSuccess: () => {
      asyncDelay(1000).then(() => {
        client.invalidateQueries(cacheBaseKey)
        resetCreateEntryManualInvestment()
      })
    }
  })

  let createEntryManualInvestmentError

  if (error) {
    const { data } = (error as AxiosError)?.response || {}

    if (data?.message) {
      const message = ERROR_MESSAGES[data.message]
      if (message) createEntryManualInvestmentError = message
    }
  }

  return {
    createEntryManualInvestment,
    createEntryManualInvestmentAsync,
    isLoadingCreateEntryManualInvestment,
    isErrorCreateEntryManualInvestment,
    createEntryManualInvestmentError,
    createEntryManualInvestmentSuccess,
    resetCreateEntryManualInvestment
  }
}

export const useActiveOrInactiveAccountById = () => {
  const client = useQueryClient()

  const { mutateAsync: activeOrInactiveAccountByIdAsync } = useMutation(updateActiveOrInactiveAccountById, {
    onSuccess: () => {
      // Atualizações ocorrem assíncronamente no backend, por isso precisamos aguardar um pouco
      asyncDelay(400).then(() => {
        client.invalidateQueries(accountCacheKey)
      })
    }
  })

  return { activeOrInactiveAccountByIdAsync }
}

export const useDeleteEquityInvestmentById = () => {
  const client = useQueryClient()

  const {
    mutate: deleteEntryInvestmentById,
    mutateAsync: deleteEntryInvestmentByIdAsync,
    isLoading: isLoadingDeleteEntryInvestmentById,
    isError: isErrorDeleteEntryInvestmentById,
    error: deleteEntryInvestmentByIdError,
    isSuccess: isSuccesDeleteEntryInvestmentById
  } = useMutation(deleteEquityInvestmentById, {
    onSuccess: () => {
      // asyncDelay(200).then(() => {
      client.invalidateQueries(cacheBaseKey)
      // })
    }
  })

  return {
    deleteEntryInvestmentById,
    deleteEntryInvestmentByIdAsync,
    isLoadingDeleteEntryInvestmentById,
    isErrorDeleteEntryInvestmentById,
    deleteEntryInvestmentByIdError,
    isSuccesDeleteEntryInvestmentById
  }
}

export const useEditEntryInvestmentById = () => {
  const client = useQueryClient()

  const {
    mutate: editEntryInvestmentById,
    mutateAsync: editEntryInvestmentByIdAsync,
    isLoading: isLoadingEditEntryInvestmentById,
    isError: isErrorEditEntryInvestmentById,
    error: editEntryInvestmentByIdError,
    isSuccess: isSuccessEditEntryInvestmentById
  } = useMutation(editEquityInvestmentById, {
    onSuccess: () => {
      asyncDelay(200).then(() => {
        client.invalidateQueries(cacheBaseKey)
      })
    }
  })

  return {
    editEntryInvestmentById,
    editEntryInvestmentByIdAsync,
    isLoadingEditEntryInvestmentById,
    isErrorEditEntryInvestmentById,
    editEntryInvestmentByIdError,
    isSuccessEditEntryInvestmentById
  }
}

/**
 * @description BFF: Delete Transaction by Equity (Patrimony) ID and Transaction ID */
export const useDeleteEquityTransactionById = () => {
  const client = useQueryClient()

  const {
    mutate: deleteEquityTransaction,
    mutateAsync: deleteEquityTransactionAsync,
    isLoading: isLoadingDeleteEquityTransaction,
    isError: isErrorDeleteEquityTransaction,
    error: deleteEquityTransactionError,
    isSuccess: isSuccessDeleteEquityTransaction
  } = useMutation(deleteEquityTransactionById, {
    onSuccess: () => {
      // asyncDelay(200).then(() => {
      client.invalidateQueries(cacheBaseKey)
      // })
    }
  })

  return {
    deleteEquityTransaction,
    deleteEquityTransactionAsync,
    isLoadingDeleteEquityTransaction,
    isErrorDeleteEquityTransaction,
    deleteEquityTransactionError,
    isSuccessDeleteEquityTransaction
  }
}

export const useDeleteManyTransactionEquitys = () => {
  const client = useQueryClient()

  const {
    mutate: deleteManyTransactionEquitys,
    mutateAsync: deleteManyTransactionEquitysAsync,
    isLoading: isLoadingDeleteEquityTransaction,
    isError: isErrorDeleteEquityTransaction,
    error: deleteManyTransactionEquitysError,
    isSuccess: isSuccessDeleteEquityTransaction
  } = useMutation(deleteManyTransactionEquitysApi, {
    onSuccess: () => {
      // asyncDelay(200).then(() => {
      client.invalidateQueries(cacheBaseKey)
      // })
    }
  })

  return {
    deleteManyTransactionEquitys,
    deleteManyTransactionEquitysAsync,
    isLoadingDeleteEquityTransaction,
    isErrorDeleteEquityTransaction,
    deleteManyTransactionEquitysError,
    isSuccessDeleteEquityTransaction
  }
}
