import React from 'react'
import { ChipContainer, ChipSubTitle, ChipTitle } from './Chip.styles'

interface Props {
  title: string | number
  subTitle: string | number
}

export const Chip = ({ title, subTitle }: Props) => {
  return (
    <ChipContainer>
      <ChipTitle>{title}</ChipTitle>
      <ChipSubTitle>{subTitle}</ChipSubTitle>
    </ChipContainer>
  )
}
