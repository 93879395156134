import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Row, Col } from 'reactstrap'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { InputQuantity } from '../../../../Inputs/InputQuantidade'
import { useSelectComboContext } from '../../../../../hooks/contexts/selectComboContext'
import { FieldSelectPaymentMethods } from '../../../../Selects/FieldSelectPaymentMethods'
import { FieldSelectCategoria } from '../../../../Selects/FieldSelectCategoria'
import { TIPO_ORCAMENTO_NAME_CODE, Transaction } from '../SaveDataTransaction/types'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { FieldSelectDate } from '../../../../Selects/FieldSelectDate'
import moment from 'moment'
import { getTransactionType, TRANSACTION_NAME_CODE } from '../../../../../domain/lancamento/lancamento'
import { CurrencyParceledInput } from '../../../../Inputs/InputAmountV2'

export const FormDivida = () => {
  const { SaveButton } = useSaveTransactionsActions()
  const { MEIOS_PAGAMENTOS_OPTIONS } = useSelectComboContext()
  const { transaction, setTransaction, isSubmitting } = useTransactionContext()
  const [item, setStateItem] = useState<Transaction | undefined>({
    ...transaction,
    tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Dívida
  } as Transaction)
  const isEditing = !!item?.id
  const [paymentMethod, setPaymentMethod] = useState(item?.meioPagamento || null)
  const { isParceled, isRepeated } = getTransactionType({
    transactionType: item?.tipoDeParcela ?? TRANSACTION_NAME_CODE.unico
  })

  const initialData = useMemo(() => {
    let temp = item
    if (item && !item?.repeatedParcels) {
      temp = {
        ...item,
        repeatedParcels: item?.parcelas
      }
    }

    return temp
  }, [item])

  const [validForm, setValidForm] = useState(false)
  const validateForm = useCallback(() => {
    const { categoriaId, meioPagamentoId, valor, data } = item || {}
    const amount = Number(valor) > 0
    const basicFields = categoriaId && meioPagamentoId && amount && data

    if (!basicFields) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }, [item])

  const findMeioPagamento = useCallback(
    (findMeioPagamentoPadrao = false) => {
      if (item && MEIOS_PAGAMENTOS_OPTIONS) {
        if (item?.meioPagamento?.nome && !findMeioPagamentoPadrao) {
          const meioPagamento = MEIOS_PAGAMENTOS_OPTIONS?.find((op) => op.label === item?.meioPagamento.nome)
          // @ts-expect-error mismatch
          setPaymentMethod(meioPagamento?.value)
        } else if (item?.categoria?.meioPagamentoPadrao?.nome) {
          const meioPagamentoPadrao = MEIOS_PAGAMENTOS_OPTIONS.find(
            (op) => op.label === item?.categoria.meioPagamentoPadrao.nome
          )
          // @ts-expect-error mismatch
          setPaymentMethod(meioPagamentoPadrao?.value)
        }
      }
    },
    [MEIOS_PAGAMENTOS_OPTIONS, item]
  )

  const setItem = (data, callback?: () => void) => {
    setStateItem({
      ...item,
      ...data
    })
    if (callback) {
      setTimeout(() => {
        callback?.()
      }, 100)
    }
  }

  useEffect(() => {
    findMeioPagamento()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findMeioPagamento, item?.meioPagamento])

  useEffect(() => {
    setItem({
      meioPagamento: paymentMethod
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  useEffect(() => {
    validateForm()
  }, [item, validateForm])

  useEffect(() => {
    const _transaction = transaction || {}
    setItem({
      ..._transaction,
      data: transaction?.data ?? moment(new Date(), 'DD-MM-YYYY').toDate(),
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Dívida
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validForm) {
      setTransaction(item)
    }
  }, [item, setTransaction, validForm])

  return (
    <Row>
      <FieldSelectCategoria
        label="Dívida *"
        orcamentoType={3}
        defaultValue={
          item?.categoria?.id ?? typeof item?.categoriaId === 'number' ? Number(item?.categoriaId) : undefined
        }
        onSelect={({ categoria }) => {
          const meioPagamento = categoria.meioPagamentoPadrao ?? item?.meioPagamento ?? null

          let newItem = {
            categoria: categoria,
            categoriaId: categoria?.id
          }

          if (categoria?.meioPagamentoPadrao) {
            newItem = {
              ...newItem,
              // @ts-expect-error
              meioPagamento: meioPagamento,
              meioPagamentoId: meioPagamento?.id
            }
          }

          setItem(newItem, () => findMeioPagamento(true))
        }}
      />

      <FieldSelectPaymentMethods
        defaultValue={
          item?.meioPagamentoId ??
          item?.financialInstitutionCode ??
          item?.meioPagamento?.id ??
          paymentMethod?.id
        }
        onSelect={({ value }) => {
          setItem({
            meioPagamento: value,
            meioPagamentoId: value?.id
          })
        }}
      />

      <FieldSelectDate
        id="lancamentoData"
        onChange={(date: Date) => {
          setItem({
            data: date
          })
        }}
        disabled={isSubmitting || (isEditing && (isParceled || isRepeated))}
        selectedValue={item?.data}
      />

      <CurrencyParceledInput
        label="Valor Pago"
        partialData={{
          id: item?.id,
          valor: item?.valor,
          parcelas: item?.parcelas,
          valorParcela: item?.valorParcela,
          tipoDeParcela: item?.tipoDeParcela
        }}
        disabled={isSubmitting}
        onChange={(data) => {
          setItem(data)
          return
        }}
      />
      {/* <FieldInputAmount
        label="Valor Pago"
        partialData={{
          id: item?.id,
          valor: item?.valor,
          parcelas: item?.parcelas,
          valorParcela: item?.valorParcela,
          tipoDeParcela: item?.tipoDeParcela
        }}
        disabled={isSubmitting}
        onChange={(data) => {
          setItem({ ...data })
        }}
      /> */}
      {/* <Col lg="6" xl="6" className="lancamento divida">
        <div>
          <label className="lancamento divida">Valor Pago *</label>

          <CurrencyInput
            id="lancamentoValor"
            style={{ height: 38, paddingLeft: 25 }}
            value={isEditing && (isParceled || isRepeated) ? item?.valorParcela : item?.valor}
            // defaultValue={item?.id && getInputAmount()}
            thousandSeparator={'.'}
            decimalSeparator={','}
            precision="2" //prefix={'R$ '}
            onChangeEvent={(_, formattedValue, value) => {
              if (isEditing) {
                setItem({
                  valor: value,
                  editRepeated: isRepeated,
                  editInstallments: isParceled,
                  valorParcela: isParceled || isRepeated ? value : null,
                  parcelado: isParceled || isRepeated,
                  parcelas: isParceled || isRepeated ? item?.parcelas : null,
                  valorFormatado: formattedValue
                })
                return
              }

              setItem({
                valor: value,
                valorFormatado: formattedValue
              })
            }}
          />
        </div>
      </Col> */}
      <Col lg="6" xl="6">
        <div>
          <label>Descrição</label>
          <textarea
            id="lancamentoOQue"
            disabled={isSubmitting}
            onChange={(e) => {
              setItem({
                descricao: e.target.value
              })
              // parentThis.setState({
              //   item: {
              //     ...item,
              //     descricao: e.target.value
              //   }
              // })
            }}
            value={item?.descricao}
            style={{ padding: 20 }}
          />
        </div>
      </Col>

      <Col lg="6" xl="6" className="lancamento divida">
        <div>
          <InputQuantity
            onRadioChange={(value) => {
              setItem({
                tipoDeParcela: value
              })
              // parentThis.setState({
              //   item: {
              //     ...item,
              //     tipoDeParcela: value
              //   }
              // })
            }}
            onInputChange={(event) => setItem({ parcelas: event.target.value })}
            parcelas={item?.parcelas}
            tipoDeParcela={item?.tipoDeParcela}
            isEditing={isEditing}
            disabled={isSubmitting}
            // @ts-expect-error
            initialData={initialData}
          />
        </div>
      </Col>

      <Col lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton
          disabled={!validForm || !item || isSubmitting}
          transaction={{
            ...item,
            // @ts-expect-error
            meioPagamento: item?.meioPagamento ?? paymentMethod
          }}
          // beforeSave={() => {
          //   if (!item) return
          //   let newItem = item
          //   if (paymentMethod && !item?.meioPagamento) {
          //     newItem = {
          //       ...item,
          //       meioPagamento: paymentMethod
          //     }
          //     return
          //   }

          //   setTransaction(newItem)
          // }}
        />
      </Col>
    </Row>
  )
}
