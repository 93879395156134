import { noticeError } from '../../../services/Monitoring'
import { s3Client } from '../../../webClient'
import Theme, { isRawTheme, parseRawTheme } from '../theme'

export const getTheme = async (themeName: string): Promise<Theme> => {
  const url = themeName + '.json'
  const { data: maybeTheme, status } = await s3Client.get(url)
  if (status !== 200) {
    const message = `Error ${status} getting theme ${themeName}`
    const error = new Error(message)
    noticeError(error)
    throw error
  }
  if (!isRawTheme(maybeTheme)) {
    const message = `Invalid payload in theme ${themeName}: ${JSON.stringify(maybeTheme)}`
    const error = new Error(message)
    noticeError(error)
    throw error
  }
  const theme = parseRawTheme(maybeTheme)
  return theme
}
