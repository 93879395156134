import styled from 'styled-components'

export const LinkButton = styled.a`
  background: linear-gradient(90deg, #fa8834, #fa8834);
  border-radius: 1rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  transform: scale(1);
  transition: background 150ms ease-in-out, transform 150ms ease-in-out;

  &:hover {
    background: linear-gradient(90deg, #fa8834, #fb266d);
    color: #fff;
    transform: scale(1.03);
  }
`
