import React from 'react'
import * as S from './styles'
import { TrashCanIcon } from '../../../Icons'

export const ButtonDelete = ({ id, name, handleDelete, isManual = false }) => {
  return (
    <S.CellContainer>
      <TrashCanIcon
        size="small"
        tooltip={`Deletar '${name}'`}
        onClick={() => handleDelete({ id, name, isManual })}
      />
    </S.CellContainer>
  )
}
