import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Content = styled.div`
  height: 100%;
  min-height: 0;
  overflow-x: auto;
  position: relative;
  z-index: 1;
`

export const Item = styled.div<{ visible: boolean }>`
  height: 0;
  left: 0;
  max-height: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 100ms;
  z-index: -1;

  ${({ visible }) =>
    visible &&
    css`
      height: auto;
      max-height: initial;
      position: relative;
      opacity: 1;
      z-index: 1;
    `}
`

export const Footer = styled.div`
  align-items: center;
  gap: 15px;
  justify-content: center;
  background: #fff;
  display: flex;
  padding-top: 7px;
  z-index: 2;

  .aux-button {
    font-size: 12px;
  }

  button[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`

export const PageButton = styled.button`
  position: relative;

  > span {
    position: absolute;
    left: -15px;
    opacity: 0;
  }
`
