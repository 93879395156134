import { noticeError } from '../../../../services/Monitoring'
import {
  deleteWithTokenApiV2,
  getWithTokenApiV2,
  patchWithTokenApiV2,
  postWithTokenApiV2
} from '../../../../webClient'
import { defaultParams } from './constants'
import {
  IDeleteEntryManualInvestment,
  IDeleteTransactionManualInvestment,
  IGetAllInvestmentsRequest,
  IGetAllInvestmentsResult,
  IGetAllTransactions,
  IGetAllTransactionsResult,
  IManualInvestmentResult,
  IResponseInvestmentsTypes,
  ISaveEntryWithManualInvestment,
  TGetInvestmentsTypesResult,
  TGetManualInvestmentsResult,
  TInvestmentTypes
} from './types'

export const getInvestmentsTypes = async () => {
  const url = 'bff/manual_investment/types'

  const { data, status } = await getWithTokenApiV2<IResponseInvestmentsTypes>(url)

  if (status !== 200) {
    const msg = 'Error getting investments types'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data as unknown as TGetInvestmentsTypesResult
}

export const getManualInvestments = async ({ userId }: { userId: number }) => {
  if (!userId) {
    console.error('Error getting manual investments: userId is required')
    return
  }
  const url = `bff/manual_investment/userId/${userId}`

  const { data, status } = await getWithTokenApiV2<TGetManualInvestmentsResult>(url)

  if (status !== 200) {
    const msg = 'Error getting manual investments'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export const getFinancialInstitutionApi = async () => {
  const url = 'bff/financial_institution'

  const { data, status } = await getWithTokenApiV2<IManualInvestmentResult[]>(url)

  if (status !== 200) {
    const msg = 'Error getting financial institution'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data || []
}

export const getAllEquitysApi = async ({ familyId, params = defaultParams }: IGetAllInvestmentsRequest) => {
  const url = `bff/investment_all/familyId/${familyId}`
  const mergedParams = {
    ...defaultParams,
    ...params
  }
  const { data, status } = await getWithTokenApiV2<IGetAllInvestmentsResult>(url, { params: mergedParams })

  if (status !== 200) {
    const msg = 'Error getting all investments'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data || []
}

export const getAllTransactions = async ({ investmentId, params = defaultParams }: IGetAllTransactions) => {
  const url = `bff/investment/${investmentId}/transactions`
  const { data, status } = await getWithTokenApiV2<IGetAllTransactionsResult>(url, { params })

  if (status !== 200) {
    const msg = 'Error getting all investments'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data || []
}

/** @description BFF: Create Equity (Patrimony) Investment */
export const saveManualInvestment = async (body: {
  description: string
  familyId: number
  investmentType: TInvestmentTypes
  userId: number
}) => {
  const url = 'bff/manual_investment'
  const { data, status } = await postWithTokenApiV2(url, body)

  if (status !== 200) {
    const msg = `Error when creating Equity (Patrimony) investment: ${body}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

/** @description BFF: Delete Equity (Patrimony) with manual investment */
export const deleteEquityInvestmentById = async ({
  id,
  isManualInvestment = false
}: IDeleteEntryManualInvestment) => {
  const url = `bff/investment/${id}`
  const { data, status } = await deleteWithTokenApiV2(url, undefined, {
    params: {
      isManualInvestment
    }
  })

  if (status !== 200) {
    const msg = `Error when deleting Equity (Patrimony). id: ${id}, isManualInvestment: ${isManualInvestment}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

/** @description BFF: Edit Equity (Patrimony) with manual investment */
export const editEquityInvestmentById = async (
  payload: ISaveEntryWithManualInvestment & { isManualInvestment?: boolean; id: string }
) => {
  const id = payload?.entryId ?? payload?.id
  const url = `bff/investment/${id}`
  const { data, status } = await patchWithTokenApiV2(url, payload)

  if (status !== 200) {
    const msg = `Error deleting Entry: ${payload}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

/**
 * @description BFF: Delete Transaction by Equity (Patrimony) ID and Transaction ID
 * @param {number} investmentId
 * @param {number} transactionId
 * @param {boolean} isManualInvestment
 **/
export const deleteEquityTransactionById = async ({
  investmentId,
  transactionId,
  isManualInvestment
}: IDeleteTransactionManualInvestment) => {
  const url = `bff/manual_investment/${investmentId}/transaction/${transactionId}`
  const { data, status } = await deleteWithTokenApiV2(url, undefined, {
    params: {
      isManualInvestment
    }
  })

  if (status !== 200) {
    const msg = `Error deleting Transaction with id: ${transactionId} and investmentId: ${investmentId}, isManualInvestment: ${isManualInvestment}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export const deleteManyTransactionEquitysApi = async ({
  data
}: {
  data: {
    investmentId: number
    transactionId: number
  }[]
}) => {
  // const url = 'bff/manual_investment/transaction/batch'
  const url = 'bff/manual_investment/transaction-legacy/batch'
  const { data: responseData, status } = await deleteWithTokenApiV2(url, data)

  if (status !== 200) {
    const msg = 'Error deleting Many Transactions'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return {
    data: responseData
  }
}

/** @description BFF: Create Transaction by ID, Equity (Patrimony) */
export const createTransactionManualInvestment = async (body: ISaveEntryWithManualInvestment) => {
  const url = `bff/manual_investment/${body?.entryId}/transaction`
  const { data, status } = await postWithTokenApiV2(url, body)

  if (status !== 200) {
    const msg = `Error when creating Transaction by Equity (Patrimony): ${body}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

interface IEditTransactionManualInvestment {
  amount: number
  buyDate: string
  description?: string
  enableBudgetManager: boolean
  instituitionKey: number
  manualInvestmentId: string
  typeKey: 0 | 1
  pluggyInvestmentId: string
  pluggyTransactionId: string
}

/** @description BFF: Edit Transaction by ID, Equity (Patrimony) */
export const editTransactionManualInvestment = async ({
  amount,
  buyDate,
  description,
  enableBudgetManager,
  instituitionKey,
  manualInvestmentId,
  typeKey,
  pluggyTransactionId
}: IEditTransactionManualInvestment) => {
  const url = `bff/manual_investment/transaction/${pluggyTransactionId}`
  const { data, status } = await patchWithTokenApiV2(url, {
    amount,
    buyDate,
    description,
    enableBudgetManager,
    instituitionKey: Number(instituitionKey),
    manualInvestmentId,
    typeKey
  })

  if (status !== 200) {
    const msg = `Error when editing Transaction by ID ${manualInvestmentId}, Equity (Patrimony)`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}
