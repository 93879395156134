import { Patrimonio } from '../../components/Modal/DeleteTransaction/types'
import { getWithTokenApiV2, postWithTokenApiV2 } from '../../webClient'
import { ManyResults } from '../Results'

interface IFilter {
  ApenasNome?: boolean
}

export const fetchPatrimonioByFamilyIdApi = async ({
  familyId,
  filter
}: {
  familyId: number
  filter?: IFilter
}) => {
  const url = `api/patrimonio/familia/${familyId}/filtro`
  const {
    data: { results: patrimonios, success, errorMsg, totalResults },
    status
  } = await postWithTokenApiV2<ManyResults<Patrimonio>, IFilter>(url, filter)

  if (!success || status !== 200) {
    const msg = `Error getting patrimonio for family ${familyId} with filters ${JSON.stringify(filter)}`
    console.error(msg, errorMsg)
    throw new Error(msg)
  }

  return { patrimonios, totalResults }
}

export interface IFetchFinancialInstitutionsResult {
  key: number
  value: string
  serieId: number
}
// export const fetchPatrimonioFinancialInstitutionsApi = async () => {
//   const url = 'api/patrimonio/metodosAuxiliares/porIdentificador'
//   const filtro = {
//     filtro: 'instituicoesFinanceiras'
//   }

//   const {
//     data: { results: financialInstitutions, success, errorMsg, totalResults },
//     status
//   } = await postWithTokenApiV2<ManyResults<IFetchFinancialInstitutionsResult>, typeof filtro>(url, filtro)

//   if (!success || status !== 200) {
//     const msg = `Error getting financial institution with filters ${JSON.stringify(filtro)}`
//     console.error(msg, errorMsg)
//     throw new Error(msg)
//   }

//   return { financialInstitutions, totalResults }
// }

export interface PatrimoniosAtivosRendaFixa {
  id: number
  patrimonioId: number
  quantidade: number
  total: number
  descricao: string
}
export const fetchPatrimoniosAtivosRendaFixaApi = async ({ familyId }) => {
  if (!familyId) {
    console.log('Missing family id when fetching Patrimonios ativos renda fixa')
    return {
      categorias: [],
      totalResults: 0
    }
  }

  const url = `api/patrimonio/patrimonioAtivo/${familyId}`
  const { data: patrimoniosAtivosRendaFixa, status } = await getWithTokenApiV2<PatrimoniosAtivosRendaFixa[]>(
    url
  )

  if (status !== 200) {
    const msg = `Error getting Patrimonios ativos renda fixa. Family id: ${familyId}`
    // console.error(msg, errorMsg)
    throw new Error(msg)
  }

  return { patrimoniosAtivosRendaFixa }
}
