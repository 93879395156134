import React from 'react'
import styled from 'styled-components'

export const Message = styled.p`
  font-size: 0.85rem;
  font-style: italic;
  margin-top: 0.4rem;
  padding-left: 0.6rem;
`

export const InfoMessage = ({ message, className }: { message: string; className?: string }) => {
  return <Message className={className}>{message}</Message>
}
