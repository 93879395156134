import styled, { css } from 'styled-components'
import colors from '../../../domain/Colors'

export const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  padding: 0 4% 1rem;
  position: relative;
  max-width: 1280px;
`

export const Stage = styled.div<{ empty?: boolean }>`
  margin-bottom: 2rem;
  border-top: 1px solid ${colors.gray};
  border-bottom: 1px solid ${colors.gray};
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* height: 100%; */

  ${({ empty }) =>
    empty &&
    css`
      border-top: 0;
      padding-bottom: 0;
      padding-top: 0;
    `}

  p {
    line-height: 1.4;
    margin: 0 auto 0.6rem;
    max-width: 920px;
  }
`

export const InfoStage = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 2rem;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
`

export const TutorialsList = styled.ol`
  background-color: ${colors.white};
  /* box-shadow: 0px -1px 12px 2px rgba(0, 0, 0, 0.1); */
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  /* 
  position: fixed;
  right: 0;
  top: 82px;
  width: 210px;
  flex-direction: column;
  align-items: end;
  z-index: 10;
  background: white;
  padding: 1rem;
  overflow-y: scroll;
  height: calc(100vh - 82px); */
`

export const TutorialsButton = styled.button`
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  transform: scale(1);
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  text-align: right;
  color: ${colors.white};
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
    transform: scale(1.08);
  }

  &:disabled {
    background-color: ${colors.gray};
    pointer-events: none;
  }
`
