import styled from 'styled-components'
import colors from '../../../../../domain/Colors'
import { AlertModal } from '../../../../Modal'

export const StyledAlertModal = styled(AlertModal)`
  &[data-backdrop] {
    height: 100%;
    width: 100%;
  }
`

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0.5rem 0.5rem;

  > div {
    flex-basis: 48%;
  }
`

export const Title = styled.h4`
  font-weight: normal;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
`

export const Subtitle = styled.h5`
  margin-bottom: 0;
`

export const Form = styled.div`
  .MuiFormGroup-root {
    margin-bottom: 1rem;
    gap: 0.7rem;
  }

  .MuiFormLabel-root {
    line-height: 1.5rem;
    &.Mui-focused {
      color: ${colors.black};
    }
  }

  .MuiRadio-root {
    margin-right: 6px;
    padding: 0;
  }

  .MuiFormControlLabel-root {
    display: flex;
    margin-bottom: 0;
    margin-left: 0;

    &:hover {
      background-color: rgba(254, 155, 155, 0.07);
    }
  }
`
