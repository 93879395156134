import React from 'react'
import { IframeVimeo } from './components/VideoIframe/Iframe'
import { Pagination } from './components/Pagination'
import { MapProps } from './types'
import { COMMOM_CONTENT } from './commomContent'

export const HelpHome = () => (
  <Pagination>
    <div>
      <h3>Bem vindo(a) ao Vista!</h3>

      <p>Nesta tela você verá algumas informações resumidas sobre a sua saúde financeira.</p>

      <p>
        No gráfico abaixo você poderá comparar os seus investimentos, receitas, despesas e dívidas que foram
        realizados ao longo do prazo escolhido.
      </p>

      <p>Por padrão este prazo será o mês atual.</p>

      <COMMOM_CONTENT.Home.Cliente />
    </div>

    <div>
      <IframeVimeo title="Vista AI" videoId="1006570863?h=74493a4118" />
    </div>

    <div>
      <IframeVimeo title="Bloco de Notas" videoId="1008221505?h=c63681e481" />
    </div>
  </Pagination>
)

export const HelpPlanosSonhos = () => <COMMOM_CONTENT.PlanosSonhos />

export const HelpGestaoPlanos = () => (
  <COMMOM_CONTENT.GestaoPlanos>
    <IframeVimeo title="Novo lançamento de Investimentos" videoId="1004612002?h=b61661ed68" />
  </COMMOM_CONTENT.GestaoPlanos>
)

export const HelpDestinacaoPatrimonio = () => <COMMOM_CONTENT.DestinacaoPatrimonio />

export const HelpDestinacaoInvestimentos = () => (
  <div>
    <p>
      Aqui você irá determinar para onde seus investimentos mensais serão destinados. Ao realizar o
      investimento do mês ele será distribuído na proporção que você determinar para cada um dos seus planos.
    </p>
    <p>
      Ex: Se você determinar 30% de um fundo de ações para a aposentadoria e outros 70% para a compra de uma
      casa, ao alocar qualquer valor neste fundo a inteligência do Vista distribuirá nesta proporção aos seus
      planos.
    </p>

    <IframeVimeo title="Destinação de Investimentos (Ajuda)" videoId="768239591" />
  </div>
)

export const HelpDividas = () => <COMMOM_CONTENT.Dividas />
export const HelpGestaoOrcamento = () => <COMMOM_CONTENT.GestaoOrcamento />
export const HelpExtrato = () => <COMMOM_CONTENT.Extrato />

export const HelpEquilibrioFinanceiro = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <p>
      Determine metas e objetivos gerais, veja como está a sua evolução e quanto dinheiro está economizando e
      ganhando.
    </p>
  </div>
)

export const HelpMeioPagamento = () => (
  <div>
    <p>Nesta tela você terá informações preciosas do seu Planejamento Financeiro.</p>

    <p>
      Determine metas e objetivos gerais, veja como está a sua evolução e quanto dinheiro está economizando e
      ganhando.
    </p>

    <IframeVimeo title="Meio de pagamento (Ajuda)" videoId="1005515552?h=327d76e120" />
  </div>
)

export const HelpIndependenciaFinanceira = () => <COMMOM_CONTENT.IndependenciaFinanceira />
export const HelpIntegracao = () => <COMMOM_CONTENT.Integracoes />
export const HelpPatrimonioIntegracao = () => <COMMOM_CONTENT.Patrimonio.Integracoes />
export const HelpPlannerUsuarios = () => <COMMOM_CONTENT.Configuracoes.Usuarios />
export const HelpPlannerWizard = () => <COMMOM_CONTENT.Configuracoes.ConfiguracaoInicial />

export const MAP: MapProps = {
  '/destinacao-investimentos': HelpDestinacaoInvestimentos,
  '/destinacao-patrimonio': HelpDestinacaoPatrimonio,
  '/dividas': HelpDividas,
  '/equilibrio-financeiro': HelpEquilibrioFinanceiro,
  '/extrato': HelpExtrato,
  '/gestao-planos-sonhos': HelpGestaoPlanos,
  '/home': HelpHome,
  '/independencia-financeira': HelpIndependenciaFinanceira,
  '/integracoes': HelpIntegracao,
  '/meios-pagamento': HelpMeioPagamento,
  '/orcamento': HelpGestaoOrcamento,
  '/patrimonio-integracao': HelpPatrimonioIntegracao,
  '/planos-sonhos': HelpPlanosSonhos,
  '/usuarios': HelpPlannerUsuarios,
  '/wizard': HelpPlannerWizard
}
