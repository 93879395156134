import styled from 'styled-components'
import colors from '../../../../../domain/Colors'

export const Button = styled.button`
  background: ${({ theme: { colors } }) =>
    `linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-size: 1rem;
  padding: 7px 15px;
  text-transform: uppercase;

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }
`
