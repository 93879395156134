import { NavLink } from 'reactstrap'
import styled, { css } from 'styled-components'
import colors from '../../../domain/Colors'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0.5rem;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 1rem;
`

export const LinkButton = styled(NavLink)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 1rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.white};
  text-decoration: none;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    color: ${colors.white};
    transform: scale(1.08);
  }

  &:disabled {
    pointer-events: none;
  }
`

export const HelpModalButton = styled.div<{ disabled: boolean }>`
  a {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${colors.white};
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;

    &:hover {
      color: ${colors.white};
      transform: scale(1.08);
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${colors.gray} !important;
        pointer-events: none;
      `}
  }
`
