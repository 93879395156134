import React, { useState } from 'react'
import Select from './Select'
import { useCurrentFamilyId } from '../../hooks/useCurrentFamilyId'
import { useFamilyMeiosPagamentos } from '../../queries/meioPagamento/queries'
import { MeioPagamento } from '../../domain/meioPagamento/meioPagamento'
import { Selectable } from './Select/Options'

interface Props {
  onChange?: (meio: MeioPagamento['id']) => void
  placeholder?: string
  grayscale?: boolean
  selectedId?: MeioPagamento['id'] | undefined
  className?: string
}

export default ({ onChange, className, placeholder, selectedId, grayscale }: Props) => {
  const [filter, setFilter] = useState('')
  const { familyId } = useCurrentFamilyId()
  const { meiosPagamentos: meios, isLoadingMeios } = useFamilyMeiosPagamentos(familyId)
  const options: Selectable<number>[] = meios?.map(({ id, nome }) => ({ value: id, text: nome })) || []
  const actualPlaceholder = options.find(({ value }) => value === selectedId)?.text || placeholder || 'Nenhum'

  return (
    <Select
      filter={filter}
      onFilter={setFilter}
      options={options}
      loading={isLoadingMeios}
      placeholder={actualPlaceholder}
      onSelection={(meioSelecionado) => {
        if (onChange) onChange(meioSelecionado)
      }}
      grayscale={grayscale}
      className={'meio-pagamento-select ' + (className || '')}
    />
  )
}
