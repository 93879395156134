export const downloadContent = async (content: BlobPart, fileName: string) => {
  const blob = new Blob([content], { type: 'application/json' })
  const href = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
