import React, { useCallback, useEffect, useState } from 'react'
import { PluggyConnect } from 'react-pluggy-connect'
import { useCurrentUserId } from '../../hooks/useCurrentUserId'
import { useLoadingScreen } from '../../hooks/useLoadingScreen'
import { usePluggyToken } from '../../queries/bankingIntegration/queries'
import { RefreshIcon } from '../Icons'
import { Size } from '../withSize'
import styled from 'styled-components'

const Text = styled.span`
  cursor: pointer;
  text-decoration-line: underline;
`

interface Props {
  itemId: string
  size?: Size
  onError?: (error?: Error | null) => void
  onSuccess?: (itemId: string) => void
  text?: string
}

export default ({ itemId, size, text, onError, onSuccess }: Props) => {
  const { userId } = useCurrentUserId()

  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const { setIsLoading } = useLoadingScreen()
  const {
    token,
    isLoadingToken: isLoading,
    isErrorToken,
    tokenError,
    isRefetchingToken
  } = usePluggyToken(userId, itemId, {
    enabled: isOpen,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: 'always'
  })
  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, setIsLoading])
  useEffect(() => {
    close()
    onError?.(tokenError)
  }, [close, isErrorToken, onError, tokenError])
  const shouldRenderModal = isOpen && token && !isRefetchingToken

  return (
    <React.Fragment>
      {text ? (
        <Text onClick={open}>{text}</Text>
      ) : (
        <RefreshIcon size={size} tooltip="Atualizar lançamentos" onClick={open} />
      )}
      {shouldRenderModal && (
        <PluggyConnect
          updateItem={itemId}
          connectToken={token || ''}
          onClose={close}
          onSuccess={({ item: { id: itemId } }) => onSuccess?.(itemId)}
          onError={(err) => onError?.(new Error(JSON.stringify(err)))}
        />
      )}
    </React.Fragment>
  )
}
