import React from 'react'
import Icon from './Icon'
import plus from '../../assets/img/theme/plus.png'
import styled from 'styled-components'

interface Props {
  onClick?: () => void
  tooltip?: string
}

const StyledIcon = styled(Icon)`
  max-width: 24px;
  max-height: 24px;
  margin-bottom: 0px;
`

export default ({ onClick, tooltip }: Props) => (
  <StyledIcon alt="Colunas" src={plus} onClick={onClick} tooltip={tooltip} />
)
