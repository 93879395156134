import React from 'react'
import * as S from './styles'

export const Showcase = () => {
  return (
    <S.LinkButton
      href="https://vimeo.com/showcase/11360578"
      title="Showcase Onboarding Vista"
      target="_blank"
      rel="noopener noreferrer"
    >
      Todos os vídeos do Onboarding
    </S.LinkButton>
  )
}
