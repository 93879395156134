import { isAbsent } from '../helpers'
import { useLocalStorage } from './useLocalStorage'

/**@deprecated prefer using authContext and useAuthHook but consider that useAuthHook does not return
 * the current page familyId but the current user's familyId, which means it will alway be undefined
 * for planners and support users */
export const useCurrentFamilyId = () => {
  const { familyId } = useCurrentFamilyIdString()
  return { familyId: parseInt(familyId) }
}

/**@deprecated prefer using authContext and useAuthHook but consider that useAuthHook does not return
 * the current page familyId but the current user's familyId, which means it will alway be undefined
 * for planners and support users */
export const useCurrentFamilyIdString = () => {
  const { value: familyId } = useLocalStorage('familia-id')
  if (isAbsent(familyId)) throw new Error('Family id not found')
  return { familyId }
}
