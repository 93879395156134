import { useMutation, useQueryClient } from 'react-query'
import {
  DeleteSelectedFamiliesParams,
  DeleteSelectedFamiliesResult,
  deleteFamiliesById
} from '../../domain/family/api/deleteFamiliesById'
import { Family } from '../../domain/family/Family'
import {
  toggleFamilyAutoCategorization as toggleFamilyAutoCategorizationAPI,
  Props as AutoCategorizationProps
} from '../../domain/family/api/toggleFamilyAutoCategorization'
import { cacheBaseKey } from './queries'
import { getAllPersonsByFamilyIdApi } from '../../domain/family/api/getFamilyById'

export const useDeleteFamiliesById = () => {
  const {
    isLoading: isLoadingDeleteFamilies,
    isError: isErrorDeleteFamilies,
    data: deletedFamiliesData,
    error: deleteFamiliesError,
    mutateAsync: deleteSelectedFamiliesAsync
  } = useMutation<DeleteSelectedFamiliesResult, Error, DeleteSelectedFamiliesParams, unknown>(
    deleteFamiliesById
  )

  return {
    deleteSelectedFamiliesAsync,
    isLoadingDeleteFamilies,
    isErrorDeleteFamilies,
    deletedFamiliesData,
    deleteFamiliesError
  }
}

export const useToggleFamilyAutoCategorization = () => {
  const client = useQueryClient()
  const {
    isLoading: isTogglingAutoCategorization,
    isError: isTogglingAutoCategorizationError,
    data: family,
    error: toggleAutoCategorizationError,
    mutateAsync: toggleFamilyAutoCategorizationAsync,
    mutate: toggleFamilyAutoCategorization
  } = useMutation<Family, Error, AutoCategorizationProps, unknown>(toggleFamilyAutoCategorizationAPI, {
    onSuccess: () => {
      client.invalidateQueries(cacheBaseKey)
    }
  })

  return {
    isTogglingAutoCategorization,
    isTogglingAutoCategorizationError,
    family,
    toggleAutoCategorizationError,
    toggleFamilyAutoCategorizationAsync,
    toggleFamilyAutoCategorization
  }
}

export const useGetPersonByFamilyId = () => {
  const client = useQueryClient()

  const {
    mutateAsync: getPersonByFamilyIdAsync,
    mutate: getPersonByFamilyId,
    isLoading: isLoadingGetPersonByFamilyId,
    isError: isErrorGetPersonByFamilyId,
    error: getPersonByFamilyIdError,
    isSuccess: isSuccessGetPersonByFamilyId,
    data
  } = useMutation(getAllPersonsByFamilyIdApi, {
    onSuccess: () => {
      client.invalidateQueries(cacheBaseKey)
    }
  })

  const { persons, totalResults } = data || {}

  return {
    getPersonByFamilyIdAsync,
    getPersonByFamilyId,
    isLoadingGetPersonByFamilyId,
    isErrorGetPersonByFamilyId,
    getPersonByFamilyIdError,
    isSuccessGetPersonByFamilyId,
    persons,
    totalResults
  }
}
