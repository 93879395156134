import styled from 'styled-components'
import Colors from '../../../domain/Colors'

export const Container = styled.div<{ open: boolean; borderless: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 1.9px solid
    ${({ borderless }) => (borderless ? Colors.disabledTextBackground : 'rgb(154, 61, 255)')};
  border-radius: ${({ open }) => (open ? '0.25rem' : '1.3rem')};
  padding-right: 0.2rem;
  min-width: 120px;
  min-height: 1rem;
  transition-property: border-radius, text-indent;
  transition-duration: 0.4s;
  background-color: ${({ borderless }) => (borderless ? Colors.disabledTextBackground : Colors.white)};
`

export const SearchSizer = styled.div<{ open: boolean }>`
  display: flex;
  flex: 5;
  margin-left: ${({ open }) => (open ? '0.25rem' : '0.65rem')};
  transition-property: margin-left;
  transition-duration: 0.4s;
`

export const SearchInput = styled.input<{ borderless: boolean }>`
  padding: 0;
  width: 95%;
  border: none;
  -webkit-appearance: none;
  background-color: ${({ borderless }) => (borderless ? Colors.disabledTextBackground : Colors.white)};
  &:focus {
    outline: none;
  }

  &[disabled] {
    opacity: 0.4;
  }
`

export const IconContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: right;
  margin-right: 10px;
`
