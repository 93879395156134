import styled, { css } from 'styled-components'

export const ErrorContainer = styled('div')`
  ${() => css`
    display: flex;
    flex-direction: column;
    justify-content: 'flex-start';
    width: fit-content;
  `}
`

export const ExpandedContainer = styled('div')`
  ${() => css`
    padding: 20px;
    width: 100%;
  `}
`
