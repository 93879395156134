import React, { createContext, FunctionComponent, useState, useCallback } from 'react'

interface ContextValue extends State {
  setIsLoading: (isLoading: boolean, text?: string) => void
}

interface State {
  isLoading: boolean
  text: string
}

const Context = createContext<ContextValue | undefined>(undefined)

const Provider: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [{ isLoading, text }, setNewValues] = useState<State>({
    isLoading: false,
    text: ''
  })
  const setIsLoading = useCallback(
    (isLoading: boolean, newText?: string) => setNewValues({ isLoading, text: newText ?? text }),
    [text]
  )
  return <Context.Provider value={{ isLoading, text, setIsLoading }}>{children}</Context.Provider>
}

export { Provider as LoadingScreenProvider, Context as LoadingScreenContext }
export type { ContextValue as IsLoadingContextValue }
