import React, { useCallback, useMemo, useState } from 'react'
import { DownloadIcon, PrintIcon, BarsIcon, TrashCanIcon, FilterIcon } from '../../../../Icons'
import { downloadContent } from '../../../../../download'
import * as XLSX from 'xlsx'
import conveter from 'json-2-csv'
import ColumnPicker from '../../../ColumnPicker'
import Filter from '../../../Filter'
import ColumnsFilter from '../../../ColumnsFilter'
import { ToggleSwitchButton } from '../../../ToggleSwitchButton'
import * as S from './styles'
import { TableTitleProps } from './types'
// import { useLocalStorage } from '../../../../../hooks'
import { useStore } from '../../../../../hooks/useStore'
import { useLoadingScreen } from '../../../../../hooks/useLoadingScreen'
import { COLUMNS_MAP } from '../../../ColumnsFilter/columnNamesAndFilters'

export const TableTitle = <T extends object>({
  columnNames = [],
  columns,
  // refetchData,
  data,
  deletable,
  disabledColumnNames,
  downloadable,
  downloadFileName,
  downloadFormat = 'csv',
  filterEnabled,
  filterProps,
  isToggleSwitchChecked,
  mapDownloadFile,
  onDelete,
  onFilterChange = () => {},
  onHiddenColumnsChange = () => {},
  onToggleSwitch,
  printable,
  selectedRowsCount,
  title,
  defaultFilter,
  toggleableColumns,
  toggleSwitchButton,
  toggleSwitchText,
  filterDefaultSelected
}: TableTitleProps<T>) => {
  const [isToggleColumnOpen, setIsToogleColumnsOpen] = useState(false)
  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const toggleColumnOpen = () => setIsToogleColumnsOpen(!isToggleColumnOpen)
  const closeFilterModal = useCallback(() => setFilterModalOpen(false), [setFilterModalOpen])

  const toggleFilterModal = useCallback(
    () => setFilterModalOpen(!isFilterModalOpen),
    [setFilterModalOpen, isFilterModalOpen]
  )

  const downloadData = async () => {
    const fileName = `${downloadFileName || 'content'}.${downloadFormat}`
    const content = mapDownloadFile ? mapDownloadFile(data) : data
    switch (downloadFormat) {
      case 'csv':
        const csv = await conveter.json2csvAsync(content)
        downloadContent(csv, fileName)
        break
      case 'json':
        downloadContent(JSON.stringify(content), fileName)
        break
      case 'xlsx':
        const worksheet = XLSX.utils.json_to_sheet(content)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Context')
        XLSX.writeFile(workbook, fileName)
        break
      default:
        console.warn(`Unknown download format: ${downloadFormat}!`)
    }
  }

  const filterNotification = useMemo(() => {
    let count = 0
    Object.entries(filterDefaultSelected || {}).forEach(([_name, value]) => {
      if (!!value && value !== 'false') count++
    })
    return count
  }, [filterDefaultSelected])

  const { findCategory, findMeioPagamento, findOrcamento } = useStore()
  const storageFilter = localStorage.getItem('filter')
  const storageMethodId = localStorage.getItem('methodId')
  const storageBudgetId = localStorage.getItem('budgetId')
  const storageType = localStorage.getItem('type')
  const storageCategory = localStorage.getItem('category')

  const activeFilters = useMemo(() => {
    const category = Number.isInteger(Number(storageCategory)) && findCategory(Number(storageCategory))?.nome
    const paymentMethod =
      Number.isInteger(Number(storageMethodId)) && findMeioPagamento(Number(storageMethodId))?.nome
    const budget = Number.isInteger(Number(storageBudgetId)) && findOrcamento(Number(storageBudgetId))?.nome

    return {
      filter: storageFilter,
      methodId: paymentMethod,
      budgetId: budget,
      type: storageType,
      category
    }
  }, [
    storageCategory,
    findCategory,
    storageMethodId,
    findMeioPagamento,
    storageBudgetId,
    findOrcamento,
    storageFilter,
    storageType
  ])

  const clearMap = {
    filter: () => localStorage.removeItem('filter'),
    methodId: () => localStorage.removeItem('methodId'),
    budgetId: () => localStorage.removeItem('budgetId'),
    type: () => localStorage.removeItem('type'),
    category: () => localStorage.removeItem('category')
  }

  const clearFilters = () => {
    Object.keys(clearMap).forEach((key) => {
      clearMap?.[key]?.()
    })
  }

  const { setIsLoading } = useLoadingScreen()

  const filteredActiveFilters = useMemo(() => {
    return Object.entries(activeFilters).filter(([_, value]) => !!value)
  }, [activeFilters])

  return (
    <S.Container>
      <S.Header>
        {title && <S.Title>{title}</S.Title>}
        <S.IconsContainer>
          {toggleSwitchButton && (
            <ToggleSwitchButton
              onClick={onToggleSwitch}
              title={toggleSwitchText}
              checked={isToggleSwitchChecked ?? false}
            />
          )}

          {deletable && (
            <>
              {selectedRowsCount && <span>{selectedRowsCount}</span>}
              <TrashCanIcon tooltip="Deletar lançamentos" onClick={onDelete} />
              <S.IconSpacer />
            </>
          )}
          {filterEnabled && filterProps && (
            <>
              <Filter {...filterProps} defaultFilter={defaultFilter || undefined} />
              <S.IconSpacer />
            </>
          )}

          {downloadable && (
            <>
              <DownloadIcon onClick={downloadData} tooltip="Baixar Excel" />
              <S.IconSpacer />
            </>
          )}

          {printable && ( // TODO: Improve this to print only data in table, not whole page.
            <>
              <PrintIcon onClick={window.print} tooltip="Imprimir" />
              <S.IconSpacer />
            </>
          )}
          {toggleableColumns && columns && (
            <>
              <S.ShowRelative>
                <BarsIcon onClick={toggleColumnOpen} tooltip="Ver Colunas" />
                {isToggleColumnOpen && (
                  <ColumnPicker
                    open={isToggleColumnOpen}
                    columns={columns}
                    disabledColumnNames={disabledColumnNames}
                    onHiddenColumnsChange={onHiddenColumnsChange}
                    onOutsideClick={() => setIsToogleColumnsOpen(false)}
                  />
                )}
              </S.ShowRelative>
              <S.IconSpacer />
            </>
          )}
          <S.ShowRelative>
            {columnNames.length > 0 && <FilterIcon onClick={toggleFilterModal} tooltip="Filtros" />}
            {filterNotification > 0 && (
              <S.ActiveFlters onClick={toggleFilterModal}>{filterNotification}</S.ActiveFlters>
            )}
            {isFilterModalOpen && (
              <ColumnsFilter
                columnNames={columnNames}
                onFilterChange={onFilterChange}
                onOutsideClick={closeFilterModal}
                onClearFilters={clearFilters}
                filterDefaultSelected={filterDefaultSelected}
                // filterMethods={filterMethods}
              />
            )}
          </S.ShowRelative>
        </S.IconsContainer>
      </S.Header>

      <S.ActiveFltersBar>
        {filteredActiveFilters?.map(
          ([name, value]) =>
            value && (
              <S.ActiveFilter key={name}>
                <S.ActiveFilterName>{COLUMNS_MAP[name.toLowerCase()]}</S.ActiveFilterName>
                <S.ActiveFilterValue>{value}</S.ActiveFilterValue>
                <S.ButtonClear
                  onClick={() => {
                    console.log({ name, value })
                    clearMap[name]()
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                    }, 1000)
                  }}
                >
                  x
                </S.ButtonClear>
              </S.ActiveFilter>
            )
        )}

        {filteredActiveFilters?.length > 0 && (
          <S.ActiveFilter control>
            <S.ActiveFilterValue>Remover filtros</S.ActiveFilterValue>
            <S.ButtonClear
              onClick={() => {
                clearFilters()
                setIsLoading(true)
                setTimeout(() => {
                  setIsLoading(false)
                }, 1000)
              }}
            >
              x
            </S.ButtonClear>
          </S.ActiveFilter>
        )}
      </S.ActiveFltersBar>
    </S.Container>
  )
}
