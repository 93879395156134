import React from 'react'
import { MeioPagamentoSelect } from '../../../Selects'
import { ColumnFilterSelect } from '../columnNamesAndFilters'

export default ({ currentSelected, onChange }: ColumnFilterSelect) => {
  if (currentSelected !== undefined && typeof currentSelected !== 'number') {
    console.warn(`not numeric value passed to MeioPagamentoSelect: ${currentSelected}`)
    currentSelected = undefined
  }
  return (
    <MeioPagamentoSelect onChange={(id) => onChange(id)} selectedId={currentSelected} placeholder="Nenhum" />
  )
}
