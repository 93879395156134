import React from 'react'
import { Redirect } from 'react-router-dom'
import { getLoginRoute, getRoute, ROUTE_PATHS } from '../../routes'

export const getLoginPath = () => {
  const loginRoute = getLoginRoute()
  return (loginRoute?.layout ?? '') + loginRoute?.path
}

export const RedirectToLogin = () => {
  const loginPath = getLoginPath()
  console.warn('redirecting unauthenticated user to login page')
  return <Redirect to={loginPath} />
}

export const RedirectToHome = () => {
  const route = getRoute('Home')
  if (!route) throw new Error('Login route not found')

  return <Redirect to={String(route?.path)} />
}

export const safeClearStorage = () => {
  const storage = JSON.parse(JSON.stringify(localStorage))
  const persisted = ['priceModal', 'modalprimo', 'onselect-orcamento-categorization', ...ROUTE_PATHS]

  for (const key in storage) {
    if (!persisted.includes(key)) {
      localStorage.removeItem(key)
    }
  }
}
