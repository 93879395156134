export const reportar = [
  {
    label: 'Financeiro',
    value: 'Financeiro'
  },
  {
    label: 'Bug',
    value: 'Bug'
  },
  {
    label: 'Uso da plataforma',
    value: 'Uso da plataforma'
  },
  {
    label: 'Integração',
    value: 'Integração'
  },
  {
    label: 'Outros',
    value: 'Outros'
  }
]

export const paginaBug = [
  {
    label: 'Home',
    value: 'Home'
  },
  {
    label: 'Equilíbrio Financeiro',
    value: 'Equilíbrio Financeiro'
  },
  {
    label: 'Planos & Sonhos',
    value: 'Planos & Sonhos'
  },
  {
    label: 'Gestão de Planos',
    value: 'Gestão de Planos'
  },
  {
    label: 'Gestão de Orçamento',
    value: 'Gestão de Orçamento'
  },
  {
    label: 'Dívidas',
    value: 'Dívidas'
  },
  {
    label: 'Extrato',
    value: 'Extrato'
  },
  {
    label: 'Patrimônio',
    value: 'Patrimônio'
  },
  {
    label: 'Destinação de Patrimônio',
    value: 'Destinação de Patrimônio'
  },
  {
    label: 'Destinação de Investimentos',
    value: 'Destinação de Investimentos'
  },
  {
    label: 'Outra',
    value: 'Outra'
  }
]

export const installmentTypeMap = {
  0: 'parcelado',
  1: 'repetido'
}
