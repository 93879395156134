import React, { useEffect } from 'react'
import { AlertModal } from '../../../../Modal'
import * as S from './styles'
import { DetailsTransactionProps } from './types'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { OPERATION_OPTIONS } from '../../../../../domain/investments/api/ManualInvestments/constants'
import { formatCurrencyBRL } from '../../../../Utils'
import { useStore } from '../../../../../hooks/useStore'

export const DetailsInvestmentTransaction = ({
  open,
  onClose,
  editingTransaction = false
}: DetailsTransactionProps) => {
  const { findInstitution, findPatrimony, findManualPatrimony } = useStore()
  const { saveTransaction, isSubmitting, isSuccess, transaction } = useTransactionContext()
  const operationLabel = OPERATION_OPTIONS.find((op) => op.value === transaction?.operacao)?.label

  const institutionLabel = findInstitution(
    Number(transaction?.financialInstitutionCode ?? transaction?.idInstituicaoFinanceira)
  )?.name
  const patrimonyLabel = findPatrimony(transaction?.patrimonioId)?.descricao
  const manualPatrimonyLabel = findManualPatrimony(transaction?.pluggyInvestmentId)?.description

  let title = 'Criação'
  if (editingTransaction) title = 'Edição'
  if (transaction?.editRepeated) title = 'Edicão repetido'
  if (transaction?.editInstallments) title = 'Edição lançamento parcelado'

  const primaryButton = {
    text: 'Salvar',
    onClick: () => {
      saveTransaction({
        transaction
      })
    },
    disabled: isSubmitting
  }

  const secondaryButton = {
    text: 'Cancelar',
    onClick: onClose,
    disabled: isSubmitting
  }

  useEffect(() => {
    if (isSuccess) {
      console.log({ isSuccess })
      onClose()
    }
  }, [isSuccess, onClose])

  return (
    <AlertModal
      onClose={onClose}
      open={open}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      title={`Resumo: ${title}`}
    >
      <S.Details>
        <div>
          <S.Subtitle>Operação</S.Subtitle>
          <p>{operationLabel ?? '---'}</p>
        </div>

        <div>
          <S.Subtitle>Patrimônio</S.Subtitle>
          <p>{transaction?.patrimonio?.descricao ?? patrimonyLabel ?? manualPatrimonyLabel ?? '---'}</p>
        </div>

        <div>
          <S.Subtitle>Instituição Financeira</S.Subtitle>
          <p>{institutionLabel ?? '---'}</p>
        </div>

        <div>
          <S.Subtitle>Valor {transaction?.operacao === 0 ? 'aplicado' : 'resgatado'} </S.Subtitle>
          <p>{transaction?.valor ? formatCurrencyBRL(transaction?.valor) : '---'}</p>
        </div>
      </S.Details>
    </AlertModal>
  )
}
