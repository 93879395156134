import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import {
  ParseTransactionData,
  setFixedTime
} from '../../../components/Navbars/AdminNavbar/components/SaveDataTransaction/parseTransactionData'
import {
  useCreateOrUpdateTransaction,
  useEditTransactionEquityById
} from '../../../queries/lancamento/mutations'
import { Transaction } from '../../../components/Navbars/AdminNavbar/components/SaveDataTransaction/types'
import { IParentThis, ISaveTransaction, TransactionContext } from './types'
import moment from 'moment'
import { TIPO_ORCAMENTO_NAME_CODE } from '../../../components/Modal/DeleteTransaction/constants'
import { ISaveEntryWithManualInvestment } from '../../../domain/investments/api/ManualInvestments/types'
import { useSaveEntryManualInvestment } from '../../../queries/investments/mutations'
import { useCoreContext } from '../coreProvider'

export const TransactionContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [transaction, _setTransaction] = useState<Transaction | undefined>({} as Transaction)
  const [isOpenEditing, _setIsOpenEditing] = useState(false)
  const [isEditing, _setIsEditing] = useState(false)
  const [parentThis, _setParentThis] = useState<IParentThis>({} as IParentThis)
  const [showSuccess, _setShowSuccess] = useState(false)
  const [showError, _setShowError] = useState(false)
  const { familiaId, empresaId } = useCoreContext()
  const [modalOpen, setModalOpen] = useState(false)

  const {
    createEntryManualInvestmentAsync,
    isErrorCreateEntryManualInvestment,
    createEntryManualInvestmentSuccess,
    createEntryManualInvestmentError
    // resetCreateEntryManualInvestment
  } = useSaveEntryManualInvestment()

  const {
    createOrUpdateTransactionInvestmentAsync,
    isSuccessCreateOrUpdateTransactionInvestmentById,
    isErrorCreateOrUpdateTransactionInvestmentById,
    createOrUpdateTransactionInvestmentError,
    exception,
    success
  } = useCreateOrUpdateTransaction()

  const {
    editTransactionInvestmentByIdAsync,
    isSuccessEditTransactionInvestmentById,
    isErrorEditTransactionInvestmentById,
    editTransactionInvestmentByIdError
  } = useEditTransactionEquityById()

  const createOrUpdateTransactionError =
    !!isErrorCreateOrUpdateTransactionInvestmentById && !success && !!exception
  const createOrUpdateTransactionSuccessWithError =
    !!isSuccessCreateOrUpdateTransactionInvestmentById && !success && !!exception

  const Error =
    (createEntryManualInvestmentError as Error) ||
    createOrUpdateTransactionInvestmentError ||
    editTransactionInvestmentByIdError
  const hasError =
    !!Error ||
    createOrUpdateTransactionError ||
    createOrUpdateTransactionSuccessWithError ||
    isErrorEditTransactionInvestmentById ||
    isErrorCreateEntryManualInvestment

  const isSuccess = Boolean(
    (isSuccessCreateOrUpdateTransactionInvestmentById && success && !exception) ||
      isSuccessEditTransactionInvestmentById ||
      createEntryManualInvestmentSuccess
  )

  const modalShow = () => setModalOpen(true)
  const modalClose = useCallback(() => {
    _setTransaction(undefined)
    setModalOpen(false)
  }, [])
  const handleSetTransaction = (transaction?: Transaction) => {
    _setTransaction(transaction)
  }
  const handleEditintgEntry = (entry: Transaction) => {
    _setTransaction(entry)
    modalShow()
  }

  const saveTransaction = ({ parceled, transaction: item }: ISaveTransaction) => {
    const lancamento = item ?? transaction
    if (!transaction) {
      console.error(`Error: transaction is required, ${lancamento}`)
      return
    }

    const editing = !!lancamento?.id
    const { isManual } = lancamento || {}
    const isInvestment = lancamento?.tipoOrcamento === TIPO_ORCAMENTO_NAME_CODE.Investimento

    setIsSubmitting(true)

    if (editing && isManual) {
      /* edicão de lançamento manual */
      const newData = {
        amount: lancamento?.valor,
        buyDate: moment(lancamento?.data, 'DD/MM/YYYY').toISOString(),
        description: lancamento?.descricao,
        enableBudgetManager: lancamento?.enableBudgetManager,
        instituitionKey: lancamento?.idInstituicaoFinanceira ?? lancamento?.financialInstitutionCode,
        typeKey: lancamento?.operacao,
        manualInvestmentId: String(lancamento?.pluggyInvestmentId),
        pluggyTransactionId: String(lancamento?.pluggyTransactionId)
      }
      // @ts-expect-error
      editTransactionInvestmentByIdAsync(newData)
      return
    }

    if (!editing && isInvestment) {
      /* criação de lançamento manual, tipo investimento */
      const investmentBody: ISaveEntryWithManualInvestment = {
        amount: lancamento?.valor ?? 0,
        buyDate: setFixedTime(lancamento?.data ?? new Date())?.toISOString(),
        description: lancamento?.descricao ?? '',
        enableBudgetManager: lancamento?.enableBudgetManager,
        entryId: String(lancamento?.patrimonio?.id ?? lancamento?.patrimonioId),
        instituitionKey: lancamento?.financialInstitutionCode ?? 0,
        typeKey: lancamento?.operacao ?? 0
      }

      createEntryManualInvestmentAsync(investmentBody)
      return
    }

    const { data: parsedData, id } = ParseTransactionData({
      lancamento: lancamento as Transaction,
      parceled,
      familiaId: Number(familiaId),
      empresaId: Number(empresaId)
    })

    if (!parsedData) {
      setIsSubmitting(false)
      return
    }

    console.log('submitting', parsedData)
    // return

    /* criação de lançamento */
    createOrUpdateTransactionInvestmentAsync({ id, data: JSON.parse(JSON.stringify(parsedData)) })
  }

  const reloadScreenOnSuccess = () => {
    if (
      ['/v1/admin/extrato', '/v1/admin/patrimonio-integracao', '/v1/admin/orcamento'].includes(
        window.location.pathname
      )
    ) {
      window.location.reload()
    }
  }

  const onSuccessClose = () => {
    _setShowSuccess(false)
    _setShowError(false)
    setIsSubmitting(false)
    _setIsEditing(false)
    _setIsOpenEditing(false)
    modalClose()
    reloadScreenOnSuccess()
  }

  useEffect(() => {
    if (isSuccess) {
      _setShowSuccess(isSuccess)
      _setTransaction({} as Transaction)
    }
  }, [isSuccess])

  useEffect(() => {
    if (hasError) {
      // console.log('hasError', { hasError })
      _setShowSuccess(false)
      _setShowError(true)
      setIsSubmitting(false)
      // _setIsEditing(false)
      // _setIsOpenEditing(false)
      // _setShowError(false)
      // _setTransaction({} as Transaction)
      // parentThis?.atualizarDados?.()
      // parentThis?.limparSelecao?.()
      // parentThis?.cadastroLancamento?.hide?.()
      // parentThis?.setState({ saveEntry: { open: false } })
    }
  }, [hasError])

  useEffect(() => {
    const hasId = !!transaction?.id
    _setIsEditing(hasId)
  }, [transaction])

  return (
    <TransactionContext.Provider
      value={{
        empresaId: String(empresaId),
        familiaId: String(familiaId),
        isEditing,
        isOpenEditing,
        isSubmitting,
        showSuccess,
        showError,
        parentThis,
        isSuccess,
        isError: hasError,
        error: Error,
        saveTransaction,
        onSuccessClose,
        setShowError: (value: boolean) => _setShowError(value),
        setShowSuccess: (value: boolean) => _setShowSuccess(value),
        // @ts-expect-error expected-error
        setParentThis: (value: object) => _setParentThis(value),
        setTransaction: handleSetTransaction,
        transaction,
        setIsEditing: (value: boolean) => _setIsEditing(value),
        setIsOpenEditing: (value: boolean) => _setIsOpenEditing(value),
        modal: {
          open: modalOpen,
          show: modalShow,
          close: modalClose
        },
        setEditingEntry: handleEditintgEntry
      }}
    >
      {children}
    </TransactionContext.Provider>
  )
}

export function useTransactionContext() {
  const context = useContext(TransactionContext)
  if (!context) {
    throw new Error('TransactionContext ===> Need Wrap User container')
  }

  return context
}
