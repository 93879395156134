import { patchWithTokenApiV2 } from '../../../webClient'
import { noticeError } from '../../../services/Monitoring'

export type Params = {
  name?: string
  amountOriginal?: number
  pluggyInvestmentId: string
}

type Body = {
  name?: string
  amountOriginal?: number
}

export type Response = string

export const updateInvestment = async ({ name, amountOriginal, pluggyInvestmentId }: Params) => {
  const body = { name, ...(amountOriginal && { amountOriginal }) }
  const url = `investments/investment/${pluggyInvestmentId}`

  const { data: updatedInvestment, status } = await patchWithTokenApiV2<Response, Body>(url, body)

  if (status !== 200) {
    const msg = `Error updating investment id ${pluggyInvestmentId}`
    const error = new Error(msg)
    noticeError(error, { pluggyInvestmentId, name })
    throw new Error(msg)
  }

  return updatedInvestment
}
